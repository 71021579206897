import { createClient } from "../../../Services/ApiClient";

import { Quote } from "../domain/Quote";
import { Currency } from "../../Common/domain/Currency";
import { QuoteRepository } from "../domain/QuoteRepository";

const cliente = createClient();

export const createApiQuoteRepository = (): QuoteRepository => {
  return { getQuoteWithFees, getQuoteWithFeesAndTiers };
};

const getQuoteWithFees = async (
  countryCode: string,
  currency: string,
  amount: number,
  payerQuote?: string
) => {
  const response = await cliente.get<Quote>(
    `Quote/${countryCode}/${currency}/${amount}/${payerQuote}`,
    {}
  );
  return response.data;
};

const getQuoteWithFeesAndTiers = async (
  countryCode: string,
  currency: string,
  amount: number,
  payerQuote?: string
) => {
  const response = await cliente.get<Quote>(
    `Quote/tiers/${countryCode}/${currency}/${amount}/${payerQuote}`
  );
  return response.data;
};
