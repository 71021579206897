import styled from "styled-components";
import { theme } from "styled-tools";

export const RecipientDetailsTextRow = styled.p`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
  text-align: left;
`;
