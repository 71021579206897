import { PropsLink } from "../../Models/LinkInterface";
import { LinkStyled } from "./Link.styled";

const Link = ({ href, variant, size, children, ...props }: PropsLink) => {
  return (
    <LinkStyled
      to={href}
      variant={variant || "normal"}
      size={size || 1}
      {...props}
    >
      {children}
    </LinkStyled>
  );
};

export default Link;
