import { allowedNumbers } from "../../../Constants/Regexp";
import { SelectOption } from "../../../Models/Select";
import { Destination } from "../../Common/domain/Destination";
import { AdditionalFieldData, AdditionalFieldInfo } from "./BeneficiaryForm";

export type BeneficiariesPaginated = {
  preferredRecipients?: BeneficiaryRecipient[] | null;
  recipients?: BeneficiaryRecipient[] | null;
  pagination: {
    page: number;
    perPage: number;
    total: number;
  };
};

export type BeneficiaryRecipient = {
  beneficiaryId: string;
  fullName: string;
  email?: string;
  phoneNumber?: string;
  alias: string;
  country: string;
  account: Account[];
  accountSelected?: Account;
  additionalField?: AdditionalFieldData;
};

export type Account = {
  id: string;
  index?: number;
  accountName?: string;
  accountNumber: string;
  accountTypeId: string;
  bankName: string;
  rail?: string;
  country: string;
  destination?: string;
  destinationId: string;
  destinationList?: Array<SelectOption & Destination>;
  subdivisionList?: SelectOption[];
} & AdditionalFieldData;

export interface BeneficiarySelect {
  beneficiaryId: string;
  fullName: string;
  email?: string;
  phoneNumber?: string;
  alias: string;
  country: string;
  account: Account[];
  accountSelected?: Account;
  additionalField?: AdditionalFieldData;
}

export interface BeneficiaryEdit extends BeneficiaryRecipient {
  beneficiaryId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  alias: string;
  dateOfBirth: string;
  gender?: string;
  nationality?: string;
  countryOfBirth?: string;
  phoneNumber: string;
  phoneCountryCode?: string;
  email: string;
  snn?: string;
  addressType?: string;
  address: string;
  addressExtra: string;
  city: string;
  subnational: string;
  country: string;
  postalCode: string;
  accounts?: Account[];
  additionalField?: AdditionalFieldData;
  accountsGrouped?: Map<string, Account[]>;
}

export interface BeneficiaryUpdate {
  body: Partial<BeneficiaryEdit>;
  idBeneficiary: string | undefined;
}

export interface BeneficiaryInfoUpdate {
  firstName: string;
  middleName: string;
  lastName: string;
  alias: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  address: string;
  addressExtra: string;
  city: string;
  subnational: string;
  country: string;
  postalCode: string;
  accountBanks: AccountsUpdate[];
  additionalField?: AdditionalFieldData;
  [x: string]: unknown;
}

export interface AccountsUpdate {
  accountId: string;
  accountNumber: string;
  branch?: string;
  additionalFieldInfo?: AdditionalFieldInfo[];
  ifscCode?: string;
}

export const formatDOBString = (dateString: string) => {
  const [day, month, year] = dateString.split("/");
  const formattedDay = day.padStart(2, "0");
  const formattedMonth = month.padStart(2, "0");
  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const isValidAccountWithMessage = (account: string, country: string) => {
  if (country === "MEX") {
    return account.length === 18 && allowedNumbers.test(account);
  }

  return true;
};

export const groupAccounts = (accounts: Account[]) => {
  return accounts.reduce((groups, currentAccount, index) => {
    const destinationAccounts = groups?.get(currentAccount.destinationId) || [];
    destinationAccounts.push({ ...currentAccount, index });
    groups?.set(currentAccount.destinationId, destinationAccounts);

    return groups;
  }, new Map([]) as Map<string, Account[]>);
};
