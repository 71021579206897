import React from "react";
import { useTranslation } from "react-i18next";
import {
  FlexContainer,
  FlexItem,
} from "../../../../Components/Flex/Flex.styled";
import Text from "../../../../Components/Text";
import IconFont from "../../../../Components/IconFont";
import Button from "../../../../Components/Button";

interface NewBeneficiaryHeadProps {
  showTitle?: boolean;
  onClose?: () => void;
}

export const NewBeneficiaryHead = ({
  showTitle,
  onClose,
}: NewBeneficiaryHeadProps) => {
  const { t } = useTranslation("global");

  return (
    <FlexContainer justify="space-between" alignItems="start">
      <FlexItem direction="column" alignItems="start">
        <Text size={3} weight={600} margin={0}>
          {showTitle && t("Beneficiaries.CreateBeneficiary.Title")}
        </Text>
        <Text size={1} weight={400} color="grey" align="left" margin={0}>
          {showTitle && t("Beneficiaries.CreateBeneficiary.Subtitle")}
        </Text>
      </FlexItem>

      <Button
        variant="transparent"
        sizeButton="fit"
        padding="0px"
        onClick={() => onClose && onClose()}
      >
        <IconFont name="cancel" size="medium" color="black" />
      </Button>
    </FlexContainer>
  );
};
