import { IconsFontType } from "../../../Models/IconInterface";
import { Destination } from "../../Common/domain/Destination";
import { Codes } from "../../PaymentOrders/domain/VelocityLimit";

import { Quote } from "./Quote";
import { QuotePaymentMethodDestination } from "./QuoteDeliveryMethod";

export type QuoterProps = {
  deliveryMethodCurrency?: "D2B" | "CPU" | undefined;
  deliveryMethodsSelect?: QuoteOption[];
  sendToOptionsSelect?: (QuoteOption & Destination)[];
  full?: boolean;
  title?: string;
  textButton: string;
  value?: QuoteSelectedValue;
  maxAmount?: number;
  minAmount?: number;
  statusButton?: string;
  isDisabledButton?: boolean;
  showVelocityLimit?: boolean;
  codesMessages?: Codes[];
  paymentMethodOptions?: QuoteOption[];
  handleSubmit: () => void;
  onChange?: (tQuoteValue: QuoteSelectedValue, cantSubmit?: boolean) => void;
};

export type QuoteSelectedValue = {
  amount?: number;
  currency?: string | null;
  country?: string | null;
  deliveryMethod?: "D2B" | "CPU";
  sendTo?: string | null;
  currentQuote?: Quote["deliveryMethods"][number];
  withTiers?: boolean;
};

export type QuoteOption = {
  label: string;
  value: string;
  iconName?: IconsFontType;
};

export const getQuoteMethodDestination = (
  currentDeliveryMethodDestinations: QuotePaymentMethodDestination[],
  idSendTo: string
) => {
  return currentDeliveryMethodDestinations.find(
    (deliveryMethod) => deliveryMethod.id === idSendTo
  );
};
