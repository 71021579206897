import styled from "styled-components";
import { ifProp, theme } from "styled-tools";
import {
  footerBackgroundColor,
  headerBackgroundColor,
} from "../../Contexts/ThemeProvider/Layout";

export const ContainerFluid = styled.div<{
  $show: boolean;
  fullWidthContent?: boolean;
  roundedContent?: boolean;
}>`
  display: ${ifProp("$show", "flex", "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  background: ${headerBackgroundColor};

  .main-content {
    flex: 1;
    margin: 0 40px;
    text-align: center;
    max-width: 100%;
    background-color: #ffffff;
    border-radius: ${ifProp("roundedContent", "30px 30px 0 0", "0px")};
  }

  .footer {
    flex: 0;
  }
`;

export const HeaderContainer = styled.div`
  background-color: ${headerBackgroundColor};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div<{
  withPadding?: boolean;
  translated?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  width: 100%;
  padding-top: ${ifProp("withPadding", "10px", "0")};
  transform: ${ifProp("translated", "translateY(-48px)")};
`;

export const FooterContainer = styled.div`
  background-color: ${footerBackgroundColor};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-sizing: border-box;
  width: 100%;
`;

export const Return = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 1500px;
  height: 10%;
  padding-bottom: 10px;
  padding-top: 0;
  padding-left: 13px;

  text-decoration: underline;
  font-family: Poppins;
  text-decoration-line: none;
  color: ${theme("White")};

  span {
    padding: 6px;
    font-size: 10px;
  }

  a {
    line-height: 24px;
    font-weight: 500;
    text-decoration: none;
    color: ${theme("White")};
  }
`;
