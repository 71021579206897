import { Color, RowBold, tTableInfo } from "../../Models/TableInfo";
import { FlexContainer, FlexItem } from "../Flex/Flex.styled";
import {
  CirclePlusIcon,
  Divider,
  TableInfoColumn,
  TableInfoRow,
  TableInfoStyled,
  TextInfo,
  Title,
} from "./TableInfo.styled";
import circlePlus from "../../Assets/Icons/circle-plus.svg";
import { Fragment } from "react";
import { FontColor } from "../../Utilities/Icons";

const TableInfo = ({ data, withSpace }: tTableInfo) => {
  return (
    <TableInfoStyled $withSpace={withSpace}>
      {data.map((row, index) => {
        const { bold, color } = row;

        let fontWeightLeft = 400;
        let fontWeightRight = 400;

        switch (bold) {
          case RowBold.LEFT:
            fontWeightLeft = 600;
            fontWeightRight = 400;
            break;
          case RowBold.RIGHT:
            fontWeightLeft = 400;
            fontWeightRight = 600;
            break;
          case RowBold.ROW:
            fontWeightLeft = 600;
            fontWeightRight = 600;
            break;
          case RowBold.NONE:
            fontWeightLeft = 400;
            fontWeightRight = 400;
            break;
          default:
            fontWeightLeft = 400;
            fontWeightRight = 400;
        }

        let colorText: FontColor = "black";

        switch (color) {
          case Color.WHITE:
            colorText = "black";
            break;
          case Color.SUCCESS:
            colorText = "success";
            break;
          case Color.SOLID:
            colorText = "solid_light";
            break;
          default:
            colorText = "black";
        }

        return (
          <Fragment key={index}>
            <TableInfoRow>
              {row.type === "title" ? (
                <>
                  <Title>
                    {row.label}
                    <br /> {row.label2}
                  </Title>
                </>
              ) : (
                <>
                  <TableInfoColumn
                    fontWeight={fontWeightLeft}
                    padding={row?.padding || "24px 0"}
                    grow={1.2}
                  >
                    {row.label}
                  </TableInfoColumn>
                  <TableInfoColumn
                    textAlign="right"
                    fontWeight={fontWeightRight}
                    color={colorText}
                    padding={row?.padding || "24px 0"}
                  >
                    {row.withCirclePlus && <CirclePlusIcon src={circlePlus} />}
                    {row.value}
                  </TableInfoColumn>
                  {row.value2 && (
                    <FlexContainer>
                      <FlexItem>
                        <TextInfo>{row.label2}</TextInfo>
                      </FlexItem>
                      <FlexItem justify="end">
                        {row.value2 ? (
                          <TextInfo align="right">{row.value2}</TextInfo>
                        ) : (
                          ""
                        )}
                      </FlexItem>
                    </FlexContainer>
                  )}
                </>
              )}
            </TableInfoRow>
            {row.withDivider && <Divider />}
          </Fragment>
        );
      })}
    </TableInfoStyled>
  );
};

export default TableInfo;
