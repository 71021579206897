import { useTranslation } from "react-i18next";
import { Column, Container, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import { FlexContainer, FlexItem } from "../../../Components/Flex/Flex.styled";
import { CountryIcon } from "../../../Components/MoneyInput/MoneyInput.styled";
import { useDispatch, useSelector } from "react-redux";
import { SearchSelect } from "../../../Components/SearchSelect/SearchSelect.component";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useEffect, useState } from "react";
import Button from "../../../Components/Button";
import { useDestination } from "../../../Hooks/useDestination";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { AppDispatch } from "../../../Redux/Store";
import { allowedCountries } from "../../../Modules/Common/domain/Countries";
import { useCountry } from "../../../Hooks/useCountry";
import {
  AddAccountButton,
  AddButtonContainer,
  ButtonSection,
  CloseContainer,
  CountryContainer,
  HeadContainer,
} from "./AddAccounts.styled";
import { selectorLanguage } from "../../../Redux/Translate";
import Layout from "../../../Components/Layout";
import theme from "../../../Contexts/ThemeProvider";
import { ThemeProvider } from "styled-components";
import Icon from "../../../Components/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BeneficiarySelector,
  BeneficiarySlice,
} from "../../../Redux/Beneficiary/Beneficiary.slice";
import { useBeneficiary } from "../../../Hooks/useBeneficiary";
import {
  Account,
  BeneficiaryEdit,
} from "../../../Modules/Beneficiary/domain/Beneficiary";
import { AdditionalField } from "../../../Modules/Common/domain/AdditionalFields";
import { useModal } from "../../../Hooks/useModal";
import { BeneficiaryAccountCard } from "../../EditBeneficiary/components/BeneficiaryAccountCard";
import { useSubdivision } from "../../../Hooks/useSubdivision";
import isEmpty from "lodash.isempty";
import ToastNotifications from "../../../Components/ToastNotifications";
import { DashboardSelector } from "../../../Redux/Dashboard/Dashboard.slice";

export const AddAccounts = (...props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const { modal, showModal, setOpenModal } = useModal();
  const location = useLocation();
  const [showView, setShowView] = useState(
    location && location.state !== null
      ? location.state.newAccount
      : "editAccount"
  );
  const { previousView } = useSelector(DashboardSelector);

  const { language } = useSelector(selectorLanguage);
  const { beneficiaryEdit } = useSelector(BeneficiarySelector).data;
  const quoteCountryDestination = useSelector(QuotationSelector).data
    .countryDestination as string;

  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const dispatch = useDispatch<AppDispatch>();
  const { countries, countriesWithoutUSAOptions } = useCountry();
  const [accountAdditionalFields, setAccountAdditionalFields] =
    useState<
      Array<{ accountId: string; additionalFields: AdditionalField[] }>
    >();

  const { subdivisionList, getSubdivisionOptions } = useSubdivision();
  const {
    isLoading: beneficiaryIsLoading,
    getBeneficiaryInformation,
    updateBeneficiaryId,
    addAccount,
    deleteAccountBeneficiary,
    error: beneficiaryError,
    getMessageError,
    errorMessage,
  } = useBeneficiary();
  const [showErrorBeneficiary, setShowErrorBeneficiary] = useState(false);

  const {
    bankList,
    isLoading: destinationIsLoading,
    getDestinationsByCountry,
  } = useDestination();
  const { currentPaymentDestination, currentDeliveryMethodQuote } =
    useSelector(QuotationSelector).data;
  const { bankSelected } = useSelector(BeneficiarySelector).data;

  //? we always prioritize country selected in beneficiary flow, then we check if there is one country set in quotation
  const country =
    bankSelected?.country ||
    beneficiaryEdit?.country ||
    quoteCountryDestination;

  const countryIcon =
    country &&
    countries.find((exchange) => exchange.countryCode === country)?.icon;

  const form = useForm<any>({
    defaultValues: {
      accounts:
        !isEmpty(beneficiaryEdit?.accounts) && showView === "editAccount"
          ? beneficiaryEdit?.accounts
              ?.filter((acc) => acc.destinationId === bankSelected?.idBankName)
              .map((acc) => ({
                ...acc,
                country,
              }))
          : [
              {
                country,
                id: "",
                accountTypeId: "",
                accountNumber: "",
                destinationId: "",
                destination: "",
              },
            ],
    },
    mode: "onChange",
  });
  const {
    control,
    formState: { errors, isValid: isFormValid },
    getValues,
    setValue,
    handleSubmit,
  } = form;
  const { fields, append, remove } = useFieldArray({
    name: "accounts",
    control,
  });

  const isLoading = destinationIsLoading || beneficiaryIsLoading;

  async function fetchBanks(countryCode: string) {
    await getDestinationsByCountry("D2B", countryCode);
  }

  useEffect(() => {
    if (country && language) {
      getSubdivisionOptions(country, language);
    }
  }, [country, language]);

  useEffect(() => {
    if (allowedCountries.includes(country)) {
      fetchBanks(country);
    }
  }, []);

  useEffect(() => {
    if (beneficiaryEdit?.accounts && showView == "editAccount")
      setValue("destination", bankSelected?.idBankName);
  }, [beneficiaryEdit]);

  useEffect(() => {
    if (!beneficiaryEdit && params.beneficiaryId) {
      getBeneficiaryInformation(params.beneficiaryId);
    } else if (!beneficiaryEdit) {
      navigate(-1);
    }
  }, []);

  const processSubmit = handleSubmit(async (data) => {
    data.accounts.map((account: Account) => {
      account.destinationId = getValues("destination");
      account.destination = bankList.find(
        (dest) => dest.id === getValues("destination")
      )?.destination;
    });

    if (beneficiaryEdit) {
      const formData = {
        ...beneficiaryEdit,
        accounts: data.accounts,
        dateOfBirth: beneficiaryEdit.dateOfBirth,
      };

      if (formData.accounts) {
        // save new added accounts
        await Promise.all(
          data.accounts
            .filter((acc: Account) => !acc.id)
            .map((acc: Account) =>
              addAccount({
                account: { ...acc, destinationId: data.destination },
                beneficiaryId: beneficiaryEdit?.beneficiaryId,
              })
            )
        );

        // update existing accounts
        formData.accounts = data.accounts
          ?.filter((acc: Account) => !!acc.id)
          ?.map((acc: Account, index: number) => ({
            ...acc,
            destination: data.destination,
            additionalFieldInfo:
              accountAdditionalFields
                ?.find((accFields) => accFields.accountId === acc.id)
                ?.additionalFields?.filter(
                  (field) => field.fieldBelongsTo !== "Transaction"
                ) || [],
          }));

        if (!isEmpty(formData.accounts)) {
          const responseUpdate = await updateBeneficiaryId(
            formData,
            beneficiaryEdit?.beneficiaryId
          );

          if (responseUpdate) {
            showModal({
              modalType: "success",
              message: t(
                "Beneficiaries.EditBeneficiary.titleModalConfirmartion"
              ),
            });

            dispatch(
              BeneficiarySlice.actions.setBeneficiarySelected(undefined)
            );
            // TODO: validate navigations for send money flow
            navigate("/dashboard", {
              state: {
                previous: "createRecipient",
                activeTab:
                  previousView === "dashboard" ? "dashboard" : "recipients",
              },
            });
            // TODO: set beneficiary for quotation state
          } else {
            setShowErrorBeneficiary(true);
          }
        } else {
          showModal({
            modalType: "success",
            message: t("Beneficiaries.EditBeneficiary.titleModalConfirmartion"),
          });

          const recipient = {
            ...beneficiaryEdit,
            account: data.accounts?.map((acc: Account, index: number) => ({
              ...acc,
              additionalFieldInfo: [],
            })),
            dateOfBirth: beneficiaryEdit.dateOfBirth,
          };
          if (data.accounts.length === 1) {
            recipient.accountSelected = data.accounts[0];
          }

          dispatch(BeneficiarySlice.actions.setBeneficiarySelected(undefined));

          navigate("/dashboard", {
            state: {
              previous: "createRecipient",
              activeTab:
                previousView === "dashboard" ? "dashboard" : "recipients",
            },
          });
        }
      }
    }
  });

  const setAdditionalFields = (
    accountId: string,
    additionalFields: AdditionalField[]
  ) => {
    const accAdditionalFields = accountAdditionalFields
      ? [...accountAdditionalFields]
      : [];
    if (accAdditionalFields) {
      const accountIdx = accAdditionalFields.findIndex(
        (accFields, idx) =>
          accFields.accountId === accountId ||
          idx.toString() === accountId.toString()
      );

      if (accountIdx !== -1) {
        accAdditionalFields[accountIdx].additionalFields = additionalFields;
      } else {
        accAdditionalFields.push({
          accountId,
          additionalFields,
        });
      }
      setAccountAdditionalFields(accAdditionalFields);
    }
  };

  const closeAddBank = () => {
    navigate(-1);
  };

  const handleDelete = (fieldIdx: number) => {
    const accountId = beneficiaryEdit?.accounts?.find(
      (acc, idx) => idx === fieldIdx
    );
    if (accountId)
      showModal({
        modalType: "confirmationModal",
        title: t("Beneficiaries.EditBeneficiary.titleModalDeleteAccount"),
        message: t("Beneficiaries.EditBeneficiary.messageModalDeleteAccount"),
        handleConfirm: () => {
          remove(fieldIdx);
          deleteAccount(accountId.id);
        },
      });
  };

  const deleteAccount = async (accountId: string) => {
    if (beneficiaryEdit?.beneficiaryId) {
      await deleteAccountBeneficiary(beneficiaryEdit.beneficiaryId, accountId);
    }
    await getBeneficiaryId();
    setOpenModal(false);
    navigate(-1);
  };

  function createAccountObj(index: number): Account {
    return {
      index,
      country,
      id: "",
      accountTypeId: "",
      accountNumber: "",
      bankName: "",
      destinationId: getValues("destination") || "",
      destination: getValues("destination") || "",
      destinationList: bankList,
      subdivisionList,
    };
  }

  const getBeneficiaryId = async () => {
    if (beneficiaryEdit?.beneficiaryId) {
      await getBeneficiaryInformation(beneficiaryEdit.beneficiaryId);
    }
  };

  useEffect(() => {
    if (beneficiaryError) {
      getMessageError(beneficiaryError);
    }
  }, [beneficiaryError]);

  const handleClose = () => {
    setShowErrorBeneficiary(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={isLoading} roundedContent={false}>
        <FormProvider {...form}>
          <form onSubmit={processSubmit}>
            <Container>
              <HeadContainer>
                <Text
                  size={0.5}
                  weight={500}
                  color="black"
                  margin="8px 0"
                  align="left"
                >
                  {showView == "editAccount"
                    ? t("Beneficiaries.EditBeneficiary.title")
                    : ""}
                </Text>
                <CloseContainer>
                  {fields.length == 1 && showView == "editAccount" && (
                    <Icon
                      icon={"trash"}
                      color="black"
                      onClick={() => handleDelete(0)}
                    />
                  )}

                  <Icon icon="iconX" color="black" onClick={closeAddBank} />
                </CloseContainer>
              </HeadContainer>
              <FlexContainer p="1rem 0rem">
                <CountryContainer>
                  <Text
                    size={0}
                    weight={500}
                    color="black"
                    margin="8px 0"
                    align="left"
                  >
                    {t("Beneficiaries.Account.Country")}
                  </Text>
                  <FlexItem w="auto">
                    {countryIcon && <CountryIcon src={countryIcon} />}
                    <Text
                      size={0}
                      weight={500}
                      color="black"
                      margin="8px 0"
                      align="left"
                    >
                      {
                        countriesWithoutUSAOptions.find(
                          (c) => c.value === country
                        )?.value
                      }
                    </Text>
                  </FlexItem>
                </CountryContainer>
              </FlexContainer>

              <Row>
                <Column span={12} px={1}>
                  <Text
                    size={0}
                    weight={500}
                    color="black"
                    margin="8px 0"
                    align="left"
                  >
                    {t("Beneficiaries.AddAccount.details")}
                  </Text>
                </Column>
                <Column span={12} px={1} py={2}>
                  <Controller
                    control={control}
                    name={"destination"}
                    rules={{
                      required: t("Forms.required"),
                    }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <SearchSelect
                        labelProps={{ color: "grey", size: 0 }}
                        errors={errors}
                        options={bankList || []}
                        name="destination"
                        placeholder=""
                        onBlur={onBlur}
                        onChange={(sel) => {
                          onChange(sel?.value || "");
                        }}
                        value={bankList?.find((b) => b.value === value)}
                        placeholderInput={t(
                          "Beneficiaries.AddAccount.bankName"
                        )}
                      />
                    )}
                  />
                </Column>
              </Row>

              {fields.map((fieldGroup, index) => (
                <BeneficiaryAccountCard
                  key={index}
                  name={`${t("Beneficiaries.EditBeneficiary.account")} ${
                    index + 1
                  }`}
                  index={index}
                  account={
                    beneficiaryEdit?.account?.find(
                      (acc, idx) =>
                        acc.destinationId === getValues("destination") &&
                        idx === index
                    ) || createAccountObj(index)
                  }
                  bankList={bankList}
                  subdivisionList={subdivisionList}
                  showAsCard={false}
                  showDestinationNameInput={false}
                  onConfirmationModal={() => handleDelete(index)}
                  onAdditionalFields={(additionalFields) => {
                    const account =
                      beneficiaryEdit?.account?.find(
                        (acc, idx) =>
                          acc.destinationId === getValues("destination") &&
                          idx === index
                      ) || createAccountObj(index);
                    setAdditionalFields(account.id, additionalFields);
                  }}
                  showDelete={fields.length > 1}
                  bankSelected={getValues("destination")}
                />
              ))}

              <AddButtonContainer>
                <AddAccountButton
                  onClick={() => append(createAccountObj(fields.length + 1))}
                >
                  + {t("Beneficiaries.Account.buttonAddAccount")}
                </AddAccountButton>
              </AddButtonContainer>
              <Row>
                <Column span={12} px={1}>
                  <ButtonSection>
                    <Button
                      text={t("Beneficiaries.Account.buttonContinue")}
                      variant="primary"
                      sizeText="large"
                      sizeButton="large"
                      disabled={!isFormValid}
                    />
                  </ButtonSection>
                </Column>
              </Row>
            </Container>
          </form>
        </FormProvider>
        {modal}
        <ToastNotifications
          status="error"
          show={showErrorBeneficiary}
          message={tModals(errorMessage)}
          closeNotification={() => handleClose()}
        />
      </Layout>
    </ThemeProvider>
  );
};
