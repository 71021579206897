import { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// States
import { AppDispatch, State } from "../../Redux/Store";

// Components
import Button from "../../Components/Button";
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import Text from "../../Components/Text";

// Own Components
import PaymentsCard from "./Components/PaymentsCard.component";

import SendolaPayLogo from "../../Assets/Icons/sendola-pay-icon.svg";
import PaymentSendolaCardLogoSrc from "../../Assets/Img/sendola-pay-logo.png";

import SquareLogo from "../../Assets/Img/SquareLogoBlack.png";
import ZelleLogo from "../../Assets/Img/zelle-rounded.png";

import {
  disclaimerUniteller,
  PaymentMethodsContainer,
  PaymentSendolaCardLogo,
} from "./Payments.styled";
import { PaymentsOptions } from "../../Types/Payments";

// Global State
import {
  QuotationSlice,
  QuotationSelector,
} from "../../Redux/Quotation/Quotation.slice";

import { PAYMENTS_OPTIONS } from "../../Constants/Payments";
import { CardWrapper } from "./Components/PaymentsCard.styled";

import InConstructionModal from "../../Components/Modal/InConstructionModal";
import { QuotePaymentMethod } from "../../Modules/Quote/domain/QuoteDeliveryMethod";
import { useSendMoneyStepper } from "../../Hooks/useSendMoneyStepper";
import { ProgressSteps } from "../../Components/Steps";
import { useSendolaPayPaymentOrder } from "../../Hooks/useSendolaPayPaymentOrder";
import { SendolaPayOrderSelector } from "../../Redux/PaymentOrder/SendolaPayOrder.slice";
import isEmpty from "lodash.isempty";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { Codes } from "../../Modules/PaymentOrders/domain/VelocityLimit";
import { useModal } from "../../Hooks/useModal";
import Icon from "../../Components/Icon";
import IconFont from "../../Components/IconFont";
import { SendolaPayTransactionDetail } from "../../Modules/PaymentOrders/domain/SendolaPay";
import { globalConfigSelector } from "../../Redux/GlobalConfig/GlobalConfig.slice";
import { selectorLanguage } from "../../Redux/Translate";
import { PaymentOrderVerifySelector } from "../../Redux/PaymentOrder/PaymentOrderVerify.slice";
import { userIdSelector } from "../../Redux/User/User.slice";
import { useFeature } from "../../Hooks/useFeature";
import { JustifyContent } from "../../Types/Column";

const Payments = () => {
  const [tSendMoney] = useTranslation("sendMoney");
  const [tModals] = useTranslation("modals");
  const [t] = useTranslation("global");
  const radioRef = useRef<Array<HTMLInputElement>>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: State) => state.theme);
  const [currentPayment, setCurrentPayment] = useState({} as PaymentsOptions);
  const [sortedPayments, setSortedPayments] = useState(
    [] as (QuotePaymentMethod & { deliveryTime: string })[]
  );
  const [sendolaPayBalance, setSendolaPayBalance] =
    useState<SendolaPayTransactionDetail | null>(null);
  const {
    currentQuotation,
    currentPaymentMethod,
    currentDeliveryMethodQuote,
    currentPaymentDestination,
  } = useSelector(QuotationSelector).data;
  const { statusSendolaPay } = useSelector(SendolaPayOrderSelector).data;
  const { gpTier } = useSelector(globalConfigSelector);
  const { language } = useSelector(selectorLanguage);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const handlePopUp = () => setIsShowPopup(!isShowPopup);
  const { steps, activeStep } = useSendMoneyStepper({ initialActiveStep: 3 });
  const {
    getPrivacypolicyStatusStatus,
    getTransactionDetail,
    getShowSendolaPayStatus,
    isLoading,
    personId,
  } = useSendolaPayPaymentOrder();
  const { getStatusVelocityTxnSendolaPay, isLoading: loadingVelocity } =
    usePaymentOrder();
  const unitellerUrl = import.meta.env.VITE_UNITELLER_URL;
  const loadingPerson = useSelector((state: State) => state.PersonsKYC.loading);
  const loading = useSelector((state: State) => state.sendolaPayOrder.loading);
  const isLoad = loading || loadingPerson || isLoading || loadingVelocity;
  const { currentVerifyResponse } = useSelector(
    PaymentOrderVerifySelector
  ).data;
  const { modal: velocityModal, showModal: showVelocityModal } = useModal();
  const [errorVelocityLimit, setErrorVelocityLimit] = useState(false);
  const userIdRedux = useSelector(userIdSelector);
  const userId = useMemo(() => userIdRedux || "", [userIdRedux]);
  const { newQuoterFeature, squarePaymentMethodFeature } = useFeature();

  const {
    modal: serviceUnavailableModal,
    showModal: showServiceUnavailableModal,
  } = useModal();

  const getPaymentType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPayment(PAYMENTS_OPTIONS[Number(event?.target?.value)]);
  };

  const goToPayment = async () => {
    if (currentPayment) {
      if (currentPayment.path === "/payments-sendola-pay-instructions") {
        getStatusPrivacypolicy();
      } else if (currentPayment.path === "/square") {
        if (currentVerifyResponse) {
          navigate("/review-transaction");
        }
      } else {
        navigate(currentPayment.path);
      }
    }
  };

  const getStatusPrivacypolicy = async () => {
    if (personId) {
      const result = await getPrivacypolicyStatusStatus(personId);
      if (result?.senderPrivacyPolicyStatus) {
        if (sendolaPayBalance?.isTotalTransactionAmountPositive) {
          navigate("/review-transaction");
        } else {
          navigate("/payment-sendola-pay-balance");
        }
      } else {
        navigate("/payments-sendola-pay-instructions");
      }
    }
  };

  const showServiceUnavailablePopup = () => {
    showServiceUnavailableModal({
      modalType: "error",
      title: tModals("serviceUnavailable.title"),
      errorMessage: tModals("serviceUnavailable.errorMessage"),
      hideContactSuppport: true,
      icon: <Icon icon="sendolaPayLogo" color="white" size="xxlarge" />,
    });
  };

  const selectCard = (currentCard: number) => {
    // TODO: improve this part in order to disable methods, maybe with a flag from backend endpoint
    // if (currentCard === 0) {
    //   showServiceUnavailablePopup();
    // }
    if (![0, 1, 2, 3].includes(currentCard)) {
      handlePopUp();
    } else if (radioRef.current[currentCard]) {
      radioRef.current[currentCard].checked = true;
      const currentPaymentOption =
        PAYMENTS_OPTIONS[Number(radioRef.current[currentCard].value)];
      setCurrentPayment(currentPaymentOption);

      if (currentDeliveryMethodQuote) {
        dispatch(
          QuotationSlice.actions.setCurrentPaymentMethod(
            currentDeliveryMethodQuote.paymentMethods.find(
              (m) => m.paymentOrigin === currentPaymentOption.paymentOrigin
            )
          )
        );
      }
    }
  };

  const getPaymentMethodFee = () => {
    const paymentsArray: (QuotePaymentMethod & { deliveryTime: string })[] = [];
    let currentMethodIndex = 1;

    if (currentDeliveryMethodQuote) {
      const deliveryTimesMap = {
        TIER1: {
          free: t("Payments.sendolaPay.deliveryTimes.minutes"),
          cost: "",
        },
        TIER2: {
          free: t("Payments.sendolaPay.deliveryTimes.nextDay"),
          cost: t("Payments.sendolaPay.deliveryTimes.minutes"),
        },
        TIER3: {
          free: t("Payments.sendolaPay.deliveryTimes.nextDay"),
          cost: t("Payments.sendolaPay.deliveryTimes.minutes"),
        },
        TIER4: {
          free: "",
          cost: "",
        },
      };
      PAYMENTS_OPTIONS.forEach((method) => {
        let result = currentDeliveryMethodQuote.paymentMethods.find(
          (p) => p.paymentOrigin === method.paymentOrigin
        );
        if (result) {
          if (statusSendolaPay?.mustShowSendolaPay && !errorVelocityLimit) {
            result = {
              ...result,
              default: result.paymentOrigin === "Sendola Card",
            };
          }
          paymentsArray.push({
            ...result,
            deliveryTime: gpTier
              ? result.paymentMethodFee > 0
                ? deliveryTimesMap[gpTier]?.cost
                : deliveryTimesMap[gpTier]?.free
              : "",
          });
        }
      });

      const defaultIdx = paymentsArray.findIndex((m) => m.default);
      if (defaultIdx !== -1) {
        currentMethodIndex = defaultIdx;
      }
      setSortedPayments(paymentsArray);

      if (currentMethodIndex !== -1) {
        selectCard(currentMethodIndex);
        dispatch(
          QuotationSlice.actions.setCurrentPaymentMethod(
            paymentsArray.find((m) => m.default)
          )
        );
      }
    }
  };

  const getStatusSendolaPay = async () => {
    await getShowSendolaPayStatus();
    const velocityLimit = await getStatusVelocityTxnSendolaPay();
    if (
      velocityLimit &&
      velocityLimit.errors?.some((error: Codes) => error.code.startsWith("VL"))
    ) {
      setErrorVelocityLimit(true);
      showVelocityModal({
        modalType: "velocityLimitSendolaPay",
        limitType: velocityLimit.errors?.find(
          (error: Codes) => error.code === "VL011"
        )
          ? "month"
          : "day",
      });
    }
  };

  const getSendolaPayBalance = async () => {
    if (currentQuotation?.amount && personId) {
      const totalWithFees =
        currentQuotation.amount +
        (currentDeliveryMethodQuote?.fee || 0) +
        (currentPaymentMethod?.paymentMethodFee || 0);
      const amount = {
        totalTransactionAmount: totalWithFees,
      };

      const response = await getTransactionDetail(personId, amount);
      if (response) {
        setSendolaPayBalance(response);
      }
    }
  };

  const hasPaymentsMethod = sortedPayments.length > 0;

  useEffect(() => {
    if (statusSendolaPay?.mustShowSendolaPay && radioRef.current.length > 0) {
      getPaymentMethodFee();
    }
  }, [statusSendolaPay, radioRef.current, language, currentVerifyResponse]);

  useEffect(() => {
    if (
      statusSendolaPay?.mustShowSendolaPay &&
      !isEmpty(sortedPayments) &&
      isEmpty(sendolaPayBalance)
    ) {
      getSendolaPayBalance();
    }
  }, [statusSendolaPay, sortedPayments, currentQuotation, personId]);

  useEffect(() => {
    getStatusSendolaPay();
    getPaymentMethodFee();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout
        path={newQuoterFeature ? "/dashboard" : "/beneficiary-selection"}
        textLink={t("buttons.back")}
        loading={isLoad}
      >
        <ProgressSteps steps={steps} activeStep={activeStep} />
        <Container>
          <Row as={PaymentMethodsContainer}>
            <Column span={12} px={3}>
              <Title
                tagName="h1"
                text={t("Payments.title")}
                color="black"
                size={6}
                align="left"
              />
            </Column>
            <Column span={12} px={3} mb={3}>
              <Text color="grey" size={2} align="left" weight={400}>
                {t("Payments.description")}
              </Text>
            </Column>

            {currentDeliveryMethodQuote?.paymentMethods && (
              <>
                <Column span={6} sm={9} md={7} xs={12} px={3}>
                  <Row>
                    {statusSendolaPay?.mustShowSendolaPay && (
                      <Column span={12} mb={6}>
                        <CardWrapper onClick={() => selectCard(0)}>
                          <PaymentsCard
                            hideRadio={errorVelocityLimit}
                            radioRef={(element) => {
                              if (element) {
                                radioRef.current[0] = element;
                              }
                            }}
                            element={
                              <PaymentSendolaCardLogo
                                src={PaymentSendolaCardLogoSrc}
                              />
                            }
                            title={t("Payments.debitCardType")}
                            description={
                              <Text
                                color="black"
                                size="default"
                                desktop={0.5}
                                align="left"
                                weight={400}
                              >
                                {t("Payments.sendolaCardTypeDescriptionPart1")}
                                <span>{t("Payments.sendolaCard")}</span>
                                {t("Payments.sendolaCardTypeDescriptionPart2")}
                              </Text>
                            }
                            iconSrc={SendolaPayLogo}
                            helperText={sortedPayments[0]?.deliveryTime}
                            value="0"
                            getValue={getPaymentType}
                            balance={sendolaPayBalance?.currentBalance}
                          />
                        </CardWrapper>
                      </Column>
                    )}
                    <Column span={12} mb={6}>
                      <CardWrapper onClick={() => selectCard(1)}>
                        <PaymentsCard
                          radioRef={(element) => {
                            if (element) {
                              radioRef.current[1] = element;
                            }
                          }}
                          title={tSendMoney("paymentMethods.zelle.titleSimple")}
                          description={
                            <Text
                              color="black"
                              size="default"
                              desktop={0.5}
                              align="left"
                              weight={400}
                            >
                              {tSendMoney("paymentMethods.zelle.description")}
                            </Text>
                          }
                          iconSrc={ZelleLogo}
                          helperText={`$${
                            hasPaymentsMethod
                              ? sortedPayments[1]?.paymentMethodFee
                              : "0"
                          } ${t("Payments.fee")}`}
                          value="1"
                          getValue={getPaymentType}
                        />
                      </CardWrapper>
                    </Column>
                    {squarePaymentMethodFeature && (
                      <Column span={12} mb={6}>
                        <CardWrapper onClick={() => selectCard(2)}>
                          <PaymentsCard
                            radioRef={(element) => {
                              if (element) {
                                radioRef.current[2] = element;
                              }
                            }}
                            title={t("Square")}
                            description={
                              <Text
                                color="black"
                                size="default"
                                desktop={0.5}
                                align="left"
                                weight={400}
                              >
                                {t("Payments.achTypeDescription")}
                              </Text>
                            }
                            iconSrc={SquareLogo}
                            helperText={`$${
                              hasPaymentsMethod
                                ? sortedPayments[2]?.paymentMethodFee
                                : "0"
                            } ${t("Payments.fee")}`}
                            value="2"
                            getValue={getPaymentType}
                          />
                        </CardWrapper>
                      </Column>
                    )}
                    <Column span={12} mb={6}>
                      <CardWrapper onClick={() => selectCard(3)}>
                        <PaymentsCard
                          radioRef={(element) => {
                            if (element) {
                              radioRef.current[3] = element;
                            }
                          }}
                          title={t("Payments.achType")}
                          description={
                            <Text
                              color="black"
                              size="default"
                              desktop={0.5}
                              align="left"
                              weight={400}
                            >
                              {t("Payments.achTypeDescription")}
                            </Text>
                          }
                          icon="building-bank"
                          helperText={`$${
                            hasPaymentsMethod
                              ? sortedPayments[3]?.paymentMethodFee
                              : "0"
                          } ${t("Payments.fee")}`}
                          value="3"
                          getValue={getPaymentType}
                        />
                      </CardWrapper>
                    </Column>
                  </Row>
                </Column>
                {currentPaymentDestination?.rail === "UNT" && (
                  <Column span={12} as={disclaimerUniteller}>
                    <IconFont
                      name="circle-exclamation"
                      size="medium"
                      color="error"
                    />
                    <Text margin={"0 10px"} size={1}>
                      <Link
                        to={`${unitellerUrl}`}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        {t("ReviewTransaction.disclaimerUniteller")}
                      </Link>
                      {t("ReviewTransaction.disclaimerUniteller2")}
                    </Text>
                  </Column>
                )}
                <Column span={12} px={3} pt={3} pb={3}>
                  <Text align="left">
                    {t("Payments.sendolaPay.adviceText")}
                  </Text>
                </Column>
                <Column
                  span={12}
                  mb={6}
                  justifyContent={JustifyContent.center}
                  px={3}
                >
                  <Button
                    disabled={Object.keys(currentPayment).length === 0}
                    variant="primary"
                    sizeButton="xlarge"
                    sizeText="large"
                    sizeIcon="large"
                    text={tSendMoney("Next")}
                    iconButton="chevronRight"
                    colorIcon="white"
                    onClick={goToPayment}
                  />
                </Column>
              </>
            )}
          </Row>
        </Container>
        <InConstructionModal handleClose={handlePopUp} show={isShowPopup} />
        {errorVelocityLimit && velocityModal}
        {serviceUnavailableModal}
      </Layout>
    </ThemeProvider>
  );
};

export default Payments;
