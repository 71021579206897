import {
  getAdditionalFields,
  getFieldsAppriza,
} from "../Modules/Common/application/getAdditionalFields";
import { getBranches } from "../Modules/Common/application/getBranches";
import { getDestinations } from "../Modules/Common/application/getDestinations";
import { AdditionalFieldsPayload } from "../Modules/Common/domain/AdditionalFields";
import {
  Destination,
  DestinationBranchPayload,
} from "../Modules/Common/domain/Destination";
import { Errors } from "../Modules/Common/domain/Errors";
import { createApiDestinationRepository } from "../Modules/Common/infraestructure/ApiDestinationRepository";
import { QuoteDeliveryMethodCode } from "../Modules/Quote/domain/QuoteDeliveryMethod";
import { useStatus } from "./useStatus";
import { useState } from "react";
import { SelectOption } from "../Models/Select";
import { useDebounce } from "./useDebounce";

export const useDestination = () => {
  const { status, isLoading, hasError, setError, startRequest, endRequest } =
    useStatus();
  const [bankList, setBankList] = useState<Array<SelectOption & Destination>>(
    []
  );

  const getDestinationsByCountry = async (
    deliveryMethod: QuoteDeliveryMethodCode,
    country: string,
    sponsorBank?: string
  ) => {
    startRequest();
    try {
      const response = await getDestinations(createApiDestinationRepository())(
        deliveryMethod,
        country,
        sponsorBank
      );

      let result: Array<SelectOption & Destination> = [];

      if (response) {
        result = response.map((b) => ({
          label: b.destination,
          value: b.id,
          ...b,
        }));
      }
      setBankList(result);

      endRequest();
      return result;
    } catch (error) {
      setBankList([]);
      endRequest({ error: error as Errors, hasError: true });
    }
  };

  const getDestinationBranches = async (payload: DestinationBranchPayload) => {
    startRequest();
    try {
      const response = await getBranches(createApiDestinationRepository())(
        payload
      );

      endRequest();

      return response;
    } catch (error) {
      endRequest({ hasError: true });
      setError(error as Errors);
    }
  };

  const getDestinationAdditionalFields = useDebounce(
    async (payload: AdditionalFieldsPayload) => {
      startRequest();
      try {
        const response = await getAdditionalFields(
          createApiDestinationRepository()
        )(payload);

        endRequest();

        return response;
      } catch (error) {
        endRequest({ hasError: true });
        setError(error as Errors);
      }
    },
    500
  );

  const getAdditionalFieldsAppriza = async (
    countryCode: string,
    language?: string
  ) => {
    startRequest();
    try {
      const response = await getFieldsAppriza(createApiDestinationRepository())(
        countryCode,
        language
      );

      endRequest();

      return response;
    } catch (error) {
      endRequest({ hasError: true });
      setError(error as Errors);
    }
  };

  return {
    bankList,
    status,
    isLoading,
    hasError,
    getDestinationsByCountry,
    getDestinationBranches,
    getDestinationAdditionalFields,
    getAdditionalFieldsAppriza,
  };
};
