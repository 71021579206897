import { useTranslation } from "react-i18next";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { SelectOption } from "../../../Models/Select";
import { IconsFontType } from "../../../Models/IconInterface";
import { CardSelector } from "../../CardSelector/CardSelector.component";

interface DeliveryMethodSelectProps {
  options: SelectOption<string, { iconName?: IconsFontType }>[];
  value?: SelectOption<string, { iconName?: IconsFontType }>["value"];
  onChange?: (
    option: SelectOption<string, { iconName?: IconsFontType }>
  ) => void;
}

export const DeliveryMethodSelect = ({
  options,
  value,
  onChange,
}: DeliveryMethodSelectProps) => {
  const [tSendMoney] = useTranslation("sendMoney");

  return (
    <FlexContainer direction="column">
      <Text size={1} weight={500} align="left" margin="0 0 8px 0">
        {tSendMoney("DeliveryMethod")}
      </Text>
      <CardSelector options={options} value={value} onChange={onChange} />
    </FlexContainer>
  );
};
