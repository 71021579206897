import styled from "styled-components";
import { ifProp, prop } from "styled-tools";
import { FlexProps } from "../../Models/FlexContainer";

const FlexContainer = styled.div<FlexProps>`
  display: flex;
  flex: ${ifProp("flex", prop("flex"), "")};
  flex-direction: ${ifProp("direction", prop("direction"), "row")};
  justify-content: ${ifProp("justify", prop("justify"), "start")};
  align-items: ${ifProp("alignItems", prop("alignItems"), "start")};
  width: ${ifProp("w", prop("w"), "100%")};
  max-width: ${ifProp("mw", prop("mw"), "unset")};
  height: ${ifProp("h", prop("h"), "auto")};
  padding: ${ifProp("p", prop("p"), "0px")};
  margin: ${ifProp("m", prop("m"), "0px")};
  flex-wrap: ${ifProp("flexWrap", "wrap", "nowrap")};
  gap: ${ifProp("gap", prop("gap"), "0")};
  box-sizing: border-box;
`;

const FlexItem = styled.div<FlexProps>`
  box-sizing: border-box;
  display: flex;
  width: ${ifProp("w", prop("w"), "auto")};
  height: ${ifProp("h", prop("h"), "auto")};
  max-width: ${ifProp("mw", prop("mw"), "unset")};
  padding: ${ifProp("p", prop("p"), "0px")};
  order: ${ifProp("order", prop("order"), "0")};
  flex-grow: ${ifProp("grow", prop("grow"), "0")};
  align-self: ${ifProp("alignSelf", prop("alignSelf"), "unset")};
  align-items: ${ifProp("alignItems", prop("alignItems"), "unset")};
  justify-content: ${ifProp("justify", prop("justify"), "start")};
  flex: ${ifProp("flex", prop("flex"), "")};
  flex-direction: ${ifProp("direction", prop("direction"), "row")};
  gap: ${ifProp("gap", prop("gap"), "0")};
  margin: ${ifProp("m", prop("m"), "0px")};
`;

FlexContainer.displayName = "FlexContainer";

export { FlexContainer, FlexItem };
