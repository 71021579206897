import styled from "styled-components";
import { theme } from "styled-tools";

export const CurrencyInfoStyled = styled.div`
  margin: 40px 0;
`;

export const CurrencyInfoLabel = styled.div`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-bottom: 15px;
`;

export const CurrencyInfoDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-left: 20px;
`;

export const CurrencyInfoType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CurrencyInfoAmount = styled.div`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CurrencyInfoIcon = styled.img`
  border-radius: 50%;
  width: 35px;
  height: 35px;
`;
