/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from "react-i18next";
import Text from "../../Text";
import { FlexContainer } from "../../Flex/Flex.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  QuotationSelector,
  QuotationSlice,
} from "../../../Redux/Quotation/Quotation.slice";
import { AppDispatch } from "../../../Redux/Store";
import {
  PaymentMethod,
  PlaidAccount,
  SquarePaymentMethod,
} from "../../../Modules/Common/domain/PaymentMethods";
import { useFeature } from "../../../Hooks/useFeature";
import { useEffect, useRef } from "react";
import { PaymentRadio } from "./PaymentRadio.component";
import ZelleLogo from "../../../Assets/Img/zelle-rounded.png";
import SquareLogo from "../../../Assets/Img/square-rounded.png";

export const PaymentMethodSelect = () => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const radioRef = useRef<Array<HTMLInputElement>>([]);
  const dispatch = useDispatch<AppDispatch>();
  const [tSendMoney] = useTranslation("sendMoney");
  const { paymentMethods, paymentMethodSelected, currentPaymentMethod } =
    useSelector(QuotationSelector).data;
  const { squarePaymentMethodFeature } = useFeature();

  const selectPaymentMethod = (
    paymentMethod: PaymentMethod | PlaidAccount | SquarePaymentMethod
  ) => {
    if (
      paymentMethod?.id !== paymentMethodSelected?.id ||
      paymentMethod?.paymentSource !== paymentMethodSelected?.paymentSource
    ) {
      dispatch(QuotationSlice.actions.setPaymentMethodSelected(paymentMethod));
    }
  };

  useEffect(() => {
    sliderRef.current?.scrollTo(0, 0);
    radioRef.current[0].click();
  }, [radioRef.current]);

  return (
    <FlexContainer direction="column" style={{ position: "relative" }}>
      <Text size={1} weight={500} align="left" margin="0 0 8px 0">
        {tSendMoney("PaymentMethod")}
      </Text>
      <FlexContainer direction="column" gap="1rem">
        <PaymentRadio
          type="economy-express"
          forwardRef={(el) => {
            if (el) {
              radioRef.current[0] = el;
            }
          }}
          value="zelle"
          logo={ZelleLogo}
          title={tSendMoney("paymentMethods.zelle.title")}
          description={tSendMoney("paymentMethods.zelle.description")}
          onChange={(e) => {
            selectPaymentMethod({
              name: "Zelle",
              description: "Zelle",
              paymentSource: "Zelle",
            } as SquarePaymentMethod);
          }}
        />
        {squarePaymentMethodFeature && (
          <PaymentRadio
            type="express"
            forwardRef={(el) => {
              if (el) {
                radioRef.current[1] = el;
              }
            }}
            value="square"
            logo={SquareLogo}
            description={tSendMoney("paymentMethods.square.description")}
            showBankSupported
            onChange={(e) => {
              selectPaymentMethod({
                name: "Square",
                description: "Square Link",
                paymentSource: "Square",
              } as SquarePaymentMethod);
            }}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
