import IconFont from "../../../../Components/IconFont";
import { tStepOneProps } from "../../../../Models/TransactionCompleted";
import {
  StepOneContent,
  StepOneDetail,
  StepOneStyled,
  StepOneTitle,
  StepTitleRow,
} from "./Steps.styled";

export default function StepOne(props: tStepOneProps) {
  const { title, mtcn, description } = props;

  return (
    <StepOneStyled>
      <StepTitleRow>
        <IconFont name="step-one" size="xlarge" gradient />
        <StepOneTitle>{title}</StepOneTitle>
      </StepTitleRow>
      <StepOneContent>{mtcn}</StepOneContent>
      <StepOneDetail>{description}</StepOneDetail>
    </StepOneStyled>
  );
}
