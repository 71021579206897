import styled from "styled-components";
import { PaymentMethodType } from "../../../Modules/Common/domain/PaymentMethods";
import { switchProp, theme } from "styled-tools";
import { GradientCard } from "../../Card/Card.styled";
import { FlexContainer } from "../../Flex/Flex.styled";

export const PaymentMethodTypeTag = styled.span<{ type?: PaymentMethodType }>`
  border-radius: 16px;
  padding: 5px;
  font-size: 12px;
  line-height: 8px;
  text-align: center;

  color: ${switchProp("type", {
    express: theme("Primary_01_600"),
    "economy-express": theme("Primary_Blue"),
    economy: theme("Primary_12"),
  })};
  background-color: ${switchProp("type", {
    express: theme("Primary_01_25"),
    "economy-express": theme("Alice_Blue"),
    economy: theme("Green_06"),
  })};
`;

export const PaymentMethodCard = styled(FlexContainer)`
  flex: 1 1 auto;
  display: flex;
  gap: 12px;

  > img:nth-child(1) {
    width: 32px;
    height: 32px;
  }
`;

export const BankSupportedLogoContainer = styled(FlexContainer)`
  width: auto;
  height: 17px;
  padding: 4px;
`;
