import { RouteObject, createBrowserRouter } from "react-router-dom";

// Own Pages
import ErrorPage from "../Pages/404";
import Dashboard from "../Pages/Dashboard";
import HomePage from "../Pages/HomePage";
import ReviewTransactionPage from "../Pages/ReviewTransaction";
import TransactionCompletedPage from "../Pages/TansactionCompleted";

// Own Components
import AuthGuard from "../Components/AuthGuard/AuthGuard";
import ScrollToTop from "../Components/ScrollToTop";
import BeneficiarySelection from "../Pages/Beneficiaries/BeneficiarySelection";
import Beneficiaries from "../Pages/Beneficiaries";
import Disclosures from "../Pages/Disclosures";
import Settings from "../Pages/Settings";
import HelpAndSupport from "../Pages/HelpAndSupport/HelpAndSupport.page";
import ContactsUs from "../Pages/ContactUs";
import AddBeneficiary from "../Pages/AddBeneficiary/AddBeneficiary.page";
import AddBeneficiaryAccount from "../Pages/AddBeneficiaryAccount/AddBeneficiaryAccount.page";
import RecipientConfirmation from "../Pages/RecipientConfirmation";
import FAQs from "../Pages/FAQs";
import {
  Payments,
  PaymentACH,
  PaymentCard,
  PaymentSendolaPay,
} from "../Pages/Payments";
import { GeoLocationProvider } from "../Contexts/GeoLocation";
import {
  SendolaPayBalance,
  SendolaPayConfirmation,
} from "../Pages/Payments/sendolaPay";
import Legal from "../Pages/Legal";
import {
  SelectDestinationBranch,
  UpdateAdditionalFields,
} from "../Pages/UpdateAdditionalFields";
import PaymentLinkExternalBankAccount from "../Pages/Payments/PaymentLinkExternalBankAccount";
import PaymentLinkExternalBankAccountDetails from "../Pages/Payments/PaymentLinkExternalBankAccountDetails";
import PaymentSelectLinkedBankAccount from "../Pages/Payments/PaymentSelectLinkedBankAccount";
import { SendolaPayInstructions } from "../Pages/Payments/sendolaPay/SendolaPayInstructions.page";
import TransactionHistoryDetailsPage from "../Pages/Transactions/Components/TransactionHistoryDetailsPage.component";
import { CountrySelection } from "../Pages/Beneficiaries/CountrySelection/CountrySelection.page";
import { CreateBeneficiary } from "../Pages/Beneficiaries/Create/CreateBeneficiary.page";
import { Accounts, AddAccounts } from "../Pages/Accounts";
import { EditBeneficiary } from "../Pages/Beneficiaries/Edit/EditBeneficiary.page";
import GoogleMapsLoader from "../Contexts/GeoLocation/GoogleMapsLoader";

export const BASE_PATH = "/";

// eslint-disable-next-line
const PageWrapper = ({ page }: any) => {
  return (
    <GoogleMapsLoader>
      <AuthGuard />
      <ScrollToTop />
      {page}
    </GoogleMapsLoader>
  );
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/review-transaction",
    element: <ReviewTransactionPage />,
  },
  {
    path: "/transaction-completed",
    element: <TransactionCompletedPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/accounts",
    element: <Accounts />,
  },
  {
    path: "/add-accounts",
    element: <AddAccounts />,
  },
  {
    path: "/add-accounts/:beneficiaryId",
    element: <AddAccounts />,
  },
  {
    path: "/beneficiaries",
    element: <Beneficiaries />,
  },
  {
    path: "/beneficiaries/create/country-selection",
    element: <CountrySelection />,
  },
  {
    path: "/beneficiaries/create",
    element: <CreateBeneficiary />,
  },
  {
    path: "/beneficiary/:beneficiaryId/add-account",
    element: <AddBeneficiaryAccount />,
  },
  {
    path: "/beneficiary-selection",
    element: <BeneficiarySelection />,
  },
  {
    path: "/add-beneficiary",
    element: <AddBeneficiary />,
  },
  {
    path: "/beneficiaries/edit",
    element: <EditBeneficiary />,
  },
  {
    path: "/select-recipient-branch",
    element: <SelectDestinationBranch />,
  },
  {
    path: "/update-additional-fields",
    element: <UpdateAdditionalFields />,
  },
  {
    path: "/recipient-confirmation",
    element: <RecipientConfirmation />,
  },
  {
    path: "/disclosures",
    element: <Disclosures />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/help-and-support",
    element: <HelpAndSupport />,
  },
  {
    path: "/payments",
    element: <Payments />,
  },
  {
    path: "/payment-ach",
    element: <PaymentACH />,
  },
  {
    path: "/payment-link-external-bank-account",
    element: <PaymentLinkExternalBankAccount />,
  },
  {
    path: "/payment-select-linked-accounts",
    element: <PaymentSelectLinkedBankAccount />,
  },
  {
    path: "/payment-link-external-bank-account-details",
    element: <PaymentLinkExternalBankAccountDetails />,
  },
  {
    path: "/payment-card",
    element: <PaymentCard />,
  },
  {
    path: "/payment-sendola-pay",
    element: <PaymentSendolaPay />,
  },
  {
    path: "/payments-sendola-pay-instructions",
    element: <SendolaPayInstructions />,
  },
  {
    path: "/payment-sendola-pay-balance",
    element: <SendolaPayBalance />,
  },
  {
    path: "/payment-sendola-pay-confirmation",
    element: <SendolaPayConfirmation />,
  },
  {
    path: "/transaction-history-details",
    element: <TransactionHistoryDetailsPage />,
  },
  {
    path: "/contact-us",
    element: <ContactsUs />,
  },
  {
    path: "/FAQs",
    element: <FAQs />,
  },
  {
    path: "/GlobalPrivacyPolicy",
    element: <Legal />,
  },
].map((r) => ({
  ...r,
  element: <PageWrapper page={r.element} />,
  errorElement: <ErrorPage />,
}));

export const router = createBrowserRouter(routes, { basename: BASE_PATH });
