import { PaymentOrderPayload } from "../domain/PaymentOrderCreated";
import {
  PaymentOrderRepository,
  PaymentOrderWithSquareRepository,
} from "../domain/PaymentOrderRepository";

export const createPaymentOrder = (
  paymentOrderRepository: PaymentOrderRepository
) => {
  return async (payload: PaymentOrderPayload) => {
    const response = await paymentOrderRepository.createPaymentOrder(payload);

    return response;
  };
};

export const createPaymentOrderSquare = (
  paymentOrderRepository: PaymentOrderWithSquareRepository
) => {
  return async (id: string, paymentMethodFee: number) => {
    const response = await paymentOrderRepository.createPaymentLink(
      id,
      paymentMethodFee
    );

    return response;
  };
};
