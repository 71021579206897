import styled from "styled-components";
import { theme } from "styled-tools";
import { switchCaption } from "../../Contexts/ThemeProvider/Texts";

export const QuoterCardWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 26.5625rem;
  margin: auto;
  hr {
    width: 100%;
    height: 0.5px;
    background-color: ${theme("Black")};
    border: none;
    margin: 0;
  }
`;

export const ExchangeRateContainer = styled.div`
  background-color: ${switchCaption}40;
`;

export const BankSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
