import styled from "styled-components";
import { theme } from "styled-tools";

export const PickUpInstructionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56;
  margin-bottom: 56px;
`;

export const PickUpInstructionsIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const PickUpInstructionsTitle = styled.h3`
  color: ${theme("Content_UX_01")};
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PickUpInstructionsDescription = styled.h4`
  color: ${theme("Content_UX_02")};
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
