import Button from "../../../../Components/Button";
import { Column, Container, Row } from "../../../../Components/Grid";
import IconShape from "../../../../Components/IconShape";

import { tTransactionErrorProps } from "../../../../Models/TransactionError";
// import { Button } from "../../../TansactionCompleted/TransactionCompleted.styled";
import {
  ActionButtons,
  TransactionErrorContainer,
  TransactionErrorMessage,
  TransactionErrorTitle,
  TransactionErrorButtons,
} from "./TransactionError.styled";

export default function TransactionError(props: tTransactionErrorProps) {
  const { title, message, retryButton, cancelButton } = props;

  return (
    <Container>
      <Row>
        <Column span={12} as={TransactionErrorContainer}>
          <IconShape
            icon="circle-x"
            iconSize="large"
            border="rounded"
            bgColor="error"
            height={75}
            width={75}
          />
          <TransactionErrorTitle>{title}</TransactionErrorTitle>
          <TransactionErrorMessage
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Column>
      </Row>
      <Row>
        <Column span={12} as={ActionButtons}>
          <Button
            as={TransactionErrorButtons}
            sizeButton="xlarge"
            text={retryButton}
            variant="primary"
            onClick={() => console.log("")}
          />
          <Button
            as={TransactionErrorButtons}
            sizeButton="xlarge"
            text={cancelButton}
            variant="outline"
            onClick={() => console.log("")}
          />
        </Column>
      </Row>
    </Container>
  );
}
