import { useState } from "react";

import { GrayCard } from "../../../../Components/GrayCard/GrayCard.styled";
import TableInfo from "../../../../Components/TableInfo";
import InConstructionModal from "../../../../Components/Modal/InConstructionModal";
import {
  LinkText,
  RecipientOption,
  TransactionDetailDate,
  TransactionDetailDateLabel,
  TransactionDetailDateValue,
  TransactionDetailTitle,
  TransactionDetailsRecipt,
} from "./TransactionDetail.styled";

import { tTransactionDetailsProps } from "../../../../Models/TransactionCompleted";

import Text from "../../../../Components/Text";
import IconFont from "../../../../Components/IconFont";

export default function TransactionDetails(props: tTransactionDetailsProps) {
  const {
    title,
    data,
    dataToPrint,
    dateLabel,
    date,
    dateTime = "",
    reciptLabel,
    notice,
  } = props;

  const [isShowPopup, setIsShowPopup] = useState(false);
  const handlePopUp = () => setIsShowPopup(!isShowPopup);

  return (
    <GrayCard id="content-pdf">
      <TransactionDetailDate>
        <TransactionDetailDateLabel>{dateLabel}</TransactionDetailDateLabel>
        <TransactionDetailDateValue>{date}</TransactionDetailDateValue>
        <TransactionDetailDateValue>{dateTime}</TransactionDetailDateValue>
      </TransactionDetailDate>
      <TransactionDetailTitle>{title}</TransactionDetailTitle>
      <TableInfo data={data} />
      {notice && (
        <Text size={1} weight="700" align="center" margin="0 0 38px 0">
          {notice}
        </Text>
      )}
      <TransactionDetailsRecipt>
        <RecipientOption
          href={dataToPrint.receipt}
          download={`${dataToPrint.mtcn}`}
          target="_blank"
        >
          <LinkText color="primary">{reciptLabel}</LinkText>
          <IconFont name="download" color="primary" />
        </RecipientOption>
      </TransactionDetailsRecipt>
      <InConstructionModal handleClose={handlePopUp} show={isShowPopup} />
    </GrayCard>
  );
}
