import { IconsFontType } from "../../Models/IconInterface";
import { SelectOption } from "../../Models/Select";
import Button from "../Button";
import { GradientCard } from "../Card/Card.styled";
import { FlexContainer } from "../Flex/Flex.styled";
import IconFont from "../IconFont";
import Text from "../Text";

interface CardSelectorProps {
  options: SelectOption<string, { iconName?: IconsFontType }>[];
  value?: SelectOption<string, { iconName?: IconsFontType }>["value"];
  onChange?: (
    option: SelectOption<string, { iconName?: IconsFontType }>
  ) => void;
}

export const CardSelector = ({
  options,
  value,
  onChange,
}: CardSelectorProps) => {
  return (
    <FlexContainer gap="8px" justify="space-between">
      {options.map((opt, index) => (
        <Button
          key={index}
          variant="transparent"
          padding="0px"
          sizeButton="full"
          onClick={() => onChange && onChange(opt)}
        >
          <GradientCard
            active={value === opt.value}
            borderColor="gray"
            padding="7px 5px"
          >
            <FlexContainer direction="column" alignItems="center" gap="7px">
              {opt.iconName && (
                <IconFont name={opt.iconName} color="black" size="medium" />
              )}
              <Text size={1} lineHeight="24px" weight={400} margin={0}>
                {opt.label}
              </Text>
            </FlexContainer>
          </GradientCard>
        </Button>
      ))}
    </FlexContainer>
  );
};
