import styled from "styled-components";
import { textComponentSolidlight } from "../../../Contexts/ThemeProvider/Texts";

export const ContainerTitleAccount = styled.div`
  width: 100%;
  border-left: 8px solid ${textComponentSolidlight};
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
