import { Link } from "react-router-dom";
import styled from "styled-components";
import { SizesKey, handleSizeType } from "../../Utilities/Sizes";
import { ifProp } from "styled-tools";

export const LinkStyled = styled(Link)<{
  variant: string;
  size?: SizesKey;
  underline?: boolean;
}>`
  color: ${(props) =>
    props.variant === "white"
      ? props.theme.Background_01
      : props.variant === "primary"
      ? props.theme.Primary_01
      : props.theme.Background_02};
  cursor: pointer;
  font-family: "Poppins";
  font-size: ${(props) => (props.size ? handleSizeType(props.size) : "12px")};
  text-decoration: ${ifProp("underline", "underline", "none")};

  &:hover {
    text-decoration: ${(props) =>
      props.variant === "white" ? "underline" : "none"};
  }
`;
