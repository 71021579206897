import { useEffect, useState, useCallback } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import isEmpty from "lodash.isempty";

// Own states
import { AppDispatch, State } from "../../Redux/Store";

import { TransferOrdersSlice } from "../../Redux/TransferOrders/TransferOrders.slice";

// Own components
import Button from "../../Components/Button";
import Layout from "../../Components/Layout";

// Own styles
import {
  DashboardCard,
  DashboardCardInner,
  DashboardContent,
  QuoterSection,
  SwitchColumn,
} from "./Dashboard.styled";

// Assets
import Title from "../../Components/Title";
import { Column, Container, Row } from "../../Components/Grid";
import Text from "../../Components/Text";

import { AlignItems, JustifyContent } from "../../Types/Column";

import {
  PaymentTransactionHistory,
  TransactionList,
} from "../../Modules/PaymentOrders/domain/PaymentOrder";

import { useQuote } from "../../Hooks/useQuote";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { usePerson } from "../../Hooks/usePerson";
import Modal from "../../Components/Modal";
import IconFont from "../../Components/IconFont";
import IconShape from "../../Components/IconShape";
import { useVelocityLimits } from "../../Hooks/useVelocityLimits";
import { scrollTopWindow } from "../../Utilities/ScrollTopWindow";
import { QuoteSelectedValue } from "../../Modules/Quote/domain/QuoteSelected";
import { Codes } from "../../Modules/PaymentOrders/domain/VelocityLimit";
import { getQuoteCountryDestinationCode } from "../../Modules/Quote/domain/QuoteCountryDestination";
import { Currency } from "../../Modules/Common/domain/Currency";
import Transactions from "../Transactions";
import Tabs from "../../Components/Tabs/Tabs.components";
import { useDashboard } from "../../Hooks/useDashboard";
import { countrySelector } from "../../Redux/Country/Country.slice";
import Beneficiaries from "../Beneficiaries";
import { DashboardSlice } from "../../Redux/Dashboard/Dashboard.slice";
import { QuoterCardAdvanced } from "../../Components/QuoterCardAdvanced/QuoterCardAdvanced.component";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { BeneficiarySelector } from "../../Redux/Beneficiary/Beneficiary.slice";
import { useModal } from "../../Hooks/useModal";
import {
  QuotationSelector,
  QuotationSlice,
} from "../../Redux/Quotation/Quotation.slice";
import QuoterCard from "../../Components/QuoterCard";
import Skeleton from "../../Components/Skeleton";
import {
  KYC_ABANDONED,
  KYC_APPROVED,
  KYC_DECLINED,
  KYC_EXPIRED,
  KYC_IN_REVIEW,
  KYC_NOT_STARTED,
  KYC_SUBMITTED,
} from "../../Constants/KYCStatus";
import ToastNotifications from "../../Components/ToastNotifications";
import { PlatformAppURL } from "../../Constants/ExternalServices";

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const theme = useSelector((state: State) => state.theme);
  const { t } = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const [statusVeriff, setStatusVeriff] = useState("");
  const [personKYC, setPersonkyc] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [openModalVeriffApproved, setOpenModalVeriffApproved] = useState(false);
  const [openModalIncompleteVeriff, setOpenModalIncompleteVeriff] =
    useState(false);
  const [deliveryMethodSelected, setDeliveryMethodSelected] = useState<
    "D2B" | "CPU"
  >("D2B");
  const [showBeneficiaryNotification, setShowBeneficiaryNotification] =
    useState(false);

  const {
    isLoading: isQuoteLoading,
    quoterValue,
    sendToSelectOptions,
    deliveryMethodsSelectOptions,
    quoteModal,
    currentPaymentDestination,
    handleQuotationChange,
    resetQuote,
    fetchPaymentMethods,
  } = useQuote();
  const {
    isLoading: isTransactionLoading,
    getTransactionDetail,
    getStatusVelocityAmountTxn,
    getStatusVelocityTxn,
    getStatusVelocityTxnDestination,
    getTransactionHistory,
  } = usePaymentOrder();
  const {
    person,
    getCurrentPerson,
    errors,
    isLoading: isPersonKYCLoading,
  } = usePerson();
  const {
    activeTab,
    dashboardTabs,
    loading: dashboardIsLoading,
    newQuoterFeature,
    getDashboardTabs,
    setActiveTab,
  } = useDashboard();
  const { velocityModal, showVelocityModal } = useVelocityLimits();

  const [viewVelocity, setViewVelocity] = useState(false);
  const [velocityLimits, setVelocityLimits] = useState<Codes[]>([]);

  const [transactionList, setTransactionList] =
    useState<PaymentTransactionHistory | null>(null);

  const [openModalPerson, setOpenModalPerson] = useState(false);
  const navigate = useNavigate();
  const [cantSubmit, setCantSubmit] = useState(false);

  const [velocityDestination, setVelocityDestination] = useState<Codes[]>([]);
  const [codesVelocity, setCodesVelocity] = useState<Codes[]>([]);
  const { countries } = useSelector(countrySelector);
  const {
    data: { beneficiarySelected },
  } = useSelector(BeneficiarySelector);
  const {
    currentPaymentMethod,
    currentDeliveryMethodQuote,
    paymentMethodSelected,
  } = useSelector(QuotationSelector).data;
  const {
    isLoading: loadingBeneficiaryVerify,
    verifyErrorModal,
    velocityModal: paymentOrderVelocityModal,
    verifyCurrentSendMoneySelection,
    verificationUniteller,
    saveSenderEmailUniteller,
  } = usePaymentOrder();
  const {
    modal: unitellerDisclaimerModal,
    showModal: showUnitellerDisclaimerModal,
    setOpenModal: setUnitellerDisclaimerModalOpen,
  } = useModal();
  const { modal: idDocumentModal, showModal: showIdDocumentModal } = useModal();

  const loading =
    isPersonKYCLoading ||
    isQuoteLoading ||
    isTransactionLoading ||
    dashboardIsLoading ||
    loadingBeneficiaryVerify;

  const handleModalPerson = () => {
    setOpenModalPerson(!openModalPerson);
  };

  const getPersonKYC = async () => {
    await getCurrentPerson();
  };

  const getVelocity = async () => {
    const response = await getStatusVelocityTxn();
    if (response && response.status === 400) {
      setIsDisabledButton(true);
      setVelocityLimits(response.errors.filter((error: unknown) => error));
    }
  };

  useEffect(() => {
    if (newQuoterFeature) {
      fetchPaymentMethods();
    }
  }, [newQuoterFeature]);

  useEffect(() => {
    if (isEmpty(statusVeriff) && !isEmpty(person)) {
      handleVeriffStatus();
    }
  }, [person, statusVeriff, errors]);

  const handleVeriffStatus = async () => {
    if (errors && errors.status === 404) {
      setPersonkyc(false);
      setStatusVeriff("");
      setOpenModalPerson(true);
      return;
    }

    if (!isEmpty(person)) {
      setPersonkyc(true);
      const kycLevel1 = person.kycLevelStatus.find((kyc) => kyc.level === 1);
      const kycLevel2 = person.kycLevelStatus.find((kyc) => kyc.level === 2);
      const anyLevelDenied =
        kycLevel1?.status === KYC_DECLINED ||
        kycLevel2?.status === KYC_DECLINED;
      const level1Approved = kycLevel1?.status === KYC_APPROVED;
      const level2Approved = kycLevel2?.status === KYC_APPROVED;
      const level2InProcess =
        kycLevel2?.status &&
        ([KYC_SUBMITTED, KYC_IN_REVIEW].includes(kycLevel2?.status) ||
          ![KYC_NOT_STARTED, KYC_DECLINED, KYC_EXPIRED, KYC_ABANDONED].includes(
            kycLevel2?.status
          ));

      if (
        (level2Approved && level1Approved) ||
        ((!kycLevel2 || level2InProcess) && level1Approved)
      ) {
        setStatusVeriff("approved");
      } else if (anyLevelDenied) {
        window.location.href = PlatformAppURL;
      } else {
        setOpenModalPerson(true);
      }
    }
  };

  const idDocumentValidation = async (
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    if (!person.hasDocument) {
      showIdDocumentModal({
        modalType: "idDocumentModal",
        handleConfirm: () => {
          verifyCurrentSendMoneySelection({
            onSuccess,
            onVerifyErrorClose: onError,
          });
        },
      });
    } else {
      verifyCurrentSendMoneySelection({
        onSuccess,
        onVerifyErrorClose: onError,
      });
    }
  };

  const runPaymentOrderVerifications = useCallback(async () => {
    if (beneficiarySelected) {
      const rail = currentPaymentDestination?.rail;
      const onSuccess = () => {
        if (!currentPaymentMethod && !paymentMethodSelected) {
          navigate("/payments");
        } else if (paymentMethodSelected) {
          navigate("/review-transaction");
        }
      };
      const onVerifyErrorClose = () => {
        return;
      };

      if (rail === "UNT") {
        const responseVerification = await verificationUniteller();
        if (
          responseVerification?.showTermsAndConditions ||
          isEmpty(responseVerification?.senderEmail)
        ) {
          showUnitellerDisclaimerModal({
            modalType: "unitellerDisclaimer",
            showEmailInput: isEmpty(responseVerification?.senderEmail),
            emailDefaultValue: responseVerification?.senderEmail || undefined,
            handleConfirm: async (data) => {
              const { email } = data as {
                checkLegalTerms: boolean;
                email: string;
              };
              if (email) {
                await saveSenderEmailUniteller(email);
              } else if (responseVerification?.senderEmail) {
                await saveSenderEmailUniteller(
                  responseVerification.senderEmail
                );
              }
              idDocumentValidation(onSuccess, onVerifyErrorClose);
              setUnitellerDisclaimerModalOpen(false);
            },
          });
        } else {
          idDocumentValidation(onSuccess, onVerifyErrorClose);
        }
      } else {
        verifyCurrentSendMoneySelection({
          onSuccess,
          onVerifyErrorClose,
        });
      }
    }
  }, [
    beneficiarySelected,
    currentPaymentMethod,
    paymentMethodSelected,
    currentPaymentDestination,
  ]);

  const handleSendMoney = useCallback(async () => {
    if (personKYC && !cantSubmit && quoterValue.current.amount) {
      const response = await getStatusVelocityAmountTxn(
        quoterValue.current.amount
      );
      if (
        response?.errors &&
        response.errors.some((error) => error.code.startsWith("VL"))
      ) {
        showVelocityModal({
          codesMessages: response.errors,
          modalTypeVelocity: "velocityLimit",
          pageName: "verify",
        });
      } else {
        if (
          beneficiarySelected &&
          ((currentDeliveryMethodQuote?.deliveryMethodCode === "D2B" &&
            beneficiarySelected.accountSelected) ||
            currentDeliveryMethodQuote?.deliveryMethodCode === "CPU") &&
          currentPaymentDestination
        ) {
          await runPaymentOrderVerifications();
        } else {
          navigate("/beneficiary-selection");
        }
      }
    }
  }, [
    beneficiarySelected,
    beneficiarySelected?.accountSelected,
    currentDeliveryMethodQuote?.deliveryMethodCode,
    currentPaymentDestination,
    currentPaymentMethod,
    paymentMethodSelected,
    cantSubmit,
    personKYC,
    statusVeriff,
    quoterValue.current,
    person,
  ]);

  const handleContinueKYC = () => {
    window.location.href = PlatformAppURL;
  };

  const handleModalApproved = () => {
    setOpenModalVeriffApproved(!openModalVeriffApproved);
  };

  const closeModalAprroved = () => {
    setOpenModalVeriffApproved(false);
  };

  const closeModalIncompleteVeriff = () => {
    scrollTopWindow();
    setOpenModalIncompleteVeriff(false);
  };

  const handleVelocityLimits = async (quoteValues: QuoteSelectedValue) => {
    if (quoteValues.sendTo) {
      const response = await getStatusVelocityTxnDestination(
        Number(quoteValues.amount),
        quoteValues.sendTo
      );

      if (
        response?.errors &&
        response.errors.some((error: any) => error.code.startsWith("VL"))
      ) {
        setVelocityDestination(response?.errors);
      } else {
        setVelocityDestination([]);
      }
    }
  };

  useEffect(() => {
    const velocity = velocityLimits.concat(velocityDestination);
    const hasVL018 = velocity.some((item) => item.code === "VL018");
    const result = velocity.filter((item, index, self) => {
      const isFirstOccurrence =
        self.findIndex((value) => value.code === item.code) === index;
      const isNotExcludedCode = item.code !== "VL007" && item.code !== "VL008";
      const shouldExcludeVL017 = hasVL018 && item.code === "VL017";
      return isFirstOccurrence && isNotExcludedCode && !shouldExcludeVL017;
    });
    setCodesVelocity(result);
    setViewVelocity(false);
    if (result.length > 0) {
      setViewVelocity(true);
      setCantSubmit(true);
    } else {
      setCantSubmit(false);
    }
  }, [velocityLimits, velocityDestination]);

  const changeQuote = (
    value: QuoteSelectedValue,
    cantSubmit: boolean | undefined
  ) => {
    if (
      location.state &&
      location.state?.previous === "createRecipient" &&
      beneficiarySelected
    ) {
      setShowBeneficiaryNotification(true);
      setDeliveryMethodSelected(
        currentDeliveryMethodQuote?.deliveryMethodCode || "D2B"
      );
      const currencyBeneficiary = countries.find(
        (country) => country.countryCode === beneficiarySelected?.country
      )?.currency;
      handleQuotationChange({
        amount: Number(value.amount),
        currency: currencyBeneficiary as QuoteSelectedValue["currency"],
        country: beneficiarySelected?.country,
        deliveryMethod: deliveryMethodSelected,
        withTiers: true,
        sendTo: beneficiarySelected?.accountSelected?.destinationId,
      });
    } else {
      handleQuotationChange({ ...value, withTiers: true });
    }
    if (value.amount) {
      const amountIsUnderMaxAllowed =
        (currentPaymentDestination &&
          !currentPaymentDestination.maximumSendAmount) ||
        (currentPaymentDestination?.maximumSendAmount &&
          value.amount <= currentPaymentDestination?.maximumSendAmount);
      const amountIsOverMinAllowed =
        (currentPaymentDestination &&
          !currentPaymentDestination.minimumSendAmount) ||
        (currentPaymentDestination?.minimumSendAmount &&
          value.amount >= currentPaymentDestination?.minimumSendAmount);
      const amountIsValid = amountIsUnderMaxAllowed && amountIsOverMinAllowed;
      if (amountIsValid) {
        setCantSubmit(Boolean(cantSubmit));
        handleVelocityLimits({ ...value });
      } else {
        setCantSubmit(false);
      }
    }
  };

  useEffect(() => {
    if (location.state?.previous !== "createRecipient") {
      dispatch(BeneficiarySlice.actions.resetState());
    }
    const urlSearchParams = new URLSearchParams(location.search);

    const amountQuoted = urlSearchParams.get("amount");
    const currencyQuoted = urlSearchParams.get("currency");
    const payerQuoted = urlSearchParams.get("payerQuote");
    const destinationQuoted = urlSearchParams.get("paymentDestination");
    const deliveryMethod = urlSearchParams.get("deliveryMethod") as
      | "D2B"
      | "CPU";

    if (!isEmpty(countries)) {
      if (
        amountQuoted &&
        currencyQuoted &&
        payerQuoted &&
        destinationQuoted &&
        deliveryMethod
      ) {
        const country = getQuoteCountryDestinationCode(
          currencyQuoted as unknown as Currency,
          countries
        );
        setDeliveryMethodSelected(deliveryMethod);

        if (country) {
          handleQuotationChange({
            amount: Number(amountQuoted),
            currency: currencyQuoted as QuoteSelectedValue["currency"],
            country: getQuoteCountryDestinationCode(
              currencyQuoted as unknown as Currency,
              countries
            ),
            deliveryMethod: deliveryMethod,
            withTiers: true,
            sendTo: destinationQuoted,
          });
        }
      } else {
        if (
          location.state?.previous !== "createRecipient" &&
          !beneficiarySelected
        ) {
          resetQuote();
        }
      }
    } else {
      resetQuote();
    }

    const tab = location.state?.activeTab || "dashboard";
    dispatch(DashboardSlice.actions.setTabDashboard(tab));
    window.history.replaceState({}, "");

    setActiveTab(tab);
    getDashboardTabs();
    getPersonKYC();
    getVelocity();
    dispatch(DashboardSlice.actions.setLoading(false));
  }, []);

  const handleClose = () => {
    setShowBeneficiaryNotification(false);
  };

  const getPaymentTransactionHistory = async () => {
    const transactions = (await getTransactionHistory(
      {}
    )) as PaymentTransactionHistory;

    if (transactions && transactions.results) {
      transactions.results.map((item) => {
        item.visible = true;
      });
    } else {
      transactions.results = [];
    }

    setTransactionList(transactions);
  };

  const showTransactionDetail = async (transaction: TransactionList) => {
    dispatch(TransferOrdersSlice.actions.setTransactionCard(transaction));
    await getTransactionDetail(transaction.transactionId || "0");
    return navigate("/transaction-history-details");
  };

  useEffect(() => {
    scrollTopWindow();
    if (activeTab === "transactions") {
      getPaymentTransactionHistory();
    }
  }, [activeTab]);

  return (
    <ThemeProvider theme={theme}>
      <Layout
        loading={loading}
        path={PlatformAppURL}
        textLink={
          person && person.name
            ? `${t("Dashboard.greetings")}`
            : t("Dashboard.backButton")
        }
        roundedContent
      >
        <Container as={DashboardContent}>
          <Row>
            <Column
              span={12}
              as={SwitchColumn}
              justifyContent={JustifyContent.center}
            >
              <Tabs
                data={dashboardTabs}
                changeTab={setActiveTab}
                onTabsReload={getDashboardTabs}
              />
            </Column>
            <Column span={12}>
              {activeTab === "dashboard" && (
                <DashboardCard>
                  <Row as={DashboardCardInner}>
                    <Column
                      span={12}
                      xs={12}
                      sm={12}
                      md={12}
                      direction="column"
                      justifyContent={JustifyContent.center}
                      alignItems={AlignItems.center}
                      as={QuoterSection}
                    >
                      {newQuoterFeature === undefined ? (
                        <Skeleton width={425} height={811} />
                      ) : newQuoterFeature ? (
                        <QuoterCardAdvanced
                          value={quoterValue.current}
                          statusButton={statusVeriff}
                          maxAmount={
                            currentPaymentDestination?.maximumSendAmount
                          }
                          minAmount={
                            currentPaymentDestination?.minimumSendAmount
                          }
                          deliveryMethodCurrency={deliveryMethodSelected}
                          deliveryMethodsSelect={deliveryMethodsSelectOptions}
                          sendToOptionsSelect={sendToSelectOptions}
                          handleSubmit={handleSendMoney}
                          onChange={changeQuote}
                          full
                          isDisabledButton={isDisabledButton}
                          textButton={"SendMoney.Submit"}
                          showVelocityLimit={viewVelocity}
                          codesMessages={codesVelocity}
                        />
                      ) : (
                        <QuoterCard
                          value={quoterValue.current}
                          statusButton={statusVeriff}
                          maxAmount={
                            currentPaymentDestination?.maximumSendAmount
                          }
                          minAmount={
                            currentPaymentDestination?.minimumSendAmount
                          }
                          deliveryMethodsSelect={deliveryMethodsSelectOptions}
                          sendToOptionsSelect={sendToSelectOptions}
                          handleSubmit={handleSendMoney}
                          onChange={(value, cantSubmit) => {
                            changeQuote(value, cantSubmit);
                          }}
                          full
                          isDisabledButton={isDisabledButton}
                          textButton={"SendMoney.Submit"}
                          showVelocityLimit={viewVelocity}
                          codesMessages={codesVelocity}
                        />
                      )}
                    </Column>
                  </Row>
                </DashboardCard>
              )}
              {activeTab === "transactions" && (
                <Transactions
                  transactionList={transactionList}
                  transactionDetail={showTransactionDetail}
                />
              )}
              {activeTab === "recipients" && <Beneficiaries />}
            </Column>
          </Row>
        </Container>
      </Layout>
      <Modal show={openModalPerson} handleClose={handleModalPerson}>
        <IconFont name="alert" color="error" size="large" />
        <Title
          tagName="h1"
          size={5}
          text={t("Dashboard.ModalPerson.TitleSuccess")}
          color="black"
        />
        <Text size={3}>{t("Dashboard.ModalPerson.Description")}</Text>
        <Button
          text={t("Dashboard.ModalPerson.Button")}
          onClick={handleContinueKYC}
          sizeText="medium"
          variant="primary"
        />
      </Modal>
      <Modal show={openModalVeriffApproved} handleClose={handleModalApproved}>
        <IconShape
          icon="circle-check"
          iconSize="xlarge"
          border="rounded"
          bgColor="primary"
          height={75}
          width={75}
        />
        <Text size={3} weight={700}>
          {t("Dashboard.Veriff.SubtitleApproved")}
        </Text>
        <Button
          text={t("buttons.next")}
          onClick={closeModalAprroved}
          sizeText="medium"
          variant="primary"
        />
      </Modal>
      <Modal
        show={openModalIncompleteVeriff}
        handleClose={closeModalIncompleteVeriff}
      >
        <IconShape
          icon="alert"
          iconSize="xlarge"
          iconColor="warning"
          bgColor="transparent"
          height={75}
          width={75}
        />
        <Text size={3} weight={700}>
          {t("OpenAccount.Veriff.IncompleteVeriff")}
        </Text>
        <Button
          text={t("buttons.close")}
          onClick={closeModalIncompleteVeriff}
          sizeText="medium"
          variant="primary"
        />
      </Modal>
      <ToastNotifications
        status="success"
        show={showBeneficiaryNotification}
        message={tModals("beneficiaries.create")}
        closeNotification={() => handleClose()}
      />
      {quoteModal}
      {velocityModal}
      {verifyErrorModal}
      {paymentOrderVelocityModal}
      {unitellerDisclaimerModal}
      {idDocumentModal}
    </ThemeProvider>
  );
};

export default withTranslation("ns")(Dashboard);
