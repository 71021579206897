import React, { useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Link, useBlocker, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { isNil } from "lodash";

// Own components
import Button from "../../Components/Button";
import { Column, Container, Row } from "../../Components/Grid";
import CurrencyService from "../../Services/CurrencyService";
import Layout from "../../Components/Layout";
import Destination from "../ReviewTransaction/components/Destination";
import Title from "../../Components/Title";
import Text from "../../Components/Text";
import IconFont from "../../Components/IconFont";

import { AppDispatch, State } from "../../Redux/Store";
import { RowBold, tTableInfoData } from "../../Models/TableInfo";

import PickUpInstructions from "./components/PickUpInstructions";
import { StepOne, StepTwo } from "./components/Steps";
import TransactionDetails from "./components/TransactionDetails/TransactionDetails.component";

import { ButtonContainer } from "../ReviewTransaction/ReviewTransaction.styled";

import {
  MTCNNumber,
  MTCNTitle,
  Description,
  disclaimerUniteller,
  WaitingForFundsDisclaimer,
} from "./TransactionCompleted.styled";

import getDateFnsLocale from "../../Utilities/dateFnsLocale";
import {
  paymentOrderSelector,
  paymentOrderSlice,
} from "../../Redux/PaymentOrder/PaymentOrder.slice";

import { useSendMoneyStepper } from "../../Hooks/useSendMoneyStepper";
import { imgTypes } from "../../Utilities/Icons";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import { rails } from "../../Constants/Rails";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { TransferOrdersSelector } from "../../Redux/TransferOrders";
import { selectorLanguage } from "../../Redux/Translate";
import { JustifyContent } from "../../Types/Column";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { FlexContainer } from "../../Components/Flex/Flex.styled";
import { useModal } from "../../Hooks/useModal";
import { PaymentOrderStatus } from "../../Modules/PaymentOrders/domain/PaymentOrder";

const TransactionCompletedPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: State) => state.theme);
  const paymentOrder = useSelector(paymentOrderSelector).data;
  const unitellerUrl = import.meta.env.VITE_UNITELLER_URL;
  const {
    currentPaymentDestination,
    currentPaymentMethod,
    currentDeliveryMethodQuote,
  } = useSelector(QuotationSelector).data;
  const { transactionDetails: transactionDetailsById } = useSelector(
    TransferOrdersSelector
  ).data;

  const { t, i18n } = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const [transactionDetails, setTransactionDetails] = useState<
    tTableInfoData[]
  >([]);

  const { isLoading: paymentOrderIsLoading, getTransactionDetail } =
    usePaymentOrder();

  const { modal: modalZelle, showModal: showModalZelle } = useModal();

  const railName = useMemo(() => {
    return currentPaymentDestination?.rail
      ? rails[currentPaymentDestination?.rail as keyof typeof rails]
      : null;
  }, [currentPaymentDestination?.rail]);

  const isBankTransfer = useMemo(
    () => paymentOrder?.deliveryMethod === "D2B",
    [paymentOrder]
  );

  const isUnitellerTransaction =
    currentPaymentDestination?.rail === "UNT" ||
    paymentOrder?.rail === "UNT" ||
    paymentOrder?.rail === "Uniteller";

  const isZelleTransaction =
    paymentOrder?.payment?.productType.toLowerCase() === "zelle" ||
    currentPaymentMethod?.paymentOrigin.toLowerCase() === "zelle";

  useEffect(() => {
    if (isNil(paymentOrder) && search) {
      const urlSearchParams = new URLSearchParams(location.search);
      const paymentOrderId = urlSearchParams.get("paymentorderId");

      if (paymentOrderId) {
        getTransactionDetail(paymentOrderId);
      } else {
        return navigate("/dashboard");
      }
    }
  }, []);

  useEffect(() => {
    setTransferDetails();
  }, [language]);

  useEffect(() => {
    if (transactionDetailsById) {
      const { receipt, receiver, instruction } = transactionDetailsById;
      dispatch(
        paymentOrderSlice.actions.setPaymentOrderCreated({
          ...transactionDetailsById,
          mtcn: transactionDetailsById.mtcn,
          mtn: transactionDetailsById.mtn,
          orderedAt: instruction.quote.quoteUpdatedAt,
          deliveryMethod: instruction.quote.type,
          receipt,
          receiver: {
            firstName: receiver.firstName,
          },
          instruction: {
            destination: {
              country: instruction.destination.country,
              currency: instruction.destination.currency,
            },
            origination: {
              amount: instruction.origination.amount,
            },
            quote: {
              fee: currentDeliveryMethodQuote?.fee || 0,
              paymentMethodFee: currentPaymentMethod?.paymentMethodFee || 0,
              fx: instruction.quote.fx,
            },
          },
        })
      );
    }
  }, [transactionDetailsById]);

  const setTransferDetails = () => {
    if (!isNil(paymentOrder)) {
      const transactionDetailsFormat = [
        {
          id: "destinationCountry",
          label: t(
            "TransactionCompleted.TransactionDetails.destinationCountry"
          ),
          value: paymentOrder.instruction.destination.country || "",
          bold: RowBold.LEFT,
        },
        {
          id: "to",
          label: t("TransactionCompleted.TransactionDetails.to"),
          value: `${paymentOrder.receiver.firstName} ${
            paymentOrder.receiver.lastName || ""
          }`,
          bold: RowBold.LEFT,
        },
        {
          id: "payOutMethod",
          label: t("TransactionCompleted.TransactionDetails.payOutMethod"),
          value: isBankTransfer
            ? t("TransactionCompleted.TransactionDetails.DirectToBank")
            : t("TransactionCompleted.TransactionDetails.CashPickUp"),
          bold: RowBold.LEFT,
        },
        {
          id: "transferAmount",
          label: t("TransactionCompleted.TransactionDetails.transferAmount"),
          label2: "Exchange rate",
          value: `${CurrencyService.formatNumberToCurrency(
            paymentOrder.instruction?.origination.amount
          )}`,
          value2: ` 1.00 USD = ${paymentOrder.instruction.quote.fx} ${paymentOrder.instruction.destination.currency}`,
          bold: RowBold.NONE,
          withDivider: true,
        },
        {
          id: "sendingFee",
          label: t("TransactionCompleted.TransactionDetails.sendingFee"),
          value: `${CurrencyService.formatNumberToCurrency(
            paymentOrder.instruction.quote.fee
          )}`,
          bold: RowBold.NONE,
          withCirclePlus: true,
          padding: "0",
        },
        {
          id: "paymentMethodFee",
          label: t("TransactionCompleted.TransactionDetails.paymentMethodFee"),
          value: `${CurrencyService.formatNumberToCurrency(
            paymentOrder.instruction.quote.paymentMethodFee
          )}`,
          bold: RowBold.NONE,
          withCirclePlus: true,
          padding: "10px 0 0 0",
          withDivider: true,
        },
        {
          id: "totalPayment",
          label: t("TransactionCompleted.TransactionDetails.totalPayment"),
          value: `${
            paymentOrder.payment?.amount
              ? CurrencyService.formatNumberToCurrency(
                  paymentOrder.payment.amount
                )
              : CurrencyService.formatNumberToCurrency(
                  paymentOrder.instruction.origination.amount +
                    paymentOrder.instruction.quote.fee +
                    paymentOrder.instruction.quote.paymentMethodFee
                )
          }`,
          bold: RowBold.ROW,
        },
      ];

      setTransactionDetails(transactionDetailsFormat);
    }
  };

  async function handleFinishButton(
    event: React.MouseEvent<HTMLAnchorElement>
  ) {
    dispatch(BeneficiarySlice.actions.resetState());
    return navigate("/dashboard");
  }

  const openZelleInstructions = () => {
    if (paymentOrder) {
      showModalZelle({
        modalType: "zelleInstructionsModal",
        finish: true,
        reference: paymentOrder.mtcn,
        amount: paymentOrder.payment.amount
          ? Number(paymentOrder.payment.amount)
          : Number(paymentOrder.instruction.origination.amount) +
            Number(paymentOrder.instruction.quote.fee) +
            Number(paymentOrder.instruction.quote.paymentMethodFee),
      });
    }
  };

  useEffect(() => {
    setTransferDetails();
    if (paymentOrder?.payment?.productType === "Zelle") {
      openZelleInstructions();
    }
  }, [paymentOrder]);

  useBlocker(({ nextLocation }) => nextLocation.pathname !== "/dashboard");

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={paymentOrderIsLoading}>
        <Container>
          <Row>
            <Column span={2} xs={12} />
            <Column span={8} xs={12} m={2}>
              {paymentOrder?.lastStatus?.toLowerCase() ===
                PaymentOrderStatus.funds && isZelleTransaction ? (
                <>
                  <Title
                    size={2}
                    tagName="h1"
                    color="black"
                    text={t("TransactionCompleted.PendingTitle")}
                    align="left"
                  />
                  <Title
                    size={2}
                    tagName="h2"
                    color="black"
                    text={t("TransactionCompleted.PendingDescription")}
                    align="left"
                  />
                </>
              ) : (
                <>
                  <Title
                    size={5}
                    tagName="h1"
                    color="black"
                    text={t("TransactionCompleted.SectionTitle")}
                    align="left"
                  />
                  <Description>
                    {t("TransactionCompleted.SectionDescription")}
                  </Description>
                </>
              )}
            </Column>
          </Row>

          <Row>
            <Column span={12}>
              <Destination
                from={paymentOrder?.instruction?.origination?.country}
                to={paymentOrder?.instruction?.destination?.country}
              />
            </Column>
          </Row>

          <Row>
            <Column span={12} m={2}>
              <MTCNTitle>{t("TransactionCompleted.MTCNTitle")}</MTCNTitle>
              {paymentOrder?.lastStatus?.toLowerCase() ===
                PaymentOrderStatus.funds && isZelleTransaction ? (
                <MTCNNumber letterSpacing="8.5px">
                  {t("TransactionCompleted.PendingMTCN")}
                </MTCNNumber>
              ) : (
                <MTCNNumber>{paymentOrder?.mtcn}</MTCNNumber>
              )}
              {isZelleTransaction && (
                <Button
                  variant="transparent"
                  padding="0px"
                  sizeButton="full"
                  color="zelle"
                  onClick={openZelleInstructions}
                >
                  <FlexContainer gap="11px" justify="center">
                    <IconFont name="info" color="zelle" size="medium" />
                    <Text size={0} weight={600} color="zelle" align="left">
                      {t("Zelle.instructions.cta")}
                    </Text>
                  </FlexContainer>
                </Button>
              )}
            </Column>
          </Row>

          <Row>
            <Column span={2} xs={12} />
            <Column span={8} xs={12} m={2}>
              {!isNil(paymentOrder) && (
                <TransactionDetails
                  dateLabel={t(
                    "TransactionCompleted.TransactionDetails.transactionDate"
                  )}
                  date={format(
                    new Date(paymentOrder.orderedAt),
                    "MMM dd, yyyy",
                    { locale: getDateFnsLocale(i18n.language) }
                  )}
                  dateTime={format(
                    new Date(paymentOrder.orderedAt),
                    "hh:mm aaaa"
                  )}
                  title={t("TransactionCompleted.TransactionDetails.title")}
                  data={transactionDetails}
                  dataToPrint={paymentOrder}
                  notice={
                    currentPaymentDestination?.rail === "UNT"
                      ? t("ReviewTransaction.TransactionUniteller")
                      : railName
                      ? t("TransactionCompleted.TransactionDetails.notice", {
                          rail: railName,
                        })
                      : t("ReviewTransaction.TransactionDetails.notice")
                  }
                  reciptLabel={t(
                    "TransactionCompleted.TransactionDetails.transactionRecipt"
                  )}
                />
              )}
            </Column>
            <Column span={2} xs={12} />
          </Row>

          {isZelleTransaction &&
            paymentOrder?.lastStatus === PaymentOrderStatus.funds && (
              <FlexContainer p="0 16px" m="0 0 40px 0">
                <WaitingForFundsDisclaimer>
                  <IconFont name="info" color="primary" size="medium" />
                  <Text
                    size="default"
                    weight={600}
                    align="left"
                    color="solid_2"
                  >
                    {t("TransactionCompleted.WaitingForFundsDisclaimer")}
                  </Text>
                </WaitingForFundsDisclaimer>
              </FlexContainer>
            )}

          {isUnitellerTransaction && (
            <>
              <Row>
                <Column
                  span={12}
                  my={2}
                  justifyContent={JustifyContent.center}
                  px={2}
                >
                  <img src={imgTypes["logoUniteller"]} alt="logo-header" />
                </Column>
              </Row>
              <Row>
                <Column span={12} as={disclaimerUniteller} px={2}>
                  <IconFont
                    name="circle-exclamation"
                    size="medium"
                    color="error"
                  />
                  <Text margin={"0 10px"} size={1}>
                    <Link
                      to={`${unitellerUrl}`}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      {t("ReviewTransaction.disclaimerUniteller")}
                    </Link>
                    {t("ReviewTransaction.disclaimerUniteller2")}
                  </Text>
                </Column>
              </Row>
            </>
          )}

          {!isBankTransfer && (
            <Row>
              <Column span={12} m={2}>
                <PickUpInstructions
                  title={t("TransactionCompleted.PickUpInstructions.title")}
                  description={t(
                    "TransactionCompleted.PickUpInstructions.description"
                  )}
                />
              </Column>
              <Column span={12} m={2}>
                <StepOne
                  title={t("TransactionCompleted.Step.stepOneTitle")}
                  mtcn={paymentOrder?.mtcn || "# 0000"}
                  description={t(
                    "TransactionCompleted.Step.stepOneDescription"
                  )}
                />
                <StepTwo
                  title={t("TransactionCompleted.Step.stepTwoTitle")}
                  description={t(
                    "TransactionCompleted.Step.stepTwoDescription"
                  )}
                />
              </Column>
            </Row>
          )}

          <Row>
            <Column span={12} xs={12} px={2}>
              <ButtonContainer>
                <Button
                  variant="primary"
                  sizeButton="full"
                  sizeText="large"
                  text={t("TransactionCompleted.ActionButton")}
                  onClick={handleFinishButton}
                />
              </ButtonContainer>
            </Column>
          </Row>
        </Container>
        {modalZelle}
      </Layout>
    </ThemeProvider>
  );
};

export default withTranslation("ns")(TransactionCompletedPage);
