import styled from "styled-components";
import { theme } from "styled-tools";
import mediaQuery, { Screen } from "../../../../Utilities/MediaQuery";

export const DestinationStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin: 12px 0 24px;

  ${mediaQuery[Screen.desktop]} {
    flex-direction: row;
  }
`;

export const DestinationIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const DestinationLabel = styled.div`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
