import { ZelleInstructionsModalProps } from "../../../Models/ModalInterface";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import ZelleLogo from "../../../Assets/Img/zelle-wide-logo.png";
import IconFont from "../../IconFont";
import { StepLogo } from "./ZelleInstructionsModal.styled";
import {
  zelleLinkEn,
  zelleLinkEs,
  zelleSendolaAccount,
  waLinkEn,
  waLinkEs,
} from "../../../Constants/Zelle";
import Button from "../../Button";
import { useState } from "react";
import { CopyTextToClipboard } from "../../../Utilities/CopyTextToClipboard";
import CurrencyService from "../../../Services/CurrencyService";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";
import { isAndroid, isIOS } from "react-device-detect";

export const ZelleInstructionsModal = (props: ZelleInstructionsModalProps) => {
  const [t] = useTranslation("global");
  const [copiedText, setCopiedText] = useState(false);
  const { language } = useSelector(selectorLanguage);

  const zelleRedirect = language === "en" ? zelleLinkEn : zelleLinkEs;
  const zelleSupportWA = language === "en" ? waLinkEn : waLinkEs;

  const copyTextMtcn = () => {
    setCopiedText(true);
    CopyTextToClipboard(props.reference);
    setTimeout(() => {
      setCopiedText(false);
    }, 1000);
  };

  const handleButtonZelle = () => {
    const androidDeepLink =
      "intent://#Intent;scheme=zelle;package=com.zellepay.zelle;end";
    const iosDeepLink = "zelle://";

    if (isAndroid) {
      window.location.href = androidDeepLink;
    } else if (isIOS) {
      window.location.href = iosDeepLink;
    } else {
      window.location.href = zelleRedirect;
    }
  };

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer
        justify="center"
        alignItems="center"
        gap="4px"
        m="0 0 20px 0"
      >
        <Text display="inline" align="left" size={1} weight={600}>
          {props.finish
            ? t("Zelle.instructions.title")
            : t("Zelle.instructions.titleHowTo")}
          &nbsp;
          <img
            src={ZelleLogo}
            width="57px"
            height="32px"
            style={{ display: "inline", margin: "0 4px -9px 4px" }}
          />
          {props.finish ? "" : ` ?`}
        </Text>
      </FlexContainer>
      <FlexContainer>
        <Text size={0.5} weight={600} align="left" margin="0 0 34px 0">
          {props.finish
            ? t("Zelle.instructions.listTitle")
            : t("Zelle.instructions.listTitleHowTo")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="24px" p="0 0 41px 0">
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="device-mobile" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step1.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {t("Zelle.instructions.step1.description")}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="building-bank" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step2.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {t("Zelle.instructions.step2.description")}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="user-rounded" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step3.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {t("Zelle.instructions.step3.description")}
              <Text
                as="span"
                align="left"
                color="zelle"
                weight={600}
                display="inline"
              >
                &nbsp;{`"${zelleSendolaAccount}".`}
              </Text>
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="currency-dollar" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step4.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {props.finish
                ? t("Zelle.instructions.step4.description")
                : t("Zelle.instructions.step4.descriptionHowTo")}
              {props.finish && (
                <Text as="span" display="inline" color="zelle" weight={600}>
                  {` ${CurrencyService.formatNumberToCurrency(
                    props.amount || 0
                  )}`}
                </Text>
              )}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="24px">
          <StepLogo>
            <IconFont name="message" color="zelle" size="medium" />
          </StepLogo>
          <FlexContainer direction="column">
            <Text align="left" weight={600} size={0.5} lineHeight="16px">
              {t("Zelle.instructions.step5.title")}
            </Text>
            <Text
              align="left"
              size="default"
              color="grey"
              weight={400}
              lineHeight="16px"
            >
              {props.finish
                ? t("Zelle.instructions.step5.description")
                : t("Zelle.instructions.step5.descriptionHowTo")}
            </Text>
            {props.finish && (
              <FlexContainer gap="16px">
                <Text as="span" color="zelle" weight={600}>
                  {props.reference}
                </Text>
                <Button
                  variant="transparent"
                  padding="0px"
                  onClick={copyTextMtcn}
                >
                  <Text as="span" color="zelle" weight={600}>
                    <IconFont
                      name={copiedText ? "circle-check" : "copy"}
                      color="zelle"
                      size="small"
                    />
                    {` ${t("global.copy")}`}
                  </Text>
                </Button>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
        {props.finish && (
          <FlexContainer gap="24px" m="0 0 10px 0">
            <StepLogo>
              <IconFont name="cash" color="zelle" size="medium" />
            </StepLogo>
            <FlexContainer direction="column">
              <Text align="left" weight={600} size={0.5} lineHeight="16px">
                {t("Zelle.instructions.step6.title")}
              </Text>
              <Text
                align="left"
                size="default"
                color="grey"
                weight={400}
                lineHeight="16px"
              >
                {t("Zelle.instructions.step6.description")}
              </Text>
            </FlexContainer>
          </FlexContainer>
        )}
        {props.finish && (
          <FlexContainer direction="column" alignItems="center" gap="15px">
            <Button
              variant="zelle"
              sizeText="mLarge"
              sizeButton="large"
              text={t("Zelle.instructions.submit")}
              padding="18px 32px"
              onClick={handleButtonZelle}
            />
            <Text color="grey" size="default" weight={500}>
              {t("Zelle.instructions.helpAdvice")}
            </Text>
            <Button
              variant="outline"
              sizeText="mLarge"
              sizeButton="large"
              text={t("global.contactSupport")}
              padding="18px 32px"
              onClick={() => {
                window.location.href = zelleSupportWA;
              }}
            />
          </FlexContainer>
        )}
      </FlexContainer>
    </Modal>
  );
};
