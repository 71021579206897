import { useSelector } from "react-redux";
import { ModalProps } from "../../../Models/ModalInterface";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import Modal from "../Modal.component";
import { selectorLanguage } from "../../../Redux/Translate";
import Text from "../../Text";
import { FlexContainer } from "../../Flex/Flex.styled";

export const LegalInfoModal = (props: ModalProps) => {
  const { language } = useSelector(selectorLanguage);
  const notesES = [
    'La accesibilidad a los servicios y fondos puede sufrir demoras o estar indisponible debido a una variedad de factores, como el tipo de servicio escogido, la elección de opciones de entrega diferida, el monto transferido, el país receptor, la disponibilidad de moneda, regulaciones aplicables, asuntos de protección al consumidor, requisitos de identificación, limitaciones de entrega, el horario operativo de las oficinas y las variaciones en los husos horarios (denominados en conjunto como "restricciones"). Podrían imponerse restricciones adicionales; para más detalles, revisa nuestros términos y condiciones.',
    "Sendola también obtiene ingresos mediante el cambio de divisas. Al seleccionar un transmisor de dinero, es crucial comparar con detenimiento tanto las tarifas de transferencia como los tipos de cambio. Las tarifas, los tipos de cambio y los impuestos pueden diferir según la marca, el canal y la localización, dependiendo de varios factores. Tanto las tarifas como los tipos de cambio pueden modificarse sin notificación previa.",
    "Si utilizas una tarjeta de crédito, el emisor podría aplicar tarifas por adelanto de efectivo y los correspondientes intereses. Estos costos pueden evitarse utilizando una tarjeta de débito.",
    "Algunos bancos pueden aplicar una tarifa por realizar transferencias bancarias, tanto nacionales como internacionales. Es recomendable consultar con tu banco.",
    "Ten precaución al proporcionar los datos de tu cuenta bancaria. El dinero será depositado en la cuenta que corresponda al número proporcionado. Recuerda: La cuenta de pago del destinatario debe ser una cuenta de cobros en la moneda local.",
    "Por lo general, el dinero se depositará en la cuenta bancaria del destinatario entre [tiempo que toma]  después de que Sendola reciba los fondos. La disponibilidad de los servicios y fondos puede verse afectada por varios factores, incluyendo el servicio seleccionado, opciones de entrega diferida, el monto enviado, el país destino, la disponibilidad de la moneda, regulaciones, protección al consumidor, requisitos de identificación, restricciones de entrega, horarios de las agencias y diferencias horarias.",
    "El costo de cada tipo de transferencia monetaria depende de múltiples factores, como la rapidez de la transferencia, el destino de los fondos y otros aspectos relevantes. Selecciona el método de financiación que mejor se adapte a tus necesidades.",
  ];

  const notesEN = [
    'The availability of services and funds may be delayed or unavailable due to a variety of factors, such as the type of service chosen, the selection of deferred delivery options, the amount transferred, the destination country, currency availability, applicable regulations, consumer protection issues, identification requirements, delivery limitations, the operational hours of the offices, and variations in time zones (collectively referred to as "restrictions"). Additional restrictions may apply; for more details, please review our terms and conditions.',
    "Sendola also earns revenue through currency exchange. When choosing a money transmitter, it is crucial to carefully compare both transfer fees and exchange rates. Fees, exchange rates, and taxes may vary by brand, channel, and location based on various factors. Both fees and exchange rates are subject to change without prior notice.",
    "If you use a credit card, the card issuer may apply cash advance fees and associated interest charges. These costs can be avoided by using a debit card.",
    "Some banks may charge a fee for conducting bank transfers, whether domestic or international. It is advisable to check with your bank.",
    "Exercise caution when providing your bank account information. The money will be deposited into the account corresponding to the provided account number. Remember: The recipient's payment account must be a receiving account in local currency.",
    "Typically, the money will be deposited into the recipient's bank account within [xxxxx] after Sendola receives the funds. The availability of services and funds can be affected by various factors, including the chosen service, deferred delivery options, the amount sent, the destination country, currency availability, regulations, consumer protection, identification requirements, delivery restrictions, agency hours, and time zone differences.",
    "The cost of each type of money transfer depends on multiple factors, including the speed of the transfer, the destination of the funds, and other relevant aspects. Choose the funding method that best suits your needs.",
  ];

  const notes = language === "en" ? notesEN : notesES;

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      {notes.map((note, key) => (
        <FlexContainer key={key} alignItems="start" gap="16px">
          <Text align="left" weight={600} size={2} margin="0 0 16px 0">
            {key + 1}.
          </Text>
          <Text align="left" margin="0 0 16px 0">
            {note}
          </Text>
        </FlexContainer>
      ))}
    </Modal>
  );
};
