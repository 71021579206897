import { useCallback, useEffect, useState } from "react";
import { iTabInfo } from "../Models/TabInfo";
import { useFeature } from "./useFeature";
import Cookies from "universal-cookie";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardSelector,
  DashboardSlice,
} from "../Redux/Dashboard/Dashboard.slice";
import { AppDispatch } from "../Redux/Store";
import { selectorLanguage } from "../Redux/Translate";
import packageJson from "../../package.json";
const cookies = new Cookies();

export const useDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { language } = useSelector(selectorLanguage);
  const { newQuoterFeature, getAppFeatures } = useFeature();
  const { currentTab, loading } = useSelector(DashboardSelector);

  const [activeTab, setActiveTab] = useState<string>(currentTab);
  const [dashboardTabs, setDashboardTabs] = useState<iTabInfo[]>([]);

  const getDashboardTabs = useCallback(async () => {
    let tabs: iTabInfo[] = [
      {
        value: "dashboard",
        label: language === "en" ? "Send money" : "Enviar dinero",
        isActive: activeTab === "dashboard",
        showDescriptionPopup: false,
        isNew: false,
      },
    ];
    const dashboardTabFeatures: iTabInfo[] =
      (await getAppFeatures({ type: "dashboardTab" }))?.map((feat) => {
        const deadline = addDays(
          feat.releaseDate ? new Date(feat.releaseDate) : new Date(),
          25
        );
        const currentDate = new Date();

        const popUpClosed = cookies.get(`${feat.value}DateClicked`);
        const popUpClosedDate = popUpClosed ? new Date(popUpClosed) : null;
        const newTagDeadline = popUpClosedDate
          ? addDays(new Date(popUpClosedDate), 30)
          : null;

        return {
          value: feat.value,
          label: language === "en" ? feat.labelEn || "" : feat.labelEs || "",
          isActive: activeTab === feat.value,
          showDescriptionPopup:
            packageJson.version === feat.version &&
            currentDate <= deadline &&
            !popUpClosed,
          isNew:
            packageJson.version === feat.version &&
            popUpClosed &&
            newTagDeadline &&
            currentDate <= newTagDeadline,
          elementId: feat.elementId || `${feat.type}-${feat.value}`,
        };
      }) || [];
    tabs = [...tabs, ...dashboardTabFeatures];
    setDashboardTabs(tabs);
  }, [language]);

  useEffect(() => {
    dispatch(DashboardSlice.actions.setTabDashboard(activeTab));
    const newDashboardTabs = dashboardTabs.map((tab) => ({
      ...tab,
      isActive: tab.value === activeTab,
    }));

    setDashboardTabs(newDashboardTabs);
  }, [activeTab]);

  useEffect(() => {
    getDashboardTabs();
  }, [language]);

  return {
    activeTab,
    dashboardTabs,
    loading,
    newQuoterFeature,
    setActiveTab,
    getDashboardTabs,
  };
};
