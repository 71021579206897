import { ThemeProvider } from "styled-components";
import theme from "../../../Contexts/ThemeProvider";
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { countrySelector } from "../../../Redux/Country/Country.slice";
import { ClickableCardButton } from "../../../Components/Card/Card.styled";
import { FlexContainer, FlexItem } from "../../../Components/Flex/Flex.styled";
import Text from "../../../Components/Text";
import { CountryWithCurrency } from "../../../Modules/Common/domain/Countries";
import { AppDispatch } from "../../../Redux/Store";
import { BeneficiarySlice } from "../../../Redux/Beneficiary";
import { useNavigate } from "react-router-dom";
import { DashboardSelector } from "../../../Redux/Dashboard/Dashboard.slice";
import Button from "../../../Components/Button";
import IconFont from "../../../Components/IconFont";

export const CountrySelection = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { countries } = useSelector(countrySelector);
  const { previousView } = useSelector(DashboardSelector);

  const loading = false;

  const selectCountry = (country: CountryWithCurrency) => {
    dispatch(BeneficiarySlice.actions.setCountrySelected(country));
    navigate("/beneficiaries/create");
  };

  const handleReturn = () => {
    navigate("/dashboard", {
      state: {
        activeTab: "dashboard",
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={loading} showHeaderContent={false} translatedContent>
        <Container maxWidth="442px" margin="24px auto">
          <Row>
            {previousView === "dashboard" && (
              <Column span={12} mb={3} px={3}>
                <Button
                  variant="transparent"
                  sizeButton="fit"
                  padding="0px"
                  onClick={handleReturn}
                >
                  <IconFont name="arrow-left" size="medium" color="black" />
                </Button>
              </Column>
            )}
            <Column span={12} mb={3} px={3}>
              <FlexContainer w="100%" m="0 0 24px 0" justify="center">
                <Text size={3} weight={500} margin={0}>
                  {t("Beneficiaries.SelectCountry")}
                </Text>
              </FlexContainer>
              <FlexContainer direction="column" alignItems="center" gap="8px">
                {countries.map((country) => (
                  <ClickableCardButton
                    key={country.id}
                    alignItems="center"
                    p="16px"
                    mw="322px"
                    minHeight="67px"
                    maxHeight="67px"
                    direction="row"
                    gap="6.2%"
                    as="button"
                    onClick={() => selectCountry(country)}
                  >
                    <img src={country.icon} width={35} />
                    <FlexItem flex="1 1 auto">
                      <Text size={0} weight={500}>
                        {country.countryName}
                      </Text>
                    </FlexItem>
                    <FlexItem>
                      <Text size={0} weight={500}>
                        {country.countryCode}
                      </Text>
                    </FlexItem>
                  </ClickableCardButton>
                ))}
              </FlexContainer>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
