import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Styles
import {
  Container,
  List,
  ListMobile,
  Content,
  Logocontent,
  LogoSendolaPay,
  CompanyLogoContainer,
  Linear,
  DisclosureContainer,
} from "./Footer.styled";

// Own Components
import Link from "../Link";
import PDFViewer from "../PDFViewer";

// Own Assets
import LogoPoweredBy from "../../Assets/logo_powered_by.svg";

import { ThemeContext } from "../../Contexts/ThemeContext/ThemeContext";

import { selectorLanguage } from "../../Redux/Translate";
import { FOOTER_LEGAL_FILES } from "../../Constants/LegalFiles";

import Text from "../Text";
import { useModal } from "../../Hooks/useModal";

const Footer = () => {
  const [t] = useTranslation("global");
  const {
    theme: { images, currentTheme },
  } = useContext(ThemeContext);
  const lang = useSelector(selectorLanguage);
  const { modal, showModal } = useModal();

  return (
    <>
      <Container>
        <Content>
          <List>
            <li>
              <PDFViewer
                url={
                  lang.language === "en"
                    ? FOOTER_LEGAL_FILES[0].urlEn
                    : FOOTER_LEGAL_FILES[0].urlEs
                }
                text={t("footer.Section1.0")}
                variant="white"
                size={1}
              />
            </li>
            <li>
              <PDFViewer
                url={
                  lang.language === "en"
                    ? FOOTER_LEGAL_FILES[1].urlEn
                    : FOOTER_LEGAL_FILES[1].urlEs
                }
                text={t("footer.Section1.1")}
                variant="white"
                size={1}
              />
            </li>
          </List>
          <List>
            <li>
              <PDFViewer
                url={
                  lang.language === "en"
                    ? FOOTER_LEGAL_FILES[2].urlEn
                    : FOOTER_LEGAL_FILES[2].urlEs
                }
                text={t("footer.Section2.0")}
                variant="white"
                size={1}
              />
            </li>
            <li>
              <Link href="/contact-us" variant="white" size={1}>
                {t("footer.Section2.2")}
              </Link>
            </li>
          </List>
          <ListMobile>
            <li>
              <PDFViewer
                url={
                  lang.language === "en"
                    ? FOOTER_LEGAL_FILES[0].urlEn
                    : FOOTER_LEGAL_FILES[0].urlEs
                }
                text={t("footer.Section1.0")}
                variant="white"
                size={1}
              />
            </li>
            <li>
              <PDFViewer
                url={
                  lang.language === "en"
                    ? FOOTER_LEGAL_FILES[2].urlEn
                    : FOOTER_LEGAL_FILES[2].urlEs
                }
                text={t("footer.Section2.0")}
                variant="white"
                size={1}
              />
            </li>
            <li>
              <PDFViewer
                url={
                  lang.language === "en"
                    ? FOOTER_LEGAL_FILES[1].urlEn
                    : FOOTER_LEGAL_FILES[1].urlEs
                }
                text={t("footer.Section1.1")}
                variant="white"
                size={1}
              />
            </li>
            <li>
              <Link href="/contact-us" variant="white" size={1}>
                {t("footer.Section2.2")}
              </Link>
            </li>
            <li>
              <Link
                href="#"
                variant="white"
                size={1}
                onClick={() => {
                  showModal({
                    modalType: "legalInfoModal",
                  });
                }}
              >
                {t("footer.ViewLegalNotes")}
              </Link>
              {modal}
            </li>
          </ListMobile>
        </Content>

        <Logocontent>
          {currentTheme !== "default" && (
            <LogoSendolaPay>
              <img src={LogoPoweredBy} alt="Sendola-2023" />
            </LogoSendolaPay>
          )}
          <img
            src={images["logo"]}
            alt={`${
              currentTheme === "default" ? "Sendola-2023" : "Martori-2023"
            }`}
          />
        </Logocontent>
      </Container>

      <DisclosureContainer>
        <Text size={1} color="white">
          <span dangerouslySetInnerHTML={{ __html: t("Disclosure") }}></span>
        </Text>
      </DisclosureContainer>
      <Linear />
    </>
  );
};

export default Footer;
