import ARGIcon from "../../../Assets/Flags/ARGIcon.png";
import BOBIcon from "../../../Assets/Flags/BOBIcon.png";
import BRAIcon from "../../../Assets/Flags/BRAIcon.png";
import CLIcon from "../../../Assets/Flags/CLIcon.png";
import COLIcon from "../../../Assets/Flags/COLIcon.png";
import ECUIcon from "../../../Assets/Flags/ECUIcon.png";
import GTMIcon from "../../../Assets/Flags/GTMIcon.png";
import MXIcon from "../../../Assets/Flags/MXIcon.png";
import SOLIcon from "../../../Assets/Flags/SOLIcon.png";
import UYUIcon from "../../../Assets/Flags/UYUIcon.png";
import SLVIcon from "../../../Assets/Flags/SLVIcon.png";
import CANIcon from "../../../Assets/Flags/CANIcon.png";
import NICIcon from "../../../Assets/Flags/NICIcon.png";
import HNDIcon from "../../../Assets/Flags/HNDIcon.png";
import PHPIcon from "../../../Assets/Flags/PHPIcon.png";
import INDIcon from "../../../Assets/Flags/INDIcon.png";
import USIconSrc from "../../../Assets/Flags/eu.png";

import USARGIcon from "../../../Assets/Flags/IconArg.png";
import USBOBIcon from "../../../Assets/Flags/IconBol.png";
import USBRAIcon from "../../../Assets/Flags/IconBra.png";
import USCLIcon from "../../../Assets/Flags/IconChi.png";
import USCOLIcon from "../../../Assets/Flags/IconCol.png";
import USECUIcon from "../../../Assets/Flags/IconEcu.png";
import USGTMIcon from "../../../Assets/Flags/IconGtm.png";
import USSLVIcon from "../../../Assets/Flags/IconSlv.png";
import USMXIcon from "../../../Assets/Flags/IconMex.png";
import USSOLIcon from "../../../Assets/Flags/IconPer.png";
import USUYUIcon from "../../../Assets/Flags/IconUry.png";
import USHNDIcon from "../../../Assets/Flags/IconHnd.png";
import USNICIcon from "../../../Assets/Flags/IconNic.png";
import USCANIcon from "../../../Assets/Flags/IconCan.png";
import USPHPIcon from "../../../Assets/Flags/IconPhp.png";
import USINDIcon from "../../../Assets/Flags/IconInd.png";

import { Currency } from "./Currency";

export type ExchangeRate = {
  id: string;
  icon: string;
  iconUs?: string;
  currency?: Currency;
  currencyQuoter?: Currency;
  label?: string;
  labelEs?: string;
  countryCode: string;
};

// TODO: Change this array or remove it when the list of supported currencies change for the product
export const allowedCurrencies = [
  "ARS",
  "BRL",
  "BOB",
  "CLP",
  "COP",
  "GTQ",
  "MXN",
  "PEN",
  "UYU",
  "CAN",
  "NIC",
  "USD",
  "SLV",
  "HNL",
  "ECU",
  "CAD",
  "INR",
  "PHP",
];

export const SenderExchangeRates: ExchangeRate[] = [
  {
    id: "0",
    icon: USIconSrc,
    label: "United States",
    labelEs: "Estados Unidos",
    currency: Currency.USD,
    countryCode: "USA",
  },
];

export const RecipientExchangeRates: ExchangeRate[] = [
  {
    id: "1",
    icon: ARGIcon,
    iconUs: USARGIcon,
    countryCode: "ARG",
  },
  {
    id: "2",
    icon: BOBIcon,
    iconUs: USBOBIcon,
    countryCode: "BOL",
  },
  {
    id: "3",
    icon: BRAIcon,
    iconUs: USBRAIcon,
    countryCode: "BRA",
  },
  {
    id: "4",
    icon: CLIcon,
    iconUs: USCLIcon,
    countryCode: "CHL",
  },
  {
    id: "5",
    icon: COLIcon,
    iconUs: USCOLIcon,
    countryCode: "COL",
  },
  {
    id: "6",
    icon: ECUIcon,
    iconUs: USECUIcon,
    countryCode: "ECU",
  },
  {
    id: "7",
    icon: GTMIcon,
    iconUs: USGTMIcon,
    countryCode: "GTM",
  },
  {
    id: "8",
    icon: MXIcon,
    iconUs: USMXIcon,
    countryCode: "MEX",
  },
  {
    id: "9",
    icon: SOLIcon,
    iconUs: USSOLIcon,
    countryCode: "PER",
  },
  {
    id: "10",
    icon: UYUIcon,
    iconUs: USUYUIcon,
    countryCode: "URY",
  },
  {
    id: "11",
    icon: CANIcon,
    iconUs: USCANIcon,
    countryCode: "CAN",
  },
  {
    id: "12",
    icon: NICIcon,
    iconUs: USNICIcon,
    countryCode: "NIC",
  },
  {
    id: "13",
    icon: SLVIcon,
    iconUs: USSLVIcon,
    countryCode: "SLV",
  },
  {
    id: "14",
    icon: HNDIcon,
    iconUs: USHNDIcon,
    countryCode: "HND",
  },
  {
    id: "15",
    icon: PHPIcon,
    iconUs: USPHPIcon,
    countryCode: "PHL",
  },
  {
    id: "16",
    icon: INDIcon,
    iconUs: USINDIcon,
    label: "India",
    countryCode: "IND",
  },
];

export const CountrieWhithoutCP = [
  "ARG",
  "BRA",
  "BOL",
  "CHL",
  "COL",
  "ECU",
  "PER",
  "URY",
  "CAN",
  "IND",
];
