import {
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  BeneficiaryModalProps,
  ErrorModalProps,
  ModalVariant,
  PartialModalVariantRecord,
  UnitellerDisclaimerModalProps,
  VelocityLimitModalProps,
  WalkthroughModalProps,
  ZelleInstructionsModalProps,
} from "../Models/ModalInterface";
import Modal from "../Components/Modal";
import { ErrorModal } from "../Components/Modal/ErrorModal/ErrorModal.component";
import { GeoLocationModal } from "../Components/Modal/GeoLocationModal/GeoLocationModal.component";
import { GeoLocationRetryModal } from "../Components/Modal/GeoLocationRetryModal/GeoLocationRetryModal.component";
import { VelocityLimitModal } from "../Components/Modal/VelocityLimitModal/VelocityLimitModal.component";
import { VelocityLimitSendolaPayModal } from "../Components/Modal/VelocityLimitModal/VelocityLimitSendolaPayModal.component";
import { SuccessModal } from "../Components/Modal/SuccessModal/SuccessModal.component";
import { UnitellerDisclaimerModal } from "../Components/Modal/DisclaimerModal/UnitellerDisclaimerModal";
import {
  UnlinkAccountModal,
  UnlinkAccountSuccessModal,
} from "../Components/Modal/UnlinkAccountModal";
import { BanUserModal } from "../Components/Modal/BanUserModal/BanUserModal";
import { WalkthroughModal } from "../Components/Modal/WalkthroughModal/WalkthroughModal.component";
import { BottomModal } from "../Components/Modal/BottomModal/BottomModal.component";
import { ConfirmationModal } from "../Components/Modal/ConfirmationModal/ConfirmationModal.component";
import { SelectBeneficiaryAccountModal } from "../Components/Modal/BeneficiaryModal/SelectBeneficiaryAccountModal.component";
import { AddBeneficiaryAccountModal } from "../Components/Modal/BeneficiaryModal/AddBeneficiaryAccountModal.component";
import { IdDocumentModal } from "../Components/Modal/IdDocumentModal/IdDocumentModal.component";
import { ZelleInstructionsModal } from "../Components/Modal/ZelleInstructionsModal/ZelleInstructionsModal.component";
import { LegalInfoModal } from "../Components/Modal/LegalInfoModal/LegalInfoModal.component";

export const useModal = () => {
  const [openModal, setOpenModal] = useState(true);
  const [modal, setModal] = useState<ReactPortal | ReactElement | null>(null);

  const showModal = ({
    id,
    modalType = "default",
    content,
    title,
    message,
    errorMessage,
    codesMessages,
    pageName,
    usePortal = true,
    show = true,
    handleConfirm,
    handleClose,
    ...restProps
  }: {
    id?: string;
    modalType?: ModalVariant;
    content?: ReactNode;
    message?: string;
    errorMessage?: string;
    userPhone?: string;
    usePortal?: boolean;
    handleClose?: (confirm?: boolean) => void;
  } & Partial<ErrorModalProps> &
    Partial<VelocityLimitModalProps> &
    Partial<UnitellerDisclaimerModalProps> &
    Partial<WalkthroughModalProps> &
    Partial<BeneficiaryModalProps> &
    Partial<ZelleInstructionsModalProps>) => {
    setOpenModal(show);
    // helper variable to always show modal on showModal function call
    let internalOpen = show;

    const variantComponentMap: PartialModalVariantRecord = {
      default: Modal,
      success: SuccessModal,
      error: ErrorModal,
      geolocation: GeoLocationModal,
      geolocationRetry: GeoLocationRetryModal,
      velocityLimit: VelocityLimitModal,
      velocityLimitSendolaPay: VelocityLimitSendolaPayModal,
      unitellerDisclaimer: UnitellerDisclaimerModal,
      unlinkAccountModalAlert: UnlinkAccountModal,
      unlinkAccountSuccessModal: UnlinkAccountSuccessModal,
      banUser: BanUserModal,
      walkthrough: WalkthroughModal,
      bottomModal: BottomModal,
      confirmationModal: ConfirmationModal,
      selectBeneficiaryAccountModal: SelectBeneficiaryAccountModal,
      addBeneficiaryAccountModal: AddBeneficiaryAccountModal,
      idDocumentModal: IdDocumentModal,
      zelleInstructionsModal: ZelleInstructionsModal,
      legalInfoModal: LegalInfoModal,
    };

    const VariantComponent = modalType ? variantComponentMap[modalType] : Modal;

    if (VariantComponent) {
      const element = (
        <VariantComponent
          id={id}
          show={internalOpen || openModal}
          title={title}
          message={message}
          {...(modalType === "error" ? { errorMessage } : {})}
          handleClose={(confirm) => {
            setOpenModal(false);
            internalOpen = false;
            handleClose && handleClose(confirm);
          }}
          handleConfirm={handleConfirm}
          codesMessages={codesMessages}
          pageName={pageName}
          {...restProps}
        >
          {content}
        </VariantComponent>
      );
      setModal(usePortal ? createPortal(element, document.body) : element);
    }
  };

  useEffect(() => {
    if (!openModal) {
      setModal(null);
    }
  }, [openModal]);

  return { modal, showModal, setOpenModal };
};
