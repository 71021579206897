/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useBeneficiaryAdditionalFields } from "../../Hooks/useBeneficiaryAdditionalFields";
import { useSubdivision } from "../../Hooks/useSubdivision";
import {
  BeneficiarySelector,
  BeneficiarySlice,
} from "../../Redux/Beneficiary/Beneficiary.slice";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import { ThemeProvider } from "styled-components";
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import { DynamicInput } from "../../Components/DynamicInput";
import { ThemeSelector } from "../../Redux/Theme";
import { useLocation } from "react-router";
import { AdditionalField } from "../../Modules/Common/domain/AdditionalFields";
import { StyledAdditionalFieldsForm } from "./UpdateAdditionalFields.styled";
import Button from "../../Components/Button";
import { AlignItems, JustifyContent } from "../../Types/Column";
import { useBeneficiary } from "../../Hooks/useBeneficiary";
import {
  Account,
  BeneficiaryEdit,
  BeneficiaryRecipient,
} from "../../Modules/Beneficiary/domain/Beneficiary";
import { useModal } from "../../Hooks/useModal";
import { AppDispatch } from "../../Redux/Store";
import { GeoLocationContext } from "../../Contexts/GeoLocation";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { isEmpty } from "lodash";
import { selectorLanguage } from "../../Redux/Translate";
import { Errors } from "../../Modules/Common/domain/Errors";
import { useNavigate } from "react-router-dom";

export const UpdateAdditionalFields = () => {
  const pathLocation = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const lang = useSelector(selectorLanguage);
  const { missingFields } = (pathLocation.state as {
    missingFields?: AdditionalField[];
  }) || { missingFields: undefined };

  const location = useContext(GeoLocationContext);
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const theme = useSelector(ThemeSelector);
  const {
    currentPaymentDestination,
    currentDeliveryMethodQuote,
    paymentMethodSelected,
    currentPaymentMethod,
  } = useSelector(QuotationSelector).data;
  const currentQuoteDestinations =
    currentDeliveryMethodQuote?.paymentDestinations;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const transferType = currentDeliveryMethodQuote!.deliveryMethodCode;

  const form = useForm({ mode: "all" });
  const {
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = form;

  const {
    subdivisionList,
    isLoading: subdivisionsLoading,
    getSubdivisionOptions,
  } = useSubdivision();
  const {
    isLoading: beneficiaryIsLoading,
    beneficiaryInformationId,
    updateBeneficiaryId,
    getBeneficiaryInformation,
  } = useBeneficiary();
  const {
    velocityModal,
    isLoading: verifyIsLoading,
    verifyErrorModal,
    verifyCurrentSendMoneySelection,
  } = usePaymentOrder();
  const {
    modal: updateAdditionalFieldsModal,
    showModal: showAdditionalFieldsModal,
  } = useModal();

  const destinationSelected =
    currentPaymentDestination ||
    currentDeliveryMethodQuote?.paymentDestinations.find(
      (dest) =>
        dest.destination.toLowerCase() ===
        beneficiarySelected?.accountSelected?.bankName?.toLowerCase()
    );

  const {
    additionalFields: beneficiaryAdditionalFields,
    isLoading: additionalFieldsLoading,
  } = useBeneficiaryAdditionalFields({
    country:
      beneficiarySelected!.accountSelected?.country ||
      beneficiarySelected!.country!,
    countrySubdivisions: subdivisionList,
    transferType: transferType,
    destinationSelected,
    form,
  });

  const {
    additionalFieldsAppriza,
    isLoading: loadingAdditionalAppriza,
    fetchAdditionalFieldsAppriza,
  } = useBeneficiaryAdditionalFields({
    country: "",
  });

  const isLoading =
    additionalFieldsLoading ||
    subdivisionsLoading ||
    beneficiaryIsLoading ||
    verifyIsLoading ||
    loadingAdditionalAppriza;

  const selectBeneficiary = async (
    beneficiary?: BeneficiaryRecipient,
    account?: Account,
    additionalFields?: Account[]
  ) => {
    const currentAdditionalFields = additionalFields?.find(
      (item) => item.id === account?.id
    );
    const selected = beneficiary
      ? {
          ...beneficiary,
          accountSelected: {
            ...account,
            additionalFieldInfo: currentAdditionalFields?.additionalFieldInfo,
            rail: currentQuoteDestinations?.find(
              (destination) => destination.destination === account?.bankName
            )?.rail,
          },
        }
      : undefined;
    dispatch(BeneficiarySlice.actions.setBeneficiarySelected(selected));
  };

  const processSubmit = handleSubmit(async (data) => {
    const formData: Partial<BeneficiaryEdit> = {
      firstName: beneficiaryInformationId?.firstName,
      lastName: beneficiaryInformationId?.lastName,
    };

    if (transferType === "D2B") {
      const accountToUpdate = JSON.parse(
        JSON.stringify(beneficiarySelected?.accountSelected)
      );
      if (accountToUpdate) {
        accountToUpdate.branch = data.branch || accountToUpdate.branch;
        accountToUpdate.additionalFieldInfo = [
          ...accountToUpdate.additionalFieldInfo,
          ...beneficiaryAdditionalFields
            .filter(
              (field) =>
                missingFields?.find(
                  (missingField) => missingField.fieldName === field.fieldName
                ) && field.fieldGroup !== "branch"
            )
            .map((field) => ({
              fieldBelongsTo: field.fieldBelongsTo,
              fieldName: field.fieldName,
              fieldValue: data[field.fieldName],
            })),
        ];
        formData.accounts = [accountToUpdate];
      }
    } else if (transferType === "CPU") {
      formData.additionalField = {
        branch: data.branch || undefined,
        additionalFieldInfo: beneficiaryAdditionalFields
          .filter(
            (field) =>
              missingFields?.find(
                (missingField) => missingField.fieldName === field.fieldName
              ) && field.fieldGroup !== "branch"
          )
          .map((field) => ({
            fieldBelongsTo: field.fieldBelongsTo,
            fieldName: field.fieldName,
            fieldValue: data[field.fieldName],
          })),
      };
    }

    const additionalAccount = formData.accounts?.map((item) => {
      const r = item.additionalFieldInfo?.filter(
        (field) => field.fieldBelongsTo !== "Transaction"
      );

      return {
        ...item,
        additionalFieldInfo: r,
      };
    });

    formData.additionalField = {
      ...formData.additionalField,
      branch: data.branch || null,
      additionalFieldInfo: [
        ...(formData.additionalField?.additionalFieldInfo || []),
        ...additionalFieldsAppriza
          .filter(
            (field) =>
              missingFields?.find(
                (missingField) => missingField.fieldName === field.fieldName
              ) && field.fieldGroup !== "branch"
          )
          .map((field) => ({
            fieldBelongsTo: field.fieldBelongsTo,
            fieldName: field.fieldName,
            fieldValue: data[field.fieldName],
          })),
      ],
    };

    const accountBeneficiary = {
      ...formData,
      accounts: additionalAccount,
    };

    const responseUpdate = await updateBeneficiaryId(
      accountBeneficiary,
      beneficiarySelected?.beneficiaryId
    );

    if (!(responseUpdate as Errors).errors) {
      const beneficiary = responseUpdate as BeneficiaryEdit;

      showAdditionalFieldsModal({
        modalType: "success",
        message: tSendMoney("updateAdditionalFields.success"),
        handleClose: () => {
          selectBeneficiary(
            beneficiary,
            beneficiarySelected?.accountSelected
              ? beneficiary.accounts?.find(
                  (acc) => acc.id === beneficiarySelected.accountSelected?.id
                )
              : undefined,
            formData.accounts
          );
        },
      });
    } else {
      showAdditionalFieldsModal({
        modalType: "error",
        errorMessage: tSendMoney("updateAdditionalFields.error"),
      });
    }
  });

  useEffect(() => {
    if (lang) {
      if (beneficiarySelected?.country) {
        getSubdivisionOptions(beneficiarySelected?.country, lang.language);
        fetchAdditionalFieldsAppriza(
          transferType === "D2B"
            ? beneficiarySelected.accountSelected!.country
            : beneficiarySelected.country
        );
      }
    }
  }, [beneficiarySelected, lang]);

  useEffect(() => {
    if (beneficiarySelected) {
      const onSuccess = () => {
        if (paymentMethodSelected && currentPaymentMethod) {
          navigate("/review-transaction");
        } else {
          navigate("/payments");
        }
      };
      verifyCurrentSendMoneySelection({ onSuccess });
    }
  }, [beneficiarySelected]);

  useEffect(() => {
    if (!isEmpty(subdivisionList)) {
      setValue("destination", destinationSelected?.id);
    }
  }, [subdivisionList]);

  useEffect(() => {
    if (beneficiarySelected?.beneficiaryId) {
      getBeneficiaryInformation(beneficiarySelected.beneficiaryId);
    }
  }, [beneficiarySelected]);

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} loading={isLoading}>
        <FormProvider {...form}>
          <StyledAdditionalFieldsForm onSubmit={processSubmit}>
            <Container>
              <Row>
                <Column span={12} mb={5}>
                  <Title
                    tagName="h1"
                    text={tSendMoney("updateAdditionalFields.title")}
                    color="black"
                    size={6}
                    align="left"
                  />
                </Column>
              </Row>

              {beneficiaryAdditionalFields &&
                beneficiaryAdditionalFields
                  .filter((field) =>
                    missingFields?.find(
                      (missingField) =>
                        missingField.fieldName === field.fieldName
                    )
                  )
                  .map((field) => (
                    <Row key={field.fieldName}>
                      <Column span={12} px={2}>
                        <DynamicInput {...field} />
                      </Column>
                    </Row>
                  ))}

              {additionalFieldsAppriza &&
                additionalFieldsAppriza
                  .filter((field) =>
                    missingFields?.find(
                      (missingField) =>
                        missingField.fieldName === field.fieldName
                    )
                  )
                  .map((field) => (
                    <Row key={field.fieldName}>
                      <Column span={12} px={2}>
                        <DynamicInput {...field} />
                      </Column>
                    </Row>
                  ))}
              <Row>
                <Column
                  span={12}
                  alignItems={AlignItems.center}
                  justifyContent={JustifyContent.center}
                >
                  <Button
                    text={t("Beneficiaries.CreateBeneficiary.Form.Submit")}
                    variant={isValid ? "primary" : "default"}
                    sizeText="large"
                    sizeButton="medium"
                    disabled={!isValid}
                  />
                </Column>
              </Row>
            </Container>
          </StyledAdditionalFieldsForm>
        </FormProvider>
        {updateAdditionalFieldsModal}
        {velocityModal}
        {verifyErrorModal}
      </Layout>
    </ThemeProvider>
  );
};
