import styled from "styled-components";
import { theme } from "styled-tools";
import { textColors } from "../../../../Contexts/ThemeProvider/Pages";
import { textComponentSolidlight } from "../../../../Contexts/ThemeProvider/Texts";

export const TransactionDetailTitle = styled.h3`
  color: ${textComponentSolidlight};
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

export const TransactionDetailDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const TransactionDetailDateLabel = styled.div`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  flex: auto;
`;

export const TransactionDetailDateValue = styled.div`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  width: 40%;
`;

export const TransactionDetailsRecipt = styled.div`
  &,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    color: ${textComponentSolidlight};
    text-align: right;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }

  span {
    margin-left: 10px;
    text-decoration-line: none;
  }
`;

export const RecipientOption = styled.a`
  padding: 0;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
`;
export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const LinkText = styled.div`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  text-decoration-line: underline;
  color: ${textColors};
`;
