import styled from "styled-components";
import { theme } from "styled-tools";

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 210px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TransactionErrorContainer = styled(ActionButtons)`
  min-height: 370px;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const TransactionErrorTitle = styled.h1`
  color: ${theme("Content_UX_01")};
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TransactionErrorMessage = styled.p`
  color: ${theme("Content_UX_02")};
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  b {
    color: ${theme("Content_UX_01")};
  }
`;

export const TransactionErrorButtons = styled.button`
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 30px;
`;
