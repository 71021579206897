import { useTranslation } from "react-i18next";
import { PaymentMethodType } from "../../../Modules/Common/domain/PaymentMethods";
import { FlexContainer } from "../../Flex/Flex.styled";
import {
  BankSupportedLogoContainer,
  PaymentMethodCard,
  PaymentMethodTypeTag,
} from "./PaymentRadio.styled";
import RadioCustom from "../../RadioCustom";
import { GradientCard } from "../../Card/Card.styled";
import VisaLogo from "../../../Assets/Img/visa.png";
import MasterCardLogo from "../../../Assets/Img/mastercard.png";
import AppePayLogo from "../../../Assets/Img/apple-pay.png";
import GPayLogo from "../../../Assets/Img/google-pay.png";
import AmexLogo from "../../../Assets/Img/amex-minified.png";
import Text from "../../Text";
import { useRef } from "react";

interface PaymentRadioProps {
  type: PaymentMethodType;
  logo: string;
  value: string;
  title?: string;
  description?: string;
  showBankSupported?: boolean;
  showPlaidBankSupported?: boolean;
  checked?: boolean;
  forwardRef?: React.RefCallback<HTMLInputElement>;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PaymentRadio = ({
  type,
  logo,
  value,
  title,
  description,
  showBankSupported,
  checked,
  forwardRef,
  onChange,
}: PaymentRadioProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  const radioRef = useRef<HTMLInputElement | null>(null);

  return (
    <FlexContainer
      alignItems="center"
      gap="8px"
      onClick={() => {
        if (radioRef?.current) {
          radioRef.current.click();
        }
      }}
    >
      <FlexContainer w="20px">
        <RadioCustom
          forwardRef={(el) => {
            if (radioRef) {
              radioRef.current = el;
            }
            if (forwardRef) {
              forwardRef(el);
            }
          }}
          name="paymentMethod"
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </FlexContainer>

      <FlexContainer direction="column" gap="4px">
        <PaymentMethodTypeTag type={type}>
          {tSendMoney(`paymentMethods.types.${type}`)}
        </PaymentMethodTypeTag>
        <GradientCard
          borderWidth="1px"
          width="100%"
          padding="13px 15px"
          borderColor="muted"
        >
          <PaymentMethodCard>
            <img src={logo} />
            {showBankSupported ? (
              <FlexContainer direction="column" gap="4px">
                <FlexContainer
                  flex="1 1 auto"
                  justify="start"
                  alignItems="start"
                  w="100%"
                  flexWrap
                >
                  <BankSupportedLogoContainer>
                    <img src={VisaLogo} height="100%" />
                  </BankSupportedLogoContainer>
                  <BankSupportedLogoContainer>
                    <img src={MasterCardLogo} height="100%" />
                  </BankSupportedLogoContainer>
                  <BankSupportedLogoContainer>
                    <img src={AppePayLogo} height="100%" />
                  </BankSupportedLogoContainer>
                  <BankSupportedLogoContainer>
                    <img src={AmexLogo} height="100%" />
                  </BankSupportedLogoContainer>
                  <BankSupportedLogoContainer>
                    <img src={GPayLogo} height="100%" />
                  </BankSupportedLogoContainer>
                </FlexContainer>
                <Text align="left" color="grey" size="default" weight={400}>
                  {description}
                </Text>
              </FlexContainer>
            ) : (
              <FlexContainer direction="column" gap="4px">
                {title && (
                  <Text align="left" color="black" size="default">
                    <span dangerouslySetInnerHTML={{ __html: title }}></span>
                  </Text>
                )}
                <Text align="left" color="grey" size="default" weight={400}>
                  {description}
                </Text>
              </FlexContainer>
            )}
          </PaymentMethodCard>
        </GradientCard>
      </FlexContainer>
    </FlexContainer>
  );
};
