import { createSlice } from "@reduxjs/toolkit";
import { findCurrencies } from "./Currencies.actions";
import { State } from "../Store";
import { CurrencyState } from "../../Models/Currencies";

const initialState: CurrencyState = {
  loading: false,
  error: null,
  data: {},
};

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findCurrencies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(findCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(findCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.data = {};
        state.error = action.payload;
      });
  },
});

export const currenciesSelector = (state: State) => state.currencies;
