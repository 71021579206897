import { Controller, useFormContext } from "react-hook-form";
import { SelectOption } from "../../Models/Select";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
import { useTranslation } from "react-i18next";
import InputDateMask from "../InputDateMask";
import { allowedDate } from "../../Constants/Regexp";
import Input from "../Input";
import { useCallback, useEffect, useRef, useState } from "react";
import { SelectInstance } from "react-select";
import isEmpty from "lodash.isempty";
import { AdditionalField } from "../../Modules/Common/domain/AdditionalFields";

interface DynamicInputProps extends AdditionalField {
  defaultValue?: string;
  index?: number;
}

export const DynamicInput = ({
  fieldLabel,
  fieldName,
  fieldType,
  fieldOptions,
  defaultValue,
  async = false,
  onChange: onInputChange,
  validation,
  loadOptions,
}: DynamicInputProps) => {
  const [t] = useTranslation("global");
  const {
    formState: { errors },
    control,
    setValue,
    register,
    trigger,
    watch,
  } = useFormContext();
  const dropdownRef = useRef<SelectInstance<SelectOption> | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const subscribe = watch((value, { name, type }) => {
      if (
        fieldType === "dropdown" &&
        name === fieldName &&
        !value[fieldName] &&
        !isEmpty(dropdownRef.current?.getValue()) &&
        type !== "change"
      ) {
        setTimeout(() => {
          dropdownRef.current?.clearValue();
        });
      }
    });

    return () => subscribe.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (defaultValue && !initialized) {
      setValue(fieldName, defaultValue, { shouldValidate: true });
      setInitialized(true);
    }
  }, [defaultValue]);

  return (
    <>
      {fieldType === "dropdown" ? (
        <Controller
          control={control}
          name={fieldName}
          rules={{
            required: t("Forms.required"),
          }}
          render={({ field: { onBlur, onChange, value } }) => (
            <SearchSelect
              labelProps={{ color: "grey", size: 0 }}
              selectRef={dropdownRef}
              errors={errors}
              placeholderInput={fieldLabel}
              options={fieldOptions || []}
              name={fieldName}
              placeholder=""
              async={async}
              loadOptions={loadOptions}
              onBlur={onBlur}
              onChange={(sel) => {
                onChange(sel?.value || "");
                onInputChange && onInputChange();
              }}
              value={fieldOptions?.find((opt) => opt.value === value)}
              m="0 0 25px 0"
            />
          )}
        />
      ) : fieldType === "date" ? (
        <InputDateMask
          textLabel={fieldLabel}
          label={fieldName}
          placeholderInput="MM/DD/YYYY"
          size="xxxlarge"
          rules={{
            required: t("Forms.required"),
            pattern: {
              value: allowedDate,
              message: t("Forms.person.invalidDate"),
            },
            onChange: () => {
              trigger(fieldName);
            },
          }}
          register={register}
          errors={errors}
          mb="20px"
          full
        />
      ) : (
        <Input
          register={register}
          errors={errors}
          placeholderInput={fieldLabel}
          label={fieldName}
          rules={{
            required: t("Forms.required"),
            validate: (value, formValues) => {
              if (!validation) return true;
              return validation(value, formValues);
            },
            onChange: () => {
              onInputChange && onInputChange();
            },
          }}
          typeInput={"text"}
          mb="20px"
          full
        />
      )}
    </>
  );
};
