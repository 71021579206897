/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppDispatch, State } from "../Redux/Store";
import { QuotationSlice } from "../Redux/Quotation";

import { Currency } from "../Modules/Common/domain/Currency";
import {
  getQuoteWithFees,
  getQuoteWithFeesAndTiers,
} from "../Modules/Quote/application/getQuote";

import { createApiQuoteRepository } from "../Modules/Quote/infrastructure/ApiQuoteRepository";
import {
  QuoteDeliveryMethod,
  QuoteDeliveryMethodCode,
  QuotePaymentMethodDestination,
  getQuoteDeliveryMethod,
} from "../Modules/Quote/domain/QuoteDeliveryMethod";
import {
  QuoteOption,
  QuoteSelectedValue,
  getQuoteMethodDestination,
} from "../Modules/Quote/domain/QuoteSelected";
import {
  getCountryDestination,
  getQuoteCountryDestinationCode,
} from "../Modules/Quote/domain/QuoteCountryDestination";
import { useStatus } from "./useStatus";
import { useModal } from "./useModal";
import { Errors } from "../Modules/Common/domain/Errors";
import { PersonsKYCSelector } from "../Redux/PersonsKYC";
import { useDestination } from "./useDestination";
import { SelectOption } from "../Models/Select";
import { Destination } from "../Modules/Common/domain/Destination";
import { countrySelector } from "../Redux/Country/Country.slice";
import { IconsFontType } from "../Models/IconInterface";
import { getPaymentMethods } from "../Modules/Common/application/getPaymentMethods";
import { createApiPaymentMethodRepository } from "../Modules/Common/infraestructure/ApiPaymentMethodRepository";
import { getAccountDetails } from "../Modules/PaymentOrders/application/plaidPaymentOrder";
import {
  PaymentMethod,
  PlaidAccount,
} from "../Modules/Common/domain/PaymentMethods";
import { selectorLanguage } from "../Redux/Translate";
import { PlaidPaymentOrderRepository } from "../Modules/PaymentOrders/infrastructure/ApiPaymentWithPlaidRepository";

type DestinationByCountry = {
  country: string;
  method: QuoteDeliveryMethodCode;
  destinations: (SelectOption & Destination)[];
  sponsorBank?: string;
};

export const useQuote = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    countryDestination,
    currentPaymentDestination,
    paymentMethods,
    paymentMethodSelected,
    currentPaymentMethod,
  } = useSelector((state: State) => state.quotation.data);
  const {
    isLoading: quoteIsLoading,
    hasError,
    error,
    setError,
    startRequest,
    endRequest,
  } = useStatus();
  const [sendToSelectOptions, setSendToSelectOptions] = useState<
    (QuoteOption & Destination)[]
  >([]);
  const { countries } = useSelector(countrySelector);
  const { language } = useSelector(selectorLanguage);
  const quoterValue = useRef<Partial<QuoteSelectedValue>>({
    currentQuote: currentDeliveryMethodQuote,
    amount: currentQuotation?.amount || 500,
    currency: countries.find((c) => c.countryCode === "MEX")
      ? Currency.MXN
      : countries[0]?.defaultCurrency || null,
    country: countries.find((c) => c.countryCode === "MEX")
      ? "MEX"
      : countries[0]?.countryCode || null,
    deliveryMethod: currentDeliveryMethodQuote?.deliveryMethodCode || "D2B",
    sendTo: currentPaymentDestination?.id || null,
  });
  const destinationsByCountryRef = useRef<DestinationByCountry[]>([]);
  const { modal: quoteModal, showModal: showQuoteModal } = useModal();
  const [tModals] = useTranslation("modals");
  const personVeriff = useSelector(PersonsKYCSelector).data?.personVeriff;
  const personVerifyKYC = useSelector(PersonsKYCSelector).data?.personVerifyKYC;
  const { isLoading: destinationIsLoading, getDestinationsByCountry } =
    useDestination();
  const isLoading = quoteIsLoading || destinationIsLoading;

  const deliveryMethodsSelectOptions = [
    {
      value: "D2B",
      label: t("Quote.DirectToBank"),
      iconName: "safe" as IconsFontType,
    },
    {
      value: "CPU",
      label: t("Quote.CashPickUp"),
      iconName: "hand-coin" as IconsFontType,
    },
  ];

  const resetQuote = () => {
    dispatch(QuotationSlice.actions.resetState());
    quoterValue.current = {
      amount: 500,
      currency: countries.find((c) => c.countryCode === "MEX")
        ? Currency.MXN
        : countries[0]?.defaultCurrency,
      country: countries.find((c) => c.countryCode === "MEX")
        ? "MEX"
        : countries[0]?.countryCode,
      deliveryMethod: "D2B",
      sendTo: null,
    };
  };

  const handleQuotationChange = async (quoteValues: QuoteSelectedValue) => {
    if (
      quoteValues.amount &&
      quoteValues.currency &&
      quoteValues.deliveryMethod
    ) {
      const countryDestinationCode =
        quoteValues.country ||
        getQuoteCountryDestinationCode(quoteValues.currency, countries);
      if (countryDestinationCode !== countryDestination) {
        dispatch(
          QuotationSlice.actions.setCountryDestination(countryDestinationCode)
        );
      }

      const deliveryMethodDestinations = await getDeliveryMethodDestinations({
        deliveryMethod: quoteValues.deliveryMethod,
        countryDestination: countryDestinationCode,
        sponsorBank: (paymentMethodSelected as PaymentMethod)?.sponsorBank,
      });
      setSendToSelectOptions(deliveryMethodDestinations);

      if (
        quoteValues.amount !== quoterValue.current?.amount ||
        quoteValues.currency !== quoterValue.current?.currency ||
        quoteValues.country !== quoterValue.current?.country ||
        quoteValues.deliveryMethod !== quoterValue.current?.deliveryMethod ||
        quoteValues.sendTo !== quoterValue.current?.sendTo ||
        paymentMethodSelected
      ) {
        setQuoteValues(quoteValues, deliveryMethodDestinations);
      }
    }
  };

  const setQuoteValues = async (
    quoteValues: QuoteSelectedValue,
    destinations?: DestinationByCountry["destinations"]
  ) => {
    quoterValue.current = {
      ...quoterValue.current,
      ...quoteValues,
    };
    if (
      quoteValues.currency &&
      quoteValues.amount &&
      quoteValues.deliveryMethod &&
      quoteValues.sendTo
    ) {
      await getCurrentQuote(
        quoteValues.currency,
        quoteValues.amount,
        quoteValues.sendTo,
        quoteValues.deliveryMethod,
        quoteValues.withTiers,
        quoteValues.country,
        destinations
      );
    } else {
      setPaymentDestination(quoteValues.sendTo);
    }
  };

  const getCurrentQuote = async (
    currency: string,
    amount: number,
    sendTo: string,
    deliveryMethod: QuoteDeliveryMethodCode,
    withTiers?: boolean,
    country?: string | null,
    destinations?: DestinationByCountry["destinations"]
  ) => {
    startRequest();

    try {
      const countryDestinationObj = country
        ? getCountryDestination(country, countries)
        : null;
      const countryDestination =
        country || getQuoteCountryDestinationCode(currency, countries);
      if (!countryDestination) {
        endRequest();
        return;
      }
      const deliveryMethodDestinations =
        destinations ||
        (await getDeliveryMethodDestinations({
          deliveryMethod,
          countryDestination,
          sponsorBank: (paymentMethodSelected as PaymentMethod)?.sponsorBank,
        }));
      const selectedDestination = deliveryMethodDestinations.find(
        (dest) => dest.id === sendTo
      );

      if (selectedDestination?.rail !== currentPaymentDestination?.rail) {
        dispatch(
          QuotationSlice.actions.setCurrentPaymentDestination(
            getQuoteMethodDestination(deliveryMethodDestinations!, sendTo)
          )
        );
      }

      const amountIsUnderMaxAllowed =
        (selectedDestination && !selectedDestination.maximumSendAmount) ||
        (selectedDestination?.maximumSendAmount &&
          amount <= selectedDestination?.maximumSendAmount);
      const amountIsOverMinAllowed =
        (selectedDestination && !selectedDestination.minimumSendAmount) ||
        (selectedDestination?.minimumSendAmount &&
          amount >= selectedDestination?.minimumSendAmount);
      const amountIsValid = amountIsUnderMaxAllowed && amountIsOverMinAllowed;

      if (amountIsValid) {
        const useCase =
          withTiers && (personVeriff || personVerifyKYC)
            ? getQuoteWithFeesAndTiers
            : getQuoteWithFees;

        const response = await useCase(createApiQuoteRepository())(
          countryDestination,
          countryDestinationObj?.currency || currency,
          amount,
          selectedDestination?.payerQuote
        );

        const quoteDeliveryMethod = getQuoteDeliveryMethod(
          response.deliveryMethods,
          deliveryMethod
        );

        dispatch(
          QuotationSlice.actions.setCurrentDeliveryMethodQuote(
            quoteDeliveryMethod
          )
        );

        if (deliveryMethod === "D2B") {
          quoterValue.current = {
            ...quoterValue.current,
            currentQuote: quoteDeliveryMethod,
          };
        }

        setPaymentDestination(sendTo, quoteDeliveryMethod, countryDestination);

        if (response?.destinationCurrency) {
          response.destinationCurrency = currency;
        }

        dispatch(
          QuotationSlice.actions.setCurrentQuotation({
            currentQuotation: response,
            countryDestination,
          })
        );
        endRequest();
        return response;
      } else {
        dispatch(
          QuotationSlice.actions.setCurrentPaymentDestination(
            getQuoteMethodDestination(deliveryMethodDestinations!, sendTo)
          )
        );
      }
      endRequest();
    } catch (error) {
      endRequest({ hasError: true });
      setError(error as Errors);
    }
  };

  const getDeliveryMethodDestinations = async ({
    countryDestination,
    deliveryMethod,
    sponsorBank = "NoSponsor",
  }: {
    countryDestination?: string;
    deliveryMethod: QuoteDeliveryMethodCode;
    sponsorBank?: string;
  }) => {
    let deliveryMethodDestinations:
      | (SelectOption & Destination)[]
      | (SelectOption & QuotePaymentMethodDestination)[] = [];
    if (countryDestination) {
      const destinationFound = destinationsByCountryRef.current.find(
        (dest) =>
          dest.country === countryDestination &&
          dest.method === deliveryMethod &&
          dest.sponsorBank === sponsorBank
      );
      if (!destinationFound) {
        deliveryMethodDestinations =
          (await getDestinationsByCountry(
            deliveryMethod,
            countryDestination,
            sponsorBank
          )) || [];
        destinationsByCountryRef.current = [
          ...destinationsByCountryRef.current,
          {
            country: countryDestination,
            destinations: deliveryMethodDestinations,
            method: deliveryMethod,
            sponsorBank: sponsorBank || "NoSponsor",
          },
        ];
      } else {
        deliveryMethodDestinations = destinationFound.destinations;
      }
    }

    return deliveryMethodDestinations;
  };

  async function setPaymentDestination(
    sendTo?: string | null | undefined,
    deliveryMethodQuote?: QuoteDeliveryMethod,
    countryDestination?: string
  ) {
    if (sendTo && deliveryMethodQuote && countryDestination) {
      const deliveryMethodDestinations = await getDeliveryMethodDestinations({
        deliveryMethod: deliveryMethodQuote.deliveryMethodCode,
        countryDestination,
        sponsorBank: (paymentMethodSelected as PaymentMethod)?.sponsorBank,
      });
      dispatch(
        QuotationSlice.actions.setCurrentPaymentDestination(
          getQuoteMethodDestination(deliveryMethodDestinations!, sendTo)
        )
      );

      quoterValue.current = {
        ...quoterValue.current,
        sendTo,
        currentQuote: deliveryMethodQuote,
      };
    } else {
      quoterValue.current = {
        ...quoterValue.current,
        currentQuote: undefined,
      };
      dispatch(QuotationSlice.actions.setCurrentPaymentDestination(null));
    }
  }

  async function fetchPaymentMethods() {
    startRequest();
    try {
      const response = await getPaymentMethods(
        createApiPaymentMethodRepository()
      )();

      endRequest();
      dispatch(QuotationSlice.actions.setPaymentMethods(response));
      return response;
    } catch (error) {
      endRequest();
      dispatch(QuotationSlice.actions.setPaymentMethods([]));
    }
  }

  async function fetchPlaidAccountDetails(accountId: string, language: string) {
    startRequest();
    try {
      const response = await getAccountDetails(PlaidPaymentOrderRepository())(
        accountId,
        language
      );

      if (paymentMethodSelected?.paymentSource === "Sendola Plaid") {
        dispatch(
          QuotationSlice.actions.setPaymentMethodSelected({
            ...paymentMethodSelected,
            accountNumber: response.account,
          })
        );
      }

      endRequest();
      return response;
    } catch (error) {
      endRequest({ hasError: true });
      setError(error as Errors);
    }
  }

  useEffect(() => {
    if (
      hasError &&
      error?.errors?.some((err) => (err as string)?.includes("Tier"))
    ) {
      showQuoteModal({
        modalType: "error",
        errorMessage: tModals("quote.errorMessageTier"),
      });
    } else if (hasError) {
      showQuoteModal({
        modalType: "error",
        errorMessage: tModals("quote.errorMessage"),
      });
    }
  }, [hasError]);

  useEffect(() => {
    if (
      paymentMethodSelected?.paymentSource === "Sendola Plaid" &&
      !(paymentMethodSelected as PlaidAccount).accountNumber &&
      (paymentMethodSelected as PlaidAccount).id
    ) {
      fetchPlaidAccountDetails(
        (paymentMethodSelected as PlaidAccount).id,
        language
      );
    }

    if (paymentMethodSelected) {
      (async () => {
        const deliveryMethodDestinations = await getDeliveryMethodDestinations({
          countryDestination: countryDestination,
          deliveryMethod:
            currentDeliveryMethodQuote?.deliveryMethodCode || "D2B",
          sponsorBank:
            (paymentMethodSelected as PaymentMethod)?.sponsorBank ||
            "NoSponsor",
        });
        setSendToSelectOptions(deliveryMethodDestinations);
      })();
    }
  }, [paymentMethodSelected]);

  useEffect(() => {
    if (currentDeliveryMethodQuote?.paymentMethods && paymentMethodSelected) {
      const methodFromQuote = currentDeliveryMethodQuote.paymentMethods.find(
        (method) =>
          paymentMethodSelected.paymentSource === "Sendola Plaid"
            ? method.paymentOrigin === "Sendola ACH"
            : paymentMethodSelected.paymentSource === "Coppel"
            ? method.paymentOrigin === "Sendola Card"
            : method.paymentOrigin === paymentMethodSelected.paymentSource
      );

      if (
        methodFromQuote &&
        (methodFromQuote.paymentOrigin !==
          currentPaymentMethod?.paymentOrigin ||
          methodFromQuote.totalCost !== currentPaymentMethod?.totalCost)
      ) {
        dispatch(
          QuotationSlice.actions.setCurrentPaymentMethod(methodFromQuote)
        );
      } else if (!methodFromQuote) {
        dispatch(QuotationSlice.actions.setCurrentPaymentMethod(undefined));
      }
    }
  }, [paymentMethodSelected, currentDeliveryMethodQuote]);

  return {
    isLoading,
    hasError,
    quoterValue,
    sendToSelectOptions,
    deliveryMethodsSelectOptions,
    countryDestination,
    currentPaymentDestination,
    quoteModal,
    paymentMethods,
    getCurrentQuote,
    handleQuotationChange,
    resetQuote,
    fetchPaymentMethods,
  };
};
