import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import {
  GrayCard,
  GrayCardSubtitle,
} from "../../../../Components/GrayCard/GrayCard.styled";
import { tPayOutMethodSubtitle } from "../../../../Models/ReviewTransaction";
import { TextColors } from "../../../../Models/TextInterface";
import { textColors } from "../../../../Contexts/ThemeProvider/Pages";

export const PayOutMethodStyled = styled(GrayCard)``;

export const PayOutMethodBank = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const PayOutMethodBankName = styled.div`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  min-width: 40%;
`;

export const PayOutMethodBankAddress = styled.div`
  width: 206px;
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;

export const PayOutMethodAdvertisement = styled.p<{
  color?: TextColors;
}>`
  color: ${ifProp("color", textColors, "inherit")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
`;
export const PayOutMethodSubtitle = styled(
  GrayCardSubtitle
)<tPayOutMethodSubtitle>`
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url(${prop("$icon")});
  padding-left: 50px;
`;

export const PayOutMethodAccountNumberLabel = styled.span`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;
export const PayOutMethodAccountNumber = styled.span`
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
