import { Currency } from "../../Common/domain/Currency";

import { Quote } from "../domain/Quote";
import { QuoteRepository } from "../domain/QuoteRepository";

export const getQuoteWithFees =
  (quoteRepository: QuoteRepository) =>
  async (
    countryCode: string,
    currencyCode: string,
    amount: number,
    payerQuote?: string
  ): Promise<Quote> =>
    quoteRepository.getQuoteWithFees(
      countryCode,
      currencyCode,
      amount,
      payerQuote
    );

export const getQuoteWithFeesAndTiers =
  (quoteRepository: QuoteRepository) =>
  async (
    countryCode: string,
    currencyCode: string,
    amount: number,
    payerQuote?: string
  ) =>
    quoteRepository.getQuoteWithFeesAndTiers(
      countryCode,
      currencyCode,
      amount,
      payerQuote
    );
