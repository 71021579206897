import styled, { css } from "styled-components";
import { prop, ifProp, theme } from "styled-tools";
import { handleColor } from "../../Utilities/Icons";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { PropsText } from "../../Models/TextInterface";
import { setTextSize } from "./functions/setTextSize";

export const TextStyled = styled.p<PropsText>`
  display: ${prop("display", "block")};
  align-items: ${prop("alignItems")};
  color: ${(props) =>
    props.color ? handleColor(props.color) : theme("Content_UX_01")};
  font-family: Poppins;
  font-weight: ${ifProp("weight", prop("weight"), "400")};
  ${setTextSize()}
  text-align: ${ifProp("align", prop("align"), "center")};
  margin: ${prop("margin")};
  line-height: ${prop("lineHeight")};
  letter-spacing: ${prop("letterSpacing")};

  ${ifProp(
    "overflow",
    css`
      text-overflow: ${prop("overflow", "elipsis")};
      max-width: 100%;
      max-height: 21px;
      overflow: hidden;
      white-space: nowrap;
    `
  )}

  ${mediaQuery[Screen.xs]} {
    ${setTextSize(Screen.xs)};
  }
  ${mediaQuery[Screen.sm]} {
    ${setTextSize(Screen.sm)};
  }
  ${mediaQuery[Screen.md]} {
    ${setTextSize(Screen.md)};
  }
  ${mediaQuery[Screen.lg]} {
    ${setTextSize(Screen.lg)};
  }
  ${mediaQuery[Screen.xl]} {
    ${setTextSize(Screen.xl)};
  }
  ${mediaQuery[Screen.xxl]} {
    ${setTextSize(Screen.xxl)};
  }
  ${mediaQuery[Screen.mobile]} {
    ${setTextSize(Screen.mobile)};
  }
  ${mediaQuery[Screen.desktop]} {
    ${setTextSize(Screen.desktop)};
  }
`;
