import USILogo from "../Assets/Img/usi-logo.png";
import ZelleLogo from "../Assets/Img/zelle-rounded.png";

export const PAYMENTS_OPTIONS: Array<{
  paymentOrigin: string;
  path: string;
  logo?: string;
}> = [
  {
    paymentOrigin: "Sendola Card",
    path: "/payments-sendola-pay-instructions",
    logo: USILogo,
  },
  {paymentOrigin: "Zelle", path: "/review-transaction", logo: ZelleLogo},
  {
    paymentOrigin: "Square",
    path: "/square",
  },
  {
    paymentOrigin: "Sendola ACH",
    path: "/payment-ach",
  },
  {
    paymentOrigin: "Debit Card",
    path: "/payment-card?type=debit",
  },
  {
    paymentOrigin: "Credit Card",
    path: "/payment-card?type=credit",
  },
];
