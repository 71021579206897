import CashPurpleIconSrc from "../../../../Assets/Icons/cash-purple.svg";
import IconFont from "../../../../Components/IconFont";
import { tPickUpInstructionsProps } from "../../../../Models/TransactionCompleted";
import {
  PickUpInstructionsDescription,
  PickUpInstructionsStyled,
  PickUpInstructionsTitle,
} from "./PickUpInstructions.styled";

export default function PickUpInstructions(props: tPickUpInstructionsProps) {
  const { title, description } = props;

  return (
    <PickUpInstructionsStyled>
      <IconFont name="cash" size="xlarge" gradient />
      <PickUpInstructionsTitle>{title}</PickUpInstructionsTitle>
      <PickUpInstructionsDescription>
        {description}
      </PickUpInstructionsDescription>
    </PickUpInstructionsStyled>
  );
}
