import { tCurrencyInfoProps } from "../../../../Models/ReviewTransaction";
import {
  CurrencyInfoAmount,
  CurrencyInfoDetail,
  CurrencyInfoIcon,
  CurrencyInfoLabel,
  CurrencyInfoStyled,
  CurrencyInfoType,
} from "./CurrencyInfo.styled";

export default function CurrencyInfo(props: tCurrencyInfoProps) {
  const { label, amount, type, icon } = props;

  return (
    <CurrencyInfoStyled>
      <CurrencyInfoLabel>{label}</CurrencyInfoLabel>
      <CurrencyInfoDetail>
        <CurrencyInfoType>
          <CurrencyInfoIcon src={icon} />
          {type}
        </CurrencyInfoType>
        <CurrencyInfoAmount>{amount}</CurrencyInfoAmount>
      </CurrencyInfoDetail>
    </CurrencyInfoStyled>
  );
}
