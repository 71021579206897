import { GrayCardTitle } from "../../../../Components/GrayCard/GrayCard.styled";
import { PropsWithChildren } from "react";
import { PaymentMethodCardStyled } from "./PaymentMethod.styled";
import { FlexContainer } from "../../../../Components/Flex/Flex.styled";
import Text from "../../../../Components/Text";
import { useTranslation } from "react-i18next";
import IconFont from "../../../../Components/IconFont";
import { useModal } from "../../../../Hooks/useModal";

interface PaymentMethodCardProps extends PropsWithChildren {
  title?: string;
  paymentMethodName?: string;
  logo?: string;
}

export function PaymentMethod({
  title,
  logo,
  paymentMethodName,
}: PaymentMethodCardProps) {
  const [t] = useTranslation("global");
  const { modal, showModal } = useModal();

  const openZelleInstructions = () => {
    showModal({
      modalType: "zelleInstructionsModal",
    });
  };

  return (
    <PaymentMethodCardStyled>
      <GrayCardTitle>{title}</GrayCardTitle>
      <FlexContainer alignItems="center" gap="12px">
        {logo && <img src={logo} width="42px" height="42px" />}
        <Text weight={600} size={1} align="left">
          {paymentMethodName}
        </Text>
      </FlexContainer>
      {paymentMethodName === "Zelle" && (
        <FlexContainer
          alignItems="center"
          gap="11px"
          onClick={openZelleInstructions}
        >
          <IconFont name="info" size="medium" />
          <Text weight={600} size={0} align="left" color="zelle">
            {t("Zelle.howToCta")}
          </Text>
          {modal}
        </FlexContainer>
      )}
    </PaymentMethodCardStyled>
  );
}
