import styled from "styled-components";
import { tTableInfoColumn } from "../../Models/TableInfo";
import { handleColor } from "../../Utilities/Icons";
import { ifProp, prop, theme } from "styled-tools";

export const TableInfoStyled = styled.div<{ $withSpace?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) => (props.$withSpace ? "12px" : "24px")};
`;

export const TableInfoRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
`;

export const TableInfoColumn = styled.div<tTableInfoColumn>`
  flex: 1;
  flex-grow: ${ifProp("grow", prop("grow"), "1")};
  padding: ${ifProp("padding", prop("padding"), "0px")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  text-align: ${(props) => props.textAlign || "left"};
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) =>
    props.color ? handleColor(props.color) : props.theme.Content_UX_01};
`;

export const TextInfo = styled.p<{ align?: "right" | "left" }>`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  color: ${theme("Content_UX_01")};
  text-align: ${ifProp("align", prop("align"), "left")};
  margin: 0;
  margin-top: -22px;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  padding: 15px 0px;
  text-align: left;
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${theme("Background_03")};
  margin: 24px 0;
`;

export const CirclePlusIcon = styled.img`
  display: inline;
  margin-right: 3px;
  color: ${theme("Content_UX_02")};
  width: 15px;
  height: 15px;
`;
