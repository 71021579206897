import styled from "styled-components";

export const GrayCard = styled.div`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 38px;
  align-self: stretch;
  border-radius: 20px;
  background-color: ${(props) => props.theme.Content_State_UX_Disabled};
  margin-bottom: 40px;
`;

export const GrayCardTitle = styled.h3`
  color: ${(props) => props.theme.Content_UX_01};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-align: left;
`;

export const GrayCardSubtitle = styled.h3`
  color: ${(props) => props.theme.Content_UX_01};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
