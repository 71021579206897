import styled, { css } from "styled-components";
import { ifProp, theme } from "styled-tools";

export const BeneficiarytIcon = styled.img`
  width: 56px;
  height: 56px;
`;

export const BeneficiaryDisplayName = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

export const BeneficiaryName = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;

export const CardNumber = styled.div`
  display: flex;
  flex-direction: row;
  color: ${theme("Context_UX_01")};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  text-wrap: pretty;
`;

export const ActionButtons = styled.div`
  text-align: right;
  margin-left: auto;
`;

export const AccountsCard = styled.div<{ select: boolean }>`
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  border-radius: 8px;
  padding: 7px 0 7px 7px;

  &:hover {
    ${ifProp(
      "select",
      css`
        cursor: pointer;
        border: 1px solid ${theme("Background_03")};
        background-color: ${theme("Content_State_UX_Disabled")};
      `
    )}
  }
`;

export const AddBeneficiaryBtn = styled.a`
  box-sizing: border-box;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  justify-content: end;
  cursor: pointer;
`;

export const FlagsContainer = styled.div`
  height: auto;
  max-height: 31.36px;
  position: relative;
  width: min-content;
  min-width: 34.21px;
  text-align: left;
  align-self: center;

  img {
    width: 22.8px;
    height: 22.8px;

    &:nth-child(2) {
      margin-left: 11.4px;
      transform: translateY(-22.8px);
    }
  }
`;
