import { useEffect, useState, useContext, useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Link, useBlocker, useNavigate } from "react-router-dom";

// own states
import { State } from "../../Redux/Store";

// Own components
import Button from "../../Components/Button";
import Text from "../../Components/Text";
import IconFont from "../../Components/IconFont";
import { Column, Container, Row } from "../../Components/Grid";
import Layout from "../../Components/Layout";
import TransactionLoader from "../../Components/TransactionLoader";

import USIconSrc from "../../Assets/Flags/eu.png";
import { RowBold } from "../../Models/TableInfo";
import CurrencyService from "../../Services/CurrencyService";
import {
  maskInterbankKey,
  maskPhoneNumber,
  maskUsernameInEmail,
} from "../../Utilities/String";
import {
  ButtonContainer,
  VerticalDivider,
  disclaimerUniteller,
} from "./ReviewTransaction.styled";
import BalanceDetails from "./components/BalanceDetails";
import CurrencyInfo from "./components/CurrencyInfo";
import Destination from "./components/Destination";
import PayOutMethod from "./components/PayOutMethod";
import RecipientDetails from "./components/RecipientDetails";
import TransactionError from "./components/TransactionError";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import { BeneficiarySelector } from "../../Redux/Beneficiary/Beneficiary.slice";
import { formatNumber } from "../../Utilities/NumberUtils";
import { PaymentOrderPayload } from "../../Modules/PaymentOrders/domain/PaymentOrderCreated";
import { usePaymentOrderPayload } from "../../Hooks/usePaymentOrderPayload";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { useSendMoneyStepper } from "../../Hooks/useSendMoneyStepper";
import { ProgressSteps } from "../../Components/Steps";

import { SectionTitle } from "../TansactionCompleted/TransactionCompleted.styled";

import { ThemeContext } from "../../Contexts/ThemeContext/ThemeContext";
import { useModal } from "../../Hooks/useModal";
import { imgTypes } from "../../Utilities/Icons";
import { scrollTopWindow } from "../../Utilities/ScrollTopWindow";
import { rails } from "../../Constants/Rails";
import { countrySelector } from "../../Redux/Country/Country.slice";
import { ExchangeRate } from "../../Modules/Common/domain/ExchangeRates";
import { PaymentOrderVerifySelector } from "../../Redux/PaymentOrder/PaymentOrderVerify.slice";
import { useSquarePaymentOrder } from "../../Hooks/useSquarePaymentOrder";
import { AlignItems, JustifyContent } from "../../Types/Column";
import { useDashboard } from "../../Hooks/useDashboard";
import { PaymentMethod } from "./components/PaymentMethod/PaymentMethod.component";
import ZelleLogo from "../../Assets/Img/zelle-rounded.png";
import SquareLogo from "../../Assets/Img/square-rounded.png";

const ReviewTransactionPage = () => {
  const theme = useSelector((state: State) => state.theme);
  const [tModals] = useTranslation("modals");
  const { t } = useTranslation("global");
  const [isLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [hasError] = useState<boolean>(false);
  const {
    theme: { images },
  } = useContext(ThemeContext);
  const countries = useSelector(countrySelector).countries as ExchangeRate[];

  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    countryDestination,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
  } = useSelector(QuotationSelector).data;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;
  const isBankTransfer =
    currentDeliveryMethodQuote?.deliveryMethodCode === "D2B";

  const { newQuoterFeature } = useDashboard();

  const {
    isLoading: loadingPaymentOrder,
    error: paymentOrderError,
    createPayment,
  } = usePaymentOrder();
  const { modal: paymentOrderModal, showModal: showPaymentOrderModal } =
    useModal();
  const { paymentOrderPayload } = usePaymentOrderPayload();
  const { steps, activeStep } = useSendMoneyStepper({ initialActiveStep: 4 });
  const unitellerUrl = import.meta.env.VITE_UNITELLER_URL;
  const { currentVerifyResponse } = useSelector(
    PaymentOrderVerifySelector
  ).data;
  const { createSquarePayment, isLoading: LoadingSquare } =
    useSquarePaymentOrder();
  const loading = isLoading || loadingPaymentOrder || LoadingSquare;

  const balanceDetails = [
    {
      id: "transferAmount",
      label: t("ReviewTransaction.TransactionDetails.transferAmount"),
      label2: t("ReviewTransaction.TransactionDetails.exchangeRate"),
      value: `$ ${CurrencyService.getFloatAmount(
        currentQuotation?.amount.toString() || "0"
      )} USD`,
      value2: `$ 1.00 USD = ${currentDeliveryMethodQuote?.fx.toFixed(4)} ${
        currentQuotation?.destinationCurrency
      }`,
      bold: RowBold.NONE,
      withDivider: true,
    },
    {
      id: "sendingFee",
      label: t("ReviewTransaction.TransactionDetails.sendingFee"),
      value: `$ ${CurrencyService.getFloatAmount(
        currentDeliveryMethodQuote?.fee.toString() || "0"
      )} USD`,
      bold: RowBold.NONE,
      withDivider: false,
      withCirclePlus: true,
      padding: "0px",
    },
    {
      id: "paymentMethodFee",
      label: t("ReviewTransaction.TransactionDetails.paymentMethodFee"),
      value: `$ ${CurrencyService.getFloatAmount(
        currentPaymentMethod?.paymentMethodFee.toString() || "0"
      )} USD`,
      bold: RowBold.NONE,
      withDivider: true,
      withCirclePlus: true,
      padding: "0px",
    },
    {
      id: "totalPayment",
      label: t("ReviewTransaction.TransactionDetails.totalPayment"),
      value: `$ ${CurrencyService.getUSDAmountWithFees(
        currentQuotation?.amount?.toString() || "0",
        (currentDeliveryMethodQuote?.fee || 0) +
          (currentPaymentMethod?.paymentMethodFee || 0),
        2
      )} USD`,
      bold: RowBold.ROW,
      withDivider: false,
      padding: "0px",
    },
  ];

  let payOutMethodSubtitle = t("ReviewTransaction.PayOutMethod.CashPickUp");

  if (isBankTransfer) {
    payOutMethodSubtitle = t("ReviewTransaction.PayOutMethod.DirectToBank");
  }

  const preparePayment = async () => {
    if (currentPaymentMethod) {
      scrollTopWindow();
      const request = paymentOrderPayload as PaymentOrderPayload;
      const response = await createPayment(request);

      if (response) {
        if (
          currentVerifyResponse &&
          currentPaymentMethod?.paymentOrigin === "Square"
        ) {
          const responseSquare = await createSquarePayment(
            currentVerifyResponse.id,
            currentPaymentMethod?.paymentMethodFee || 0
          );

          if (responseSquare) {
            window.location.href = responseSquare.payment_link.url;
          }
        }
        navigate("/transaction-completed");
      }
    }
  };

  const railName = useMemo(() => {
    return currentPaymentDestination?.rail
      ? rails[currentPaymentDestination?.rail as keyof typeof rails]
      : null;
  }, [currentPaymentDestination?.rail]);

  useEffect(() => {
    if (paymentOrderError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paymentOrder.errorMessage"),
      });
    }
  }, [paymentOrderError]);

  useEffect(() => {
    if (!currentQuotation || !currentDeliveryMethodQuote) {
      navigate("/dashboard");
    }
  }, []);

  useBlocker(
    ({ nextLocation }) =>
      loading &&
      ["/dashboard", "/beneficiary-selection"].includes(nextLocation.pathname)
  );

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <TransactionLoader
            title={t("ReviewTransaction.LoaderTitle")}
            text={t("ReviewTransaction.LoaderText")}
            description={t("ReviewTransaction.LoaderDescription")}
            image="transferLoader"
            bgImage={images["bgTransactionLoader"]}
          />
        </Layout>
      </ThemeProvider>
    );
  }

  if (hasError) {
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <TransactionError
            title={t("ReviewTransaction.TransactionErrorTitle")}
            message={t("ReviewTransaction.TransactionErrorMessage")}
            retryButton={t("ReviewTransaction.RetryTransactionButton")}
            cancelButton={t("ReviewTransaction.CancelButton")}
          />
        </Layout>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout
        path={newQuoterFeature ? "/dashboard" : "/payments"}
        textLink={t("buttons.back")}
        loading={loading}
      >
        {!newQuoterFeature && (
          <ProgressSteps steps={steps} activeStep={activeStep} />
        )}
        <Container>
          <Row>
            <Column
              alignItems={AlignItems.flexStart}
              direction="column"
              span={5}
              xs={12}
              sm={12}
              px={3}
            >
              <Row>
                <Column span={12}>
                  <SectionTitle>
                    {t("ReviewTransaction.SectionTitle")}
                  </SectionTitle>
                </Column>
              </Row>

              <Row>
                <Column span={12}>
                  <Destination
                    from="United States"
                    to={countryDestination || "GTM"}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={12}>
                  <CurrencyInfo
                    label={t("ReviewTransaction.YouSendExactly")}
                    type="USD"
                    amount={`$ ${formatNumber(currentQuotation?.amount || 0)}`}
                    icon={USIconSrc}
                  />
                  <CurrencyInfo
                    label={t("ReviewTransaction.RecipientGet")}
                    type={currentQuotation?.destinationCurrency || ""}
                    amount={`$ ${formatNumber(
                      currentDeliveryMethodQuote?.amountToReceive || 0
                    )}`}
                    icon={
                      countries.find(
                        (exchange) =>
                          exchange.countryCode === countryDestination
                      )?.icon || ""
                    }
                  />
                </Column>
              </Row>
            </Column>
            <Column>
              <VerticalDivider />
            </Column>
            <Column span={6} xs={12} sm={12} px={3} mb={3}>
              <Row>
                <Column span={12}>
                  <PayOutMethod
                    title={t("ReviewTransaction.PayOutMethod.title")}
                    subtitle={payOutMethodSubtitle}
                    advertisement={t(
                      "ReviewTransaction.PayOutMethod.advertisement"
                    )}
                    bankName={
                      isBankTransfer
                        ? beneficiarySelected?.accountSelected?.bankName
                        : currentPaymentDestination?.destination
                    }
                    bankAddress={" "}
                    type={currentDeliveryMethodQuote?.deliveryMethodCode || ""}
                    interbankKey={
                      (beneficiarySelected?.accountSelected?.accountNumber &&
                        maskInterbankKey(
                          beneficiarySelected.accountSelected.accountNumber
                        )) ||
                      ""
                    }
                  />

                  <PaymentMethod
                    title={t("ReviewTransaction.PaymentMethod")}
                    logo={
                      currentPaymentMethod?.paymentOrigin === "Zelle"
                        ? ZelleLogo
                        : currentPaymentMethod?.paymentOrigin === "Square"
                        ? SquareLogo
                        : undefined
                    }
                    paymentMethodName={
                      currentPaymentMethod?.paymentOrigin === "Sendola Card"
                        ? "SendolaPay"
                        : currentPaymentMethod?.paymentOrigin
                    }
                  />

                  <RecipientDetails
                    title={t("ReviewTransaction.RecipientDetails.title")}
                    name={beneficiarySelected?.fullName || ""}
                    phone={maskPhoneNumber(
                      beneficiarySelected?.phoneNumber || ""
                    )}
                    email={maskUsernameInEmail(
                      beneficiarySelected?.email || ""
                    )}
                  />

                  <BalanceDetails
                    title={t("ReviewTransaction.TransactionDetails.title")}
                    data={balanceDetails}
                    notice={
                      currentPaymentDestination?.rail === "UNT"
                        ? t("ReviewTransaction.TransactionUniteller")
                        : railName
                        ? t("TransactionCompleted.TransactionDetails.notice", {
                            rail: railName,
                          })
                        : t("ReviewTransaction.TransactionDetails.notice")
                    }
                  />
                </Column>
              </Row>
              {currentPaymentDestination?.rail === "UNT" && (
                <Row>
                  <Column span={12} justifyContent={JustifyContent.center}>
                    <img src={imgTypes["logoUniteller"]} alt="logo-header" />
                  </Column>
                </Row>
              )}
            </Column>
          </Row>
          {currentPaymentDestination?.rail === "UNT" && (
            <Row>
              <Column span={12} as={disclaimerUniteller}>
                <IconFont
                  name="circle-exclamation"
                  size="medium"
                  color="error"
                />
                <Text margin={"0 10px"} size={1}>
                  <Link
                    to={`${unitellerUrl}`}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {t("ReviewTransaction.disclaimerUniteller")}
                  </Link>
                  {t("ReviewTransaction.disclaimerUniteller2")}
                </Text>
              </Column>
            </Row>
          )}
          <Row>
            <Column span={12} mt={3} px={3}>
              <ButtonContainer>
                <Button
                  variant="primary"
                  sizeButton="full"
                  sizeText="large"
                  text={t("ReviewTransaction.ActionButton")}
                  sizeIcon="large"
                  iconButton="arrowRight"
                  colorIcon="white"
                  disabled={loading}
                  onClick={() => preparePayment()}
                />
              </ButtonContainer>
            </Column>
          </Row>
        </Container>
        {paymentOrderModal}
      </Layout>
    </ThemeProvider>
  );
};

export default withTranslation("ns")(ReviewTransactionPage);
