import { useTranslation } from "react-i18next";
import Text from "../../../../Components/Text";
import { Controller, useFormContext } from "react-hook-form";
import { SearchSelect } from "../../../../Components/SearchSelect/SearchSelect.component";
import { useCountry } from "../../../../Hooks/useCountry";
import { useSubdivision } from "../../../../Hooks/useSubdivision";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../../Redux/Translate";
import { useEffect, useMemo, useRef, useState } from "react";
import { SelectInstance } from "react-select";
import { SelectOption } from "../../../../Models/Select";
import { useCity } from "../../../../Hooks/useCity";
import { FormInput } from "../../../../Components/Input";
import { CountryCodeList } from "../../../../Modules/Common/domain/PhoneCountryCode";
import { QuotationSelector } from "../../../../Redux/Quotation/Quotation.slice";
import { BeneficiarySelector } from "../../../../Redux/Beneficiary/Beneficiary.slice";

interface AddressFormProps {
  onLoadSubdivisions?: (subdivisions: SelectOption[]) => void;
  onSelectCountry?: (countryCode: string) => void;
}

export const AddressForm = ({
  onLoadSubdivisions,
  onSelectCountry,
}: AddressFormProps) => {
  const { t } = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const { countriesWithoutUSAOptions, isLoading: countryIsLoading } =
    useCountry();
  const {
    subdivisionList,
    isLoading: subdivisionIsLoading,
    getSubdivisionOptions,
  } = useSubdivision();
  const {
    citiesList,
    isLoading: cityIsLoading,
    getSubdivisionCitiesOptions,
  } = useCity();
  const subdivRef = useRef<SelectInstance<SelectOption> | null>(null);
  const destinationRef = useRef<SelectInstance<SelectOption> | null>(null);
  const cityRef = useRef<SelectInstance<SelectOption> | null>(null);

  const quoteCountrySelected = useSelector(QuotationSelector).data
    .countryDestination as string;
  const beneficiaryCountrySelected =
    useSelector(BeneficiarySelector).data.countrySelected;
  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    beneficiaryCountrySelected?.countryCode || quoteCountrySelected
  );

  const zipCodeMinLenght = useMemo(() => {
    const minLength =
      CountryCodeList.find((c) => c.countryCode === selectedCountry)
        ?.zipCodeLength || 5;

    return minLength;
  }, [selectedCountry]);

  const isLoading = subdivisionIsLoading || cityIsLoading || countryIsLoading;

  const onChangeCountry = async (value: string) => {
    setSelectedCountry(value);
    if (onSelectCountry) {
      onSelectCountry(value);
    }
    setValue("destination", "");
    destinationRef.current?.clearValue();

    setValue("subdivision", "");
    subdivRef.current?.clearValue();

    const subdivisions = await getSubdivisionOptions(value, language);
    if (subdivisions && onLoadSubdivisions) {
      onLoadSubdivisions(subdivisions);
    }
  };

  const onChangeSubdivision = async (value: string) => {
    setValue("city", "");
    cityRef.current?.clearValue();
    if (value) {
      await getSubdivisionCitiesOptions(value);
    }
  };

  useEffect(() => {
    if (selectedCountry && language) {
      setValue("country", selectedCountry);
      onChangeCountry(selectedCountry);
    }
  }, [language]);

  return (
    <>
      <Text
        size={2}
        lineHeight="24px"
        weight={600}
        align="left"
        margin="0 0 25px 2px"
      >
        {t("Beneficiaries.AddressTitle")}
      </Text>

      <Controller
        control={control}
        name="country"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <SearchSelect
              errors={errors}
              placeholderInput={t(
                "Beneficiaries.CreateBeneficiary.Form.Country"
              )}
              options={countriesWithoutUSAOptions}
              name="country"
              value={countriesWithoutUSAOptions.find((c) => c.value === value)}
              m="0 0 25px 0"
              showAvatar
              showAvatarOnValue
              onBlur={onBlur}
              onChange={(sel) => {
                if (sel?.value) {
                  onChange(sel.value);
                  onChangeCountry(sel.value);
                }
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name="subdivision"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <SearchSelect
              selectRef={subdivRef}
              errors={errors}
              placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.State")}
              options={subdivisionList}
              name="subdivision"
              placeholder=""
              value={subdivisionList.find((s) => s.value === value)}
              m="0 0 25px 0"
              onBlur={onBlur}
              onChange={(sel) => {
                onChange(sel?.value);
                onChangeSubdivision(sel?.value);
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name="city"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <SearchSelect
            errors={errors}
            selectRef={cityRef}
            placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.City")}
            name="city"
            placeholder=""
            options={citiesList}
            m="0 0 25px 0"
            value={citiesList.find((s) => s.value === value)}
            onBlur={onBlur}
            onChange={(sel) => onChange(sel?.value || "")}
          />
        )}
      />

      <FormInput
        placeholderInput={
          selectedCountry && ["MEX", "GTM"].includes(selectedCountry)
            ? t("Beneficiaries.CreateBeneficiary.Form.AddressStreet")
            : t("Beneficiaries.CreateBeneficiary.Form.Address1")
        }
        label="address1"
        rules={{
          required: t("Forms.required"),
          validate: (value) =>
            value.trim() === "" ? t("Forms.required") : true,
        }}
        mb="25px"
        isError={Boolean(errors?.["address1"])}
      />

      <FormInput
        placeholderInput={
          selectedCountry && ["MEX", "GTM"].includes(selectedCountry)
            ? t("Beneficiaries.CreateBeneficiary.Form.AddressApartment")
            : t("Beneficiaries.CreateBeneficiary.Form.Address2")
        }
        label="address2"
        rules={{}}
        mb="25px"
      />

      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.PostalCode")}
        maxLength={zipCodeMinLenght}
        label="postalCode"
        rules={{
          required: t("Forms.required"),
          minLength: {
            value: zipCodeMinLenght,
            message: t(
              "Beneficiaries.CreateBeneficiary.Form.PostalCodeMinLength",
              {
                minLength: zipCodeMinLenght,
              }
            ),
          },
          validate: (value) =>
            value.trim() === "" ? t("Forms.required") : true,
        }}
        isError={Boolean(errors?.["postalCode"])}
      />
    </>
  );
};
