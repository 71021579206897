import { useTranslation } from "react-i18next";
import CashIcon from "../../../../Assets/Icons/cash.svg";
import DirectToBankIcon from "../../../../Assets/Icons/direct-to-bank.svg";
import { GrayCardTitle } from "../../../../Components/GrayCard/GrayCard.styled";
import { tPayOutMethodProps } from "../../../../Models/ReviewTransaction";
import {
  PayOutMethodAccountNumber,
  PayOutMethodAccountNumberLabel,
  PayOutMethodAdvertisement,
  PayOutMethodBank,
  PayOutMethodBankAddress,
  PayOutMethodBankName,
  PayOutMethodStyled,
  PayOutMethodSubtitle,
} from "./PayOutMethod.styled";

export default function PayOutMethod(props: tPayOutMethodProps) {
  const {
    title,
    subtitle,
    advertisement,
    bankName,
    bankAddress,
    type,
    interbankKey,
  } = props;
  const isDirectToBank = type === "D2B";
  let icon = CashIcon;
  const { t } = useTranslation("global");

  if (isDirectToBank) {
    icon = DirectToBankIcon;
  }

  return (
    <PayOutMethodStyled>
      <GrayCardTitle>{title}</GrayCardTitle>
      <PayOutMethodSubtitle $icon={icon}>{subtitle}</PayOutMethodSubtitle>
      {isDirectToBank ? (
        <PayOutMethodBank>
          <PayOutMethodAccountNumberLabel>
            {t("ReviewTransaction.AccountNumber")}:
          </PayOutMethodAccountNumberLabel>{" "}
          <PayOutMethodAccountNumber>{interbankKey}</PayOutMethodAccountNumber>
        </PayOutMethodBank>
      ) : (
        <>
          <PayOutMethodBank>
            <PayOutMethodBankName>{bankName}</PayOutMethodBankName>
            <PayOutMethodBankAddress>{bankAddress}</PayOutMethodBankAddress>
          </PayOutMethodBank>
          <PayOutMethodAdvertisement color="primary">
            {advertisement}
          </PayOutMethodAdvertisement>
        </>
      )}
    </PayOutMethodStyled>
  );
}
