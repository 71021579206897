import i18next from "i18next";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import Cookies from "universal-cookie";

// Global styles
import "./global.css";
import "./Assets/fonts/iconfont.css";

// Store
import store from "./Redux/Store";

// Global files
import global_en from "./Translation/En/global.json";
import global_es from "./Translation/Es/global.json";
import modals_en from "./Translation/En/modals.json";
import modals_es from "./Translation/Es/modals.json";
import sendmoney_es from "./Translation/Es/send-money.json";
import sendmoney_en from "./Translation/En/send-money.json";

// Own router
import { router } from "./Route";
import { ThemeProvider } from "./Contexts/ThemeContext/ThemeContext";
import { clearPersistedStores, doLogout } from "./Services/Session";

import packageJson from "../package.json";
import GoogleMapsLoader from "./Contexts/GeoLocation/GoogleMapsLoader";

const cookies = new Cookies();

i18next.init({
  interpolation: { escapeValue: false },
  lng: cookies.get("lang") || "es",
  resources: {
    es: {
      global: global_es,
      modals: modals_es,
      sendMoney: sendmoney_es,
    },
    en: {
      global: global_en,
      modals: modals_en,
      sendMoney: sendmoney_en,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const persistor = persistStore(store);

const appVersion = packageJson.version;

if (cookies.get("GP-AppVersion") !== appVersion) {
  cookies.set("GP-AppVersion", appVersion);
  clearPersistedStores(store.dispatch);
  window.location.replace("/");
}

root.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ThemeProvider>
        <I18nextProvider i18n={i18next}>
          <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
        </I18nextProvider>
      </ThemeProvider>
    </Provider>
  </PersistGate>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
