import styled from "styled-components";
import { theme } from "styled-tools";

import { textColors } from "../../../../Contexts/ThemeProvider/Pages";
import { TextColors } from "../../../../Models/TextInterface";

export const StepOneStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  align-self: stretch;
  margin-bottom: 56px;
`;
export const StepTwoStyled = styled(StepOneStyled)`
  gap: 34px;
`;

export const StepOneTitle = styled.h4`
  color: ${theme("Content_UX_01")};
  text-align: left;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 363px;
  margin: 0;
  background-position: left center;
  background-repeat: no-repeat;
  padding: 10px 50px;
`;

export const StepOneContent = styled.div`
  display: flex;
  max-width: 290px;
  padding: 16px;
  align-items: flex-start;
  gap: 322px;
  border-radius: 12px;
  background: ${theme("Background_01")};
  color: ${theme("Content_UX_01")};
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
`;

export const StepOneDetail = styled.p`
  color: ${theme("Content_UX_02")};
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 100%;
  margin: 0;
`;

export const StepTwoTitle = styled(StepOneTitle)`
  text-align: left;
`;

export const StepTwoUserCard = styled.div`
  span {
    font-size: 6.5em;
  }
`;

export const StepTwoDetail = styled.p<{
  color?: TextColors;
}>`
  color: ${textColors};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const StepTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
