import { ExchangeRate } from "./ExchangeRates";

export const allowedCountriesForPayment = [
  "US",
  ...(import.meta.env.VITE_ALLOWED_COUNTRIES_FOR_PAYMENT?.toString()?.split(
    ","
  ) || []),
];

export const allowedCountries = [
  "ARG",
  "BRA",
  "BOL",
  "CHL",
  "COL",
  "GTM",
  "ECU",
  "MEX",
  "PER",
  "URY",
  "SLV",
  "HND",
  "CAN",
  "NIC",
  "IND",
  "PHL",
];

export type Country = {
  countryCode: string;
  alpha2Code: string;
  numericCode: string;
  countryName: string;
  officialName: string;
  demonym: string;
  dialCode: string;
  displayOrder: string;
  defaultCurrency: string;
};

export type CountryWithCurrency = Country & ExchangeRate;

export interface CountryState {
  countries: CountryWithCurrency[];
}

export interface CountryRepository {
  getCountries: (
    lang: string,
    sponsorBank?: string
  ) => Promise<CountryWithCurrency[]>;
  getAllCountries: (lang: string) => Promise<CountryWithCurrency[]>;
}
