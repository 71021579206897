import styled from "styled-components";
import { ModalWrap } from "../Modal.styled";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { ifProp, prop, theme } from "styled-tools";
import { modalGradientBackground } from "../../../Contexts/ThemeProvider/Layout";

export const BottomModalWrapper = styled(ModalWrap)<{
  transformY?: string;
  isDragging?: boolean;
}>`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, ${prop("transformY", "0px")});
  width: 100%;
  max-width: 100%;
  margin: 0;
  border-radius: 24px 24px 0 0;
  padding: 24px;
  animation: slide-up ease-in 400ms;
  transition: ${ifProp("isDragging", "none", "transform 0.3s ease")};
  max-height: 100dvh;
  overflow-y: auto;
  background: ${modalGradientBackground};
  box-shadow: 0px -20px 24px 0px rgba(${theme("RGBA_01")}, 0.25);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme("Content_UX_01")}71;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  ${mediaQuery[Screen.desktop]} {
    max-width: 393px;
    bottom: unset;
    border-radius: 24px;
    animation: none;
  }

  @keyframes slide-up {
    0% {
      bottom: -100%;
    }
    30% {
      bottom: -70%;
    }
    40% {
      bottom: -60%;
    }
    50% {
      bottom: -50%;
    }
    70% {
      bottom: -30%;
    }
    90% {
      bottom: -10%;
    }
    100% {
      bottom: 0;
    }
  }
`;
