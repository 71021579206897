import { useEffect, useState } from "react";
import { BeneficiarySelector } from "../Redux/Beneficiary/Beneficiary.slice";
import { useSelector } from "react-redux";
import { QuotationSelector } from "../Redux/Quotation/Quotation.slice";
import { PaymentOrderPayload } from "../Modules/PaymentOrders/domain/PaymentOrderCreated";
import CurrencyService from "../Services/CurrencyService";
import { PaymentAchSelector } from "../Redux/PaymentACH";
import { PaymentOrderVerifySelector } from "../Redux/PaymentOrder/PaymentOrderVerify.slice";
import {
  PaymentMethod,
  PaymentMethods,
  PlaidAccount,
} from "../Modules/Common/domain/PaymentMethods";
import { PaymentLinkedAccountSelector } from "../Redux/PaymentLinkedAccount/PaymentLinkedAccount.slice";

export const usePaymentOrderPayload = () => {
  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
  } = useSelector(QuotationSelector).data;
  const { achInformation } = useSelector(PaymentAchSelector).data;
  const { linkedAccountInformation } = useSelector(
    PaymentLinkedAccountSelector
  ).data;
  const { currentVerifyResponse } = useSelector(
    PaymentOrderVerifySelector
  ).data;
  const currentBeneficiarySelected =
    useSelector(BeneficiarySelector).data.beneficiarySelected;
  const [paymentOrderPayload, setPaymentOrderPayload] = useState<unknown>(null);

  const generateACHPayload = () => {
    if (currentPaymentMethod && achInformation && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
        },
        payment: {
          paymentSource: null,
          amount: currentPaymentMethod.totalCost,
          accountNumber: achInformation.accountNumber,
          routingNumber: achInformation.labelRouting,
          fullName: achInformation.holderName,
          bankName: achInformation.bankName,
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateSendolaPayPayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
        },
        payment: {
          paymentSource: "SendolaPay",
          amount: currentPaymentMethod.totalCost,
          bankName:
            currentBeneficiarySelected?.accountSelected?.bankName ||
            currentPaymentDestination?.destination ||
            "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateZellePayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
        },
        payment: {
          paymentSource: "Zelle",
          amount: currentPaymentMethod.totalCost,
          bankName:
            currentBeneficiarySelected?.accountSelected?.bankName ||
            currentPaymentDestination?.destination ||
            "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateSquarePayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
        },
        payment: {
          paymentSource: "Square",
          amount: currentPaymentMethod.totalCost,
          bankName: "",
          accountId: "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generatePlaidPayload = () => {
    if (
      currentPaymentMethod &&
      (linkedAccountInformation || paymentMethodSelected) &&
      currentVerifyResponse
    ) {
      //? (paymentMethodSelected as PlaidAccount) for the new quoter flow and linkedAccountInformation for the old one
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
        },
        payment: {
          paymentSource: "Plaid",
          amount: currentPaymentMethod.totalCost,
          accountId:
            linkedAccountInformation?.accountId ||
            (paymentMethodSelected as PlaidAccount).id,
          accountNumber:
            linkedAccountInformation?.accountNumber ||
            (paymentMethodSelected as PlaidAccount).accountNumber,
          routingNumber:
            linkedAccountInformation?.routingNumber ||
            (paymentMethodSelected as PlaidAccount).routingNumber,
          bankName:
            linkedAccountInformation?.bankName ||
            (paymentMethodSelected as PlaidAccount).bankName,
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateCoppelPayload = () => {
    if (
      currentPaymentMethod &&
      currentVerifyResponse &&
      paymentMethodSelected
    ) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
        },
        payment: {
          paymentSource: "Coppel",
          amount: currentPaymentMethod.totalCost,
          accountId: (paymentMethodSelected as PaymentMethod).id,
          accountNumber: (paymentMethodSelected as PaymentMethod).accountNumber,
          routingNumber: (paymentMethodSelected as PaymentMethod).routingNumber,
          bankName: "Coppel",
        },
        id: currentVerifyResponse.id,
      };
      return request;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (currentPaymentMethod && !paymentMethodSelected) {
      const generatePayloadFnMap: Record<PaymentMethods, () => unknown> = {
        "Sendola Card": generateSendolaPayPayload,
        "Sendola ACH": generateACHPayload,
        "Sendola Plaid": generatePlaidPayload,
        "Debit Card": () => {
          return null;
        },
        "Credit Card": () => {
          return null;
        },
        Coppel: () => {
          return null;
        },
        Square: generateSquarePayload,
        Zelle: generateZellePayload,
      };

      const fn =
        generatePayloadFnMap[
          currentPaymentMethod.paymentOrigin as PaymentMethods
        ];

      if (fn) {
        setPaymentOrderPayload(fn());
      }
    }
  }, [
    currentPaymentMethod,
    achInformation,
    linkedAccountInformation,
    currentVerifyResponse,
    paymentMethodSelected,
  ]);

  //? this effect works for the new quoter flow, its separated in order to maintain the old quoter flow active
  useEffect(() => {
    if (currentPaymentMethod && paymentMethodSelected) {
      const generatePayloadFnMap: Record<PaymentMethods, () => unknown> = {
        "Sendola Card":
          paymentMethodSelected?.paymentSource === "Coppel"
            ? generateCoppelPayload
            : generateSendolaPayPayload,
        "Sendola ACH": generatePlaidPayload,
        "Sendola Plaid": generatePlaidPayload,
        "Debit Card": () => {
          return null;
        },
        "Credit Card": () => {
          return null;
        },
        Coppel: generateCoppelPayload,
        Square: generateSquarePayload,
        Zelle: generateZellePayload,
      };

      const fn =
        generatePayloadFnMap[
          currentPaymentMethod.paymentOrigin as PaymentMethods
        ];

      if (fn) {
        setPaymentOrderPayload(fn());
      }
    }
  }, [
    currentPaymentMethod,
    linkedAccountInformation,
    currentVerifyResponse,
    paymentMethodSelected,
  ]);

  return { paymentOrderPayload };
};
