import IconFont from "../../../../Components/IconFont";
import { tDestinationProps } from "../../../../Models/ReviewTransaction";
import { DestinationLabel, DestinationStyled } from "./Destination.styled";

export default function Destination(props: tDestinationProps) {
  const { from, to } = props;

  return (
    <DestinationStyled>
      <DestinationLabel>{from}</DestinationLabel>
      <IconFont name="transaction" size="xxlarge" gradient />
      <DestinationLabel>{to}</DestinationLabel>
    </DestinationStyled>
  );
}
