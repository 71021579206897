createPaymentOrderSquare;

import { useDispatch } from "react-redux";
import { AppDispatch } from "../Redux/Store";
import { useStatus } from "./useStatus";
import { Errors } from "../Modules/Common/domain/Errors";
import { SquareApiPaymentOrderRepository } from "../Modules/PaymentOrders/infrastructure/ApiPaymentWithSquareRepository";
import { createPaymentOrderSquare } from "../Modules/PaymentOrders/application/createPaymentOrder";

export const useSquarePaymentOrder = () => {
  const { status, isLoading, hasError, error, setStatus, setError } =
    useStatus();

  const createSquarePayment = async (
    idTransfer: string,
    paymentMethodFee: number
  ) => {
    setStatus("loading");
    try {
      const response = await createPaymentOrderSquare(
        SquareApiPaymentOrderRepository()
      )(idTransfer, paymentMethodFee);

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  return {
    isLoading,
    createSquarePayment,
  };
};
