import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import { horizontalLinearGradient } from "../../../Contexts/ThemeProvider/Pages";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  border-radius: 20px;
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  justify-self: center;

  p {
    margin: 0;
  }

  span {
    font-weight: 600;
  }

  div:nth-child(1) {
    position: relative;
  }
`;

export const UnavailableOverflow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff8b;
  border-radius: 20px;
`;

export const UnavailableRibbon = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  background: ${horizontalLinearGradient};
  border-end-end-radius: 20px;
  border-end-start-radius: 20px;

  p {
    color: white;
  }
`;

export const TextSpacingBottom = styled.div`
  padding-bottom: 16px;
  text-wrap: balance;
`;

export const PaymentIcon = styled.img`
  width: auto;
  height: 42px;
  padding: 0.2rem;
  border-radius: 10%;
`;

export const CardWrapper = styled.div`
  cursor: pointer;
`;

export const PaymentIconColumn = styled.div`
  ${mediaQuery[Screen.mobile]} {
    padding-left: 0;
  }
`;
