/**
 * Takes a string with a "formatted" number with or without thousands separators, and returns the parsed number.
 *
 * Uses `parseFloat()`
 *
 * @param sep Separator for the integer part. Default comma (`,`)
 * @param str String to parse
 * @returns The string parsed as a proper `number`
 */
export function parseNumberStringWithSeparators(str: string, sep = ",") {
  if (sep === ".") {
    throw new Error("Dots as separators are not supported.");
  }

  return parseFloat(str.replaceAll(sep, ""));
}

export const formatNumber = (
  value: number,
  options?: { maximumFractionDigits?: number; minimumFractionDigits: number }
) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    ...options,
  }).format(value);
};

export const validateJustNumbers = (value: string) => {
  const rgx = /^[0-9]*\.?[0-9]*$/;
  return value.match(rgx);
};
