import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { FlexItem } from "../Flex/Flex.styled";
import {
  cardBorder,
  cardBorderGradient,
} from "../../Contexts/ThemeProvider/Layout";
import { CardBorderColors } from "../../Models/cardInterface";

export const CardStyled = styled.div<{
  $type?: string;
  padding?: string;
}>`
  padding: ${(props) => ifProp("padding", props.padding, "30px 15px")};
  border-radius: ${(props) => (props.$type === "degradent" ? "20px" : "30px")};
  background: ${(props) =>
    props.$type === "degradent"
      ? `linear-gradient(180deg, ${props.theme.Primary_03} 0%, ${props.theme.Primary_01} 100%)`
      : props.theme.Background_01};
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.Content_UX_02};
  display: flex;
  flex-direction: column;
`;

export const CardButton = styled(FlexItem)<{
  minHeight?: CSSProperties["minHeight"];
  maxHeight?: CSSProperties["maxHeight"];
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${prop("direction", "column")};
  width: 100%;
  min-height: ${prop("minHeight", "90px")};
  max-height: ${prop("maxHeight")};
  border-radius: 12px;
  padding: ${prop("p", "10px 24px")};
  justify-self: center;
  justify-content: center;
  border: 1px solid #c6c6c6;
  background: ${theme("Background_01")};
  width: 100%;

  ${mediaQuery[Screen.desktop]} {
    max-width: 382px;
  }

  ${mediaQuery[Screen.lg]} {
    max-width: 350px;
  }
`;

export const ClickableCardButton = styled(CardButton)`
  -webkit-tap-highlight-color: transparent;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-0.1rem) scale(1.01);
  }

  &:focus {
    outline: none;
    background: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background: rgba(${theme("RGBA_02")}, 0.3);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.5s, opacity 1s;
    opacity: 0;
  }

  &:active::after {
    transform: scale(60);
    opacity: 1;
    transition: transform 0.5s, opacity 0.8s;
  }
`;

export const GradientCard = styled.div<{
  borderWidth?: string;
  borderColor?: CardBorderColors;
  padding?: CSSProperties["padding"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  maxWidth?: CSSProperties["maxWidth"];
  active?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  gradientBackgroundColor?: CSSProperties["backgroundColor"];
  color?: CSSProperties["color"];
}>`
  box-sizing: border-box;
  background: ${ifProp(
    "active",
    cardBorderGradient,
    ifProp(
      "gradientBackgroundColor",
      css`linear-gradient(
        180deg,
        ${prop("gradientBackgroundColor")} 0%,
        ${prop("backgroundColor")} 100%
      )`,
      cardBorder
    )
  )};
  padding: ${prop("borderWidth", "2px")};
  border-radius: 8px;
  width: ${prop("width")};
  max-width: ${prop("maxWidth", "100%")};
  height: ${prop("height", "100%")};
  ${ifProp(
    "active",
    css`
      box-shadow: 0px 4px 24px 0px ${theme("Black")}40;
    `,
    css`
      box-shadow: 0px 4px 24px 0px ${theme("Black")}26;
    `
  )};

  > div {
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    background: ${prop("backgroundColor", "white")};
    color: ${prop("color")};
    overflow: hidden;
    padding: ${prop("padding", "3px 5px")};
    height: calc(100% - 0.5px);

    ${ifProp(
      "gradientBackgroundColor",
      css`
        background: linear-gradient(
          180deg,
          ${prop("gradientBackgroundColor")} 0%,
          ${prop("backgroundColor")} 100%
        );
      `
    )}

    * {
      color: ${prop("color")};
    }
  }
`;

export const SquareCard = styled(GradientCard)`
  > div {
    background-color: ${theme("White")};

    * {
      color: ${theme("Black")};
    }
  }

  div:nth-child(1) > img {
    width: 2.0625rem;
    height: 2.0625rem;
  }

  div:nth-child(1) div img {
    max-width: 41px;
    height: auto;
  }
`;
