import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { scrollTopWindow } from "../../Utilities/ScrollTopWindow";
import theme from "../../Contexts/ThemeProvider";
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import {
  AccountListContainer,
  AccountSubTitle,
  AddAccountButton,
  ButtonSection,
  CloseContainer,
  CountryContainer,
  SkipButton,
} from "./Accounts.styled";
import Text from "../../Components/Text";
import IconFont from "../../Components/IconFont";
import { useCountry } from "../../Hooks/useCountry";
import { useDispatch, useSelector } from "react-redux";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import Icon from "../../Components/Icon";
import { FlexItem } from "../../Components/Flex/Flex.styled";
import { CountryIcon } from "../../Components/MoneyInput/MoneyInput.styled";
import AccountCard from "./AccountCard/AccountCard.component";
import { AppDispatch, State } from "../../Redux/Store";
import { useBeneficiary } from "../../Hooks/useBeneficiary";
import {
  BeneficiaryEdit,
  groupAccounts,
} from "../../Modules/Beneficiary/domain/Beneficiary";
import Button from "../../Components/Button";
import ToastNotifications from "../../Components/ToastNotifications";
import { DashboardSelector } from "../../Redux/Dashboard/Dashboard.slice";
import { BeneficiarySlice } from "../../Redux/Beneficiary";

export const Accounts = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const [tModals] = useTranslation("modals");
  const [showView, setShowView] = useState("create");
  const beneficiaryToSave = useSelector(
    (state: State) => state.beneficiary.data.beneficiaryToSave
  );
  const { previousView } = useSelector(DashboardSelector);
  const dispatch = useDispatch<AppDispatch>();

  const [beneficiaryAccount, setBeneficiaryAccount] =
    useState<BeneficiaryEdit>();
  const [accountsGrouped, setAccountsGrouped] = useState<any>([]);
  const beneficiaryEdit = useSelector(
    (state: State) => state.beneficiary.data.beneficiaryEdit
  );
  //? we always prioritize country selected in beneficiary flow, then we check if there is one country set in quotation
  const country = beneficiaryToSave?.recipient.country;
  const countryEdit = beneficiaryEdit?.country;
  const {
    countries,
    countriesWithoutUSAOptions,
    isLoading: countriesLoading,
  } = useCountry();
  const {
    isLoading: beneficiaryLoading,
    error: beneficiaryError,
    createRecipient,
    getBeneficiaryInformation,
    updateBeneficiaryId,
    getMessageError,
    errorMessage,
  } = useBeneficiary();
  const loading = beneficiaryLoading || countriesLoading;
  const [showErrorBeneficiary, setShowErrorBeneficiary] = useState(false);

  const countryIcon = countries.find(
    (exchange) =>
      exchange.countryCode === country || exchange.countryCode === countryEdit
  )?.icon;

  const saveBeneficiary = async () => {
    if (beneficiaryToSave) {
      const response = await createRecipient(beneficiaryToSave);

      return response?.recipient;
    }
  };

  const handleClose = () => {
    setShowErrorBeneficiary(false);
  };

  const skipAddAccount = async () => {
    if (showView === "edit" && beneficiaryEdit) {
      const responseUpdate = await updateBeneficiaryId(
        beneficiaryEdit,
        beneficiaryEdit?.beneficiaryId
      );

      if (responseUpdate) {
        navigate("/dashboard", {
          state: {
            activeTab:
              previousView === "dashboard" ? "dashboard" : "recipients",
          },
        });
      } else {
        setShowErrorBeneficiary(true);
      }
    } else {
      const recipientSaved = await saveBeneficiary();

      if (recipientSaved?.beneficiaryId) {
        const recipient = {
          ...recipientSaved,
          account: [],
        };
        dispatch(BeneficiarySlice.actions.setBeneficiarySelected(recipient));

        navigate("/dashboard", {
          state: {
            previous: "createRecipient",
            activeTab:
              previousView === "dashboard" ? "dashboard" : "recipients",
          },
        });
      } else {
        setShowErrorBeneficiary(true);
      }
    }
  };

  const closeAddBank = () => {
    navigate(-1);
  };

  const addNewAccount = async () => {
    if (showView === "edit" && beneficiaryEdit) {
      const responseUpdate = await updateBeneficiaryId(
        beneficiaryEdit,
        beneficiaryEdit?.beneficiaryId
      );

      if (responseUpdate) {
        await getBeneficiaryInformation(beneficiaryEdit.beneficiaryId);
        navigate("/add-accounts", {
          state: { viewAccount: "newAccount" },
        });
      }
    } else {
      const recipientSaved = await saveBeneficiary();
      if (recipientSaved?.beneficiaryId) {
        await getBeneficiaryInformation(recipientSaved.beneficiaryId);
        navigate("/add-accounts", {
          state: { viewAccount: "newAccount" },
        });
      }
    }
  };

  useEffect(() => {
    scrollTopWindow();
  }, []);

  useEffect(() => {
    setShowView(location.state.view);

    if (location.state.view === "edit") {
      const beneficiary = beneficiaryEdit;
      if (beneficiary?.accounts) {
        setAccountsGrouped(groupAccounts(beneficiary.accounts));
      }

      setBeneficiaryAccount(beneficiary);
    }
  }, [beneficiaryEdit, country]);

  useEffect(() => {
    if (beneficiaryError) {
      getMessageError(beneficiaryError);
    }
  }, [beneficiaryError]);

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={loading} roundedContent={false}>
        <Container maxWidth="393px">
          <Row>
            <Column span={12} px={3} mt={2}>
              <CloseContainer>
                <Icon
                  icon="arrowLeft"
                  size="medium"
                  color="black"
                  onClick={closeAddBank}
                />
              </CloseContainer>
            </Column>
          </Row>
          <Row>
            <Column span={12} px={3} mt={3} mb={2}>
              <Title
                color="black"
                size={3}
                tagName="h1"
                weight={600}
                text={t("Beneficiaries.Account.Title")}
                align="left"
              />
              <AccountSubTitle>
                <IconFont name="safe" size="medium" color="black" />
                <Text
                  color="grey"
                  size={1}
                  weight={400}
                  align="left"
                  margin={"0 8px"}
                >
                  {t("Beneficiaries.Account.Subtitle")}
                </Text>
              </AccountSubTitle>
              <AccountSubTitle>
                <IconFont name="hand-coin" size="medium" color="black" />
                <Text
                  color="black"
                  size={1}
                  weight={400}
                  align="left"
                  margin={"0 8px"}
                >
                  {t("Beneficiaries.Account.Subtitle2")}
                </Text>
              </AccountSubTitle>
            </Column>
          </Row>
          <Row>
            {accountsGrouped && [...accountsGrouped.values()].length > 0 && (
              <Column span={12} px={3} mb={3}>
                <CountryContainer>
                  <Text
                    size={0}
                    weight={500}
                    color="black"
                    margin="8px 0"
                    align="left"
                  >
                    {t("Beneficiaries.Account.Country")}
                  </Text>
                  <FlexItem w="auto">
                    {countryIcon && <CountryIcon src={countryIcon} />}
                    <Text
                      size={0}
                      weight={500}
                      color="black"
                      margin="8px 0"
                      align="left"
                    >
                      {
                        countriesWithoutUSAOptions.find(
                          (c) => c.value === country || c.value === countryEdit
                        )?.value
                      }
                    </Text>
                  </FlexItem>
                </CountryContainer>
              </Column>
            )}

            {accountsGrouped &&
              [...accountsGrouped.values()].map((accounts, idx) => (
                <Column key={idx} span={12} px={3} my={1} gap="16px">
                  <AccountCard withEditButtons accountsList={accounts} />
                </Column>
              ))}
            <Column span={12} px={3} mt={2} mb={3}>
              <AccountListContainer></AccountListContainer>
              <div onClick={addNewAccount}>
                <AddAccountButton>
                  + {t("Beneficiaries.Account.buttonAdd")}
                </AddAccountButton>
              </div>
            </Column>
            <Column span={12} px={1}>
              <ButtonSection
                withBorder={
                  accountsGrouped && [...accountsGrouped.values()].length > 0
                }
              >
                <Button
                  text={t("Beneficiaries.Account.buttonSkip")}
                  variant="primary"
                  sizeText="medium"
                  sizeButton="large"
                  onClick={skipAddAccount}
                />
              </ButtonSection>
            </Column>
          </Row>
        </Container>
        <ToastNotifications
          status="error"
          show={showErrorBeneficiary}
          message={tModals(errorMessage)}
          closeNotification={() => handleClose()}
        />
      </Layout>
    </ThemeProvider>
  );
};
