const CurrencyService = {
  formatNumberToCurrency(amount: number, currency = "USD") {
    return amount?.toLocaleString("en-US", {
      style: "currency",
      currency,
    });
  },

  formatNumberString(amountString: string): string {
    let amount = this.getFloatNumber(amountString).toString();
    let decimal = "";

    const splited = amountString.split(".");

    if (splited.length === 2) {
      amount = splited[0];
      decimal = `.${splited[1]}`;
    }

    let output = amount
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    output = output + decimal;
    output = output ? `${output}` : "";

    return output;
  },

  onlyTwoDecimals(amount: number): string {
    return amount.toFixed(2);
  },

  getFloatNumber(amountString: string): number {
    const input = amountString.replace(",", "");

    return parseFloat(input);
  },

  getUSDAmountWithFees(amountString: string, fee: number, decimal = 4): string {
    const parsedAmount = this.getFloatNumber(amountString);
    const output = String((parsedAmount + fee).toFixed(decimal));

    return this.formatNumberString(output);
  },

  getFloatAmount(amountString: string): string {
    const parsedAmount = this.getFloatNumber(amountString);
    const output = String(parsedAmount);

    return output;
  },

  getFloatAmountWithFee(amountString: string, fee: number): string {
    const parsedAmount = this.getFloatNumber(amountString);
    const output = String(parsedAmount + fee);

    return output;
  },
};

export default CurrencyService;
