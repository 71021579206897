// Own components
import Footer from "../Footer/Footer.component";
import Header from "../Header";
import Link from "../Link";
import Icon from "../Icon";

// Interface
import { PropsLayout } from "../../Models/Layout";

// Own styles
import {
  ContainerFluid,
  HeaderContainer,
  Content,
  FooterContainer,
  Return,
} from "./Layout.styled";
import Loader from "../Loader";
import { useNavigate } from "react-router";
import IconFont from "../IconFont";

const Layout = ({
  path,
  pathState,
  textLink,
  show = true,
  loading,
  loadingMsg,
  fullWidthContent,
  roundedContent = true,
  children,
  translatedContent,
  showHeaderContent = true,
}: PropsLayout) => {
  const navigate = useNavigate();

  return (
    <>
      {loading && <Loader full message={loadingMsg} />}
      <ContainerFluid
        $show={show}
        fullWidthContent={fullWidthContent}
        roundedContent={roundedContent}
      >
        <HeaderContainer className="header">
          <Header showContent={showHeaderContent} />
        </HeaderContainer>
        {textLink && (
          <Return>
            <IconFont name="chevron-left" color="white" size="small" />
            <Link
              {...(path
                ? {
                    href: pathState ? "#" : path,
                    onClick: pathState
                      ? (e) => {
                          e.preventDefault();
                          navigate(path, { state: pathState });
                        }
                      : undefined,
                  }
                : { href: "#", onClick: () => navigate(-1) })}
              size={0.5}
            >
              {textLink}
            </Link>
          </Return>
        )}
        <Content
          className="main-content"
          withPadding={roundedContent}
          translated={translatedContent}
        >
          {children}
        </Content>
        <FooterContainer className="footer">
          <Footer />
        </FooterContainer>
      </ContainerFluid>
    </>
  );
};

export default Layout;
