import {
  GrayCard,
  GrayCardTitle,
} from "../../../../Components/GrayCard/GrayCard.styled";
import { tRecipientDetails } from "../../../../Models/ReviewTransaction";
import { RecipientDetailsTextRow } from "./RecipientDetails.styled";

export default function RecipientDetails(props: tRecipientDetails) {
  const { title, name, phone, email } = props;
  return (
    <GrayCard>
      <GrayCardTitle>{title}</GrayCardTitle>
      <div>
        <RecipientDetailsTextRow>{name}</RecipientDetailsTextRow>
        <RecipientDetailsTextRow>{phone}</RecipientDetailsTextRow>
        <RecipientDetailsTextRow>{email}</RecipientDetailsTextRow>
      </div>
    </GrayCard>
  );
}
