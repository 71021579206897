import styled from "styled-components";
import { theme } from "styled-tools";

export const StepLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 7px 9px;
  background-color: ${theme("Grey_Light")};
`;
