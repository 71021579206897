import { Column, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";

import {
  Card,
  PaymentIcon,
  TextSpacingBottom,
  PaymentIconColumn,
  UnavailableRibbon,
  UnavailableOverflow,
} from "./PaymentsCard.styled";

import { JustifyContent, AlignItems } from "../../../Types/Column";
import RadioCustom from "../../../Components/RadioCustom";

import type { PaymentsCardProps } from "../../../Types/Payments";
import IconShape from "../../../Components/IconShape";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../Utilities/NumberUtils";

const PaymentsCard = ({
  title,
  description,
  element,
  iconSrc,
  icon,
  helperText,
  value,
  radioRef,
  hideRadio,
  balance,
  unavailable,
  getValue,
}: PaymentsCardProps) => {
  const [t] = useTranslation("global");
  return (
    <Column span={12} as={Card}>
      <Row>
        <Column
          py={4}
          pb={unavailable ? 8 : undefined}
          pl={3}
          span={9}
          justifyContent={JustifyContent.spaceBetween}
        >
          <Row>
            <Column
              span={12}
              justifyContent={JustifyContent.spaceBetween}
              alignItems={AlignItems.flexStart}
            >
              <Row>
                {!hideRadio && (
                  <Column
                    span={1}
                    alignItems={AlignItems.flexStart}
                    justifyContent={JustifyContent.flexStart}
                  >
                    <RadioCustom
                      forwardRef={radioRef}
                      name="paymentType"
                      value={value}
                      onChange={getValue}
                    />
                  </Column>
                )}
                <Column span={11}>
                  <TextSpacingBottom>
                    {element ? (
                      element
                    ) : (
                      <Text color="black" size={2} align="left" weight={600}>
                        {title}
                      </Text>
                    )}
                  </TextSpacingBottom>
                  {description && (
                    <TextSpacingBottom>{description}</TextSpacingBottom>
                  )}
                  {balance && (
                    <Column justifyContent={JustifyContent.spaceBetween} mb={2}>
                      <Text
                        color="black"
                        size={"default"}
                        desktop={0.5}
                        align="left"
                        weight={400}
                      >
                        {t("Payments.availableBalance")}:
                      </Text>
                      <Text
                        color="black"
                        size="default"
                        desktop={0.5}
                        align="left"
                        weight={600}
                      >
                        ${formatNumber(balance)}
                      </Text>
                    </Column>
                  )}
                  {helperText && (
                    <Text
                      color="black"
                      size="default"
                      desktop={0.5}
                      align="left"
                      weight={600}
                    >
                      {helperText}
                    </Text>
                  )}
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
        <Column
          py={4}
          pb={unavailable ? 8 : undefined}
          pr={3}
          span={3}
          alignItems={AlignItems.flexStart}
          as={PaymentIconColumn}
        >
          <Row>
            <Column
              span={12}
              justifyContent={JustifyContent.flexEnd}
              alignItems={AlignItems.center}
            >
              {iconSrc ? (
                <PaymentIcon src={iconSrc} />
              ) : (
                <IconShape
                  icon={icon || "credit-card"}
                  iconSize="medium"
                  gradient
                />
              )}
            </Column>
          </Row>
        </Column>
        {unavailable && (
          <Row as={UnavailableOverflow}>
            <Column
              span={12}
              direction="column"
              justifyContent={JustifyContent.flexEnd}
            >
              <UnavailableRibbon>
                <Text size={2} weight={600}>
                  {t("global.comingSoon")}
                </Text>
              </UnavailableRibbon>
            </Column>
          </Row>
        )}
      </Row>
    </Column>
  );
};

export default PaymentsCard;
