import { createClient } from "../../../Services/ApiClient";

import {
  CreateIDDocumentResponse,
  Person,
  PersonCard,
  VerifyKYC,
} from "../domain/Person";
import { PersonRepository } from "../domain/PersonRepository";

const client = createClient();

export const createApiPersonRepository = (): PersonRepository => {
  return {
    getPerson,
    getPersonCard,
    updateStatusVerifyKYC,
    createDocument,
  };
};

const getPerson = async () => {
  const response = await client.get<Person>("persons/ByUserId");
  return response.data;
};

const getPersonCard = async () => {
  const response = await client.get<PersonCard>("personsendola/byuserid");
  return response.data;
};

const updateStatusVerifyKYC = async () => {
  const response = await client.patch<VerifyKYC>("persons/VerifyKYC", {});
  return response.data;
};

const createDocument: PersonRepository["createDocument"] = async (payload) => {
  const response = await client.post<CreateIDDocumentResponse>(
    "/persons/CreateDocument",
    payload
  );

  return response.data;
};
