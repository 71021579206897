// Define what main theme will look like
export const theme = {
  White: "#FFFFFF",
  Platinum: "#D9D9D9",
  Platinum_2: "#e8e8e8",
  Platinum_3: "#dddddd",
  Silver: "#C1C1C1",
  Grey: "#6B6B6B",
  Grey_Light: "#E1E0E094",
  Dark_Grey: "#484848",
  Coppel: "#F9E12E",
  Background_01: "#FFF",
  Background_02: "#000",
  Background_03: "#C6C6C6",
  Background_04: "#667085",
  Background_05: "#B3BBCE",
  Background_06: "#F9F9F9",
  Background_07: "#2622FF",
  Background_08: "#F98553",
  Background_09: "#CBCBCB",
  Background_10: "#E1D7F8",
  Background_11: "#0C1112",
  Background_12: "#11181C",
  Content_UX_01: "#1C1C1C",
  Content_UX_02: "#717171",
  Content_UX_03: "#FBFBFB",
  Content_UX_04: "#989898",
  Content_UX_05: "#EBEEEF",
  Content_State_UX_Hover: "#717171",
  Content_State_UX_Active: "#393939",
  Content_State_UX_Disabled: "#E3E3E3",
  Primary_Blue: "#338AF3",
  Alice_Blue: "#DEEDFF",
  Cornflower_Blue: "#569BEB",
  Green_01: "#ECFDF5",
  Green_05: "#56A034",
  Green_06: "#DAF3E7",
  Black: "#000000",
  Neutral_900: "#0E1015",
  Zelle: "#7F18D9",
  Primary_01: "#6E66FF",
  Primary_01_700: "#6739DC",
  Primary_01_600: "#5C51F4",
  Primary_01_500: "#5630B5",
  Primary_01_50: "#F0EBFB",
  Primary_01_25: "#EFF0F9",
  Primary_02: "#06063F",
  Primary_03: "#FF007C",
  Primary_04: "#210066",
  Primary_05: "#222222",
  Primary_06: "#01B442",
  Primary_07: "#24420E",
  Primary_08: "#F9CB28",
  Primary_09: "#6ee7b7",
  Primary_10: "#FDE68A",
  Primary_11: "#9EA8C0",
  Primary_12: "#077D45",
  Primary_13: "#344054",
  Primary_14: "#1FC795",
  Primary_15: "#FF9811",
  Primary_16: "#D51A0C",
  Primary_17: "#7583A5",
  Primary_18: "#CECECE",
  Primary_19: "#EEEEEC",
  Primary_500: "#038C8C",
  Secondary_01: "#9E44E1",
  Label: "#384055",
  Red_08: "#94000D",
  Error: "#EA5F59",
  font_family: "Roboto",
  Transparent: "transparent",
  RGBA_01: "0,0,0",
  RGBA_02: "35,44,119",
  RGBA_03: "196,21,90",
  RGBA_04: "14,16,21",
  RGBA_05: "113,113,113",
  RGBA_06: "0,0,0,0.35",
  RGBA_07: "0,0,0,0.85",
  RGB_Background_01: "255,255,255",
  RADIO_SHADOW: "#C8F9E8",
  Image_Empty_bg: "#DDEAF3",
  Image_Empty_Color: "#597393",
  MartoriWhite: "#FFFFFF",
  MartoriPrimary_900: "#1C4B25",
  MartoriPrimary_01: "#37B44A",
  MartoriPrimary_01_50: "#E3EDF3",
  MartoriGradient_01: "#5CCC6D",
  MartoriGradient_02: "#205B29",
  MartoriGradient_03: "#2b8739",
  MartoriBackground_10: "#d7f8e4",
};
export type ThemeColors = keyof typeof theme;
