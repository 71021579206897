import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// own states
import { AlignItems, JustifyContent } from "../../Types/Column";

// Own components
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import Text from "../../Components/Text";
import IconFont from "../../Components/IconFont";
import { BeneficiaryRecipient } from "../../Modules/Beneficiary/domain/Beneficiary";

import BeneficiaryCard from "./Components/BeneficiaryCard";
import { isEmpty } from "lodash";
import { BeneficiarySelect } from "../../Modules/Beneficiary/domain/Beneficiary";
import Button from "../../Components/Button";
import Modal from "../../Components/Modal";
import { useBeneficiary } from "../../Hooks/useBeneficiary";
import IconShape from "../../Components/IconShape";
import { SearchInput } from "../../Components/Input/SearchInput.component";
import { scrollTopWindow } from "../../Utilities/ScrollTopWindow";
import { Pagination } from "../../Components/Pagination/Pagination.component";
import { useDebounce } from "../../Hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";
import { AppDispatch } from "../../Redux/Store";
import { DashboardSlice } from "../../Redux/Dashboard/Dashboard.slice";
import { useNavigate } from "react-router-dom";

export const Beneficiaries = () => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [preferred, setPreferred] = useState<BeneficiaryRecipient[]>([]);
  const [recipients, setRecipients] = useState<BeneficiaryRecipient[]>([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<
    string | undefined
  >("");
  const [deleteBeneficiary, setDeleteBeneficiary] = useState<
    string | undefined
  >("");
  const [searchValue, setSearchValue] = useState("");
  const { isLoading, getBeneficiariesPaginated, deleteBeneficiarySelected } =
    useBeneficiary();
  const { language } = useSelector(selectorLanguage);
  const loading = isLoading;

  const handleDeleteBeneficiary = (beneficiary?: BeneficiarySelect) => {
    setOpenModalDelete(true);
    setDeleteBeneficiary(beneficiary?.fullName);
    setSelectedBeneficiary(beneficiary?.beneficiaryId);
  };

  const closeModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = async () => {
    if (selectedBeneficiary) {
      await deleteBeneficiarySelected(selectedBeneficiary);
      closeModalDelete();
      fetchBeneficiaries();
    }
  };

  const fetchBeneficiaries = useDebounce(async () => {
    const paginationResponse = await getBeneficiariesPaginated({
      page: currentPage,
      search: searchValue,
    });
    if (paginationResponse) {
      const preferred = paginationResponse.preferredRecipients || [];
      const recipients = paginationResponse.recipients || [];

      setPageCount(Math.ceil(paginationResponse.pagination.total / 20));
      setPreferred(preferred);
      setRecipients(recipients);
    }
    dispatch(DashboardSlice.actions.setLoading(false));
  }, 500);

  useEffect(() => {
    dispatch(DashboardSlice.actions.setLoading(true));
    scrollTopWindow();
    fetchBeneficiaries();
  }, [currentPage, searchValue, language]);

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  return (
    <>
      <Container maxWidth="442px" margin="24px auto">
        <Row>
          <Column
            span={12}
            mb={3}
            px={3}
            justifyContent={JustifyContent.flexEnd}
            alignItems={AlignItems.center}
            gap={3}
          >
            <Button
              text={`+ ${t("Beneficiaries.AddNewRecipient")}`}
              variant="outline"
              sizeText="medium"
              padding="11px 24px"
              weight={500}
              onClick={() => {
                dispatch(
                  DashboardSlice.actions.setPreviousView("beneficiaries")
                );
                navigate("/beneficiaries/create/country-selection");
              }}
            />
          </Column>
          <Column span={12} px={3} mb={3}>
            <SearchInput searchValue={searchValue} onChange={setSearchValue} />
          </Column>
          {!isEmpty(preferred) && (
            <>
              <Column span={12} px={3}>
                <Text
                  color="black_highlight"
                  size={1}
                  align="left"
                  weight={600}
                  margin="0 15px 4px 0"
                >
                  {t("Beneficiaries.Beneficiary.RecentBeneficiaries")}
                </Text>
                <Text
                  color="black_highlight"
                  size={0.5}
                  align="left"
                  weight={400}
                  margin="0 15px 25px 0"
                >
                  {t("Beneficiaries.Beneficiary.SubTitleRecentBeneficiaries")}
                </Text>
              </Column>

              <Column span={12} px={3} mb={3} gap="16px">
                {preferred.map((recipient) => (
                  <BeneficiaryCard
                    {...recipient}
                    key={recipient.beneficiaryId}
                    withEditButtons
                    editBeneficiary
                    withUserIcon={false}
                    handleDeleteBeneficiary={handleDeleteBeneficiary}
                  />
                ))}
              </Column>
            </>
          )}
          <Column span={12} px={3}>
            <Text
              color="black_highlight"
              size={1}
              align="left"
              weight={600}
              margin="0 15px 16px 2px"
            >
              {t("Beneficiaries.Beneficiary.AllBeneficiaries")}
            </Text>
          </Column>
          {!isEmpty(recipients) ? (
            <Column span={12} px={3} gap="16px">
              {recipients.map((recipient) => (
                <BeneficiaryCard
                  {...recipient}
                  key={recipient.beneficiaryId}
                  withEditButtons
                  editBeneficiary
                  withUserIcon={false}
                  handleDeleteBeneficiary={handleDeleteBeneficiary}
                />
              ))}
            </Column>
          ) : (
            <Column
              span={12}
              justifyContent={JustifyContent.center}
              alignItems={AlignItems.center}
              direction="column"
              px={6}
            >
              <IconFont name="user-circle" size="large" />
              <Text size={2} color="black" margin="16px 0">
                {searchValue
                  ? t("global.NoSearchResults")
                  : t("Dashboard.NotRecipients")}
              </Text>
              {/* <Button
                text={`+ ${t("Beneficiaries.AddNewRecipient")}`}
                variant="outline"
                sizeText="large"
                sizeButton="fit"
                padding="10.5px 24px"
                weight={500}
              /> */}
            </Column>
          )}
        </Row>
        <Row>
          {pageCount > 1 && (
            <Column span={12} my={4}>
              <Pagination
                current={currentPage}
                total={pageCount}
                onPageChange={setCurrentPage}
              />
            </Column>
          )}
        </Row>
      </Container>
      <Modal show={openModalDelete} handleClose={handleDelete}>
        <IconShape
          icon="user-x"
          bgColor="white"
          iconSize="xxlarge"
          iconColor="black"
        />
        <Text size={4} weight={600}>
          {t("Beneficiaries.EditBeneficiary.titleModalDelete")}
        </Text>
        <Text size={3} weight={400}>
          {t("Beneficiaries.EditBeneficiary.subTitleModalDelete")}
        </Text>
        <Text size={1} weight={700} margin={"20px 0 "}>
          {deleteBeneficiary}
        </Text>
        <Button
          text={t("Beneficiaries.EditBeneficiary.buttonDelete")}
          onClick={handleDelete}
          sizeText="medium"
          variant="primary"
        />
        <Button
          text={t("Beneficiaries.EditBeneficiary.buttonCancel")}
          onClick={closeModalDelete}
          sizeText="medium"
          variant="outline"
        />
      </Modal>
    </>
  );
};
