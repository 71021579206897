import styled from "styled-components";
import { theme } from "styled-tools";
import { textComponentSolidlight } from "../../../Contexts/ThemeProvider/Texts";
import { FlexContainer } from "../../../Components/Flex/Flex.styled";

export const CountryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 18px;
  border-bottom: 2px ${theme("Primary_18")} solid;
`;

export const ContainerTitleAccount = styled.div`
  width: 100%;
  border-left: 8px solid ${textComponentSolidlight};
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  div {
    margin-right: 5px;
  }
`;

export const AddButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  align-items: end;
`;

export const AddAccountButton = styled.span`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 16px;
  font-family: Poppins;
  text-align: right;
  text-decoration: underline;
  margin: 20px 0 0 0;
  color: ${theme("Primary_17")};
  justify-content: right;
`;

export const IconAccountSection = styled.div`
  display: flex;
`;

export const ButtonSection = styled(FlexContainer)`
  border-top: 2px ${theme("Primary_18")} solid;
  flex-direction: column;
  padding: 24px 40px;
  width: 100%;
  align-items: center;
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 18px;
  margin-right: 24px;
  width: 100%;
  justify-content: space-between;
`;
