import { createAsyncThunk } from "@reduxjs/toolkit";
import { createClient } from "../../Services/ApiClient";

import type { ApiRequestError } from "../../Types/ApiRequestError";
import type { Currency } from "../../Types/Currency";

const client = createClient();

export const findCurrencies = createAsyncThunk<
  Currency[],
  void,
  { rejectValue: ApiRequestError }
>("currencies/findCurrencies", async (_, { rejectWithValue }) => {
  try {
    const { data } = await client.get<Currency[]>("catalogs/currencies");
    return data;
  } catch (e) {
    return rejectWithValue(e as ApiRequestError);
  }
});
