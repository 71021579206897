import { useTranslation } from "react-i18next";
import { GrayCardTitle } from "../../../../Components/GrayCard/GrayCard.styled";
import TableInfo from "../../../../Components/TableInfo/TableInfo.component";
import Text from "../../../../Components/Text";
import { tBalanceDetails } from "../../../../Models/ReviewTransaction";
import { BalanceDetailsStyled } from "./BalanceDetails.styled";
import { useSelector } from "react-redux";
import { BeneficiarySelector } from "../../../../Redux/Beneficiary/Beneficiary.slice";

export default function BalanceDetails(props: tBalanceDetails) {
  const { title, data } = props;

  return (
    <BalanceDetailsStyled>
      <GrayCardTitle>{title}</GrayCardTitle>
      <TableInfo data={data} withSpace />
      {props.notice && (
        <Text weight={800} size={1} margin="auto">
          {props.notice}
        </Text>
      )}
    </BalanceDetailsStyled>
  );
}
