import {
  CountryIcon,
  CurrencyInputButton,
  CurrencyInputContainer,
  CurrencyInputCurrencyLabel,
  CurrencyInputDropdown,
  CurrencyInputDropdownItem,
  CurrencyInputDropdownList,
  CurrencyInputGroup,
  CurrencyInputInput,
  CurrencyInputInputLabel,
  CurrencyInputLabel,
} from "./MoneyInput.styled";

import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";
import {
  ExchangeRate,
  allowedCurrencies,
} from "../../Modules/Common/domain/ExchangeRates";
import { formatNumber, validateJustNumbers } from "../../Utilities/NumberUtils";
import { useTranslation } from "react-i18next";
import { ContainerText, Span } from "../Input/Input.styled";
import Icon from "../Icon";
import { CurrencyInputProps } from "../../Modules/Common/domain/CurrencyInputProps";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
import { SelectOption } from "../../Models/Select";
import {
  currencySelectRTLStyles,
  currencySelectStyles,
} from "./CurrencySelectTheme";
import { CountryWithCurrency } from "../../Modules/Common/domain/Countries";
import { FlexContainer } from "../Flex/Flex.styled";
import Text from "../Text";

function justNumbers(event: React.KeyboardEvent) {
  const { key, target } = event;
  const inputValue = (target as HTMLInputElement).value?.replace(/,/g, "");
  const isArrowKey = [
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
  ].includes(key);
  const isBackspace = key === "Backspace";
  const isDot = key === ".";
  const inputValueWithDot = inputValue + key;

  if (
    isArrowKey ||
    isBackspace ||
    (isDot && validateJustNumbers(inputValueWithDot)) ||
    !Number.isNaN(Number(key))
  ) {
    return true;
  }

  event.preventDefault();
  return false;
}

export const MoneyInputLight = (props: CurrencyInputProps) => {
  const {
    label,
    amount,
    currency,
    country,
    disabled,
    data,
    hasError,
    errorLabel,
    maxLength,
    minLength,
    handleInputChange,
    handleCurrencyChange,
    handleInputBlur,
  } = props;

  const defaultExchangeRate = data[0];
  const [selectedItem, setSelectedItem] =
    useState<Partial<CountryWithCurrency>>(defaultExchangeRate);
  const [formattedValue, setFormattedValue] = useState("0");
  const [unsupportedCurrency, setUnsupportedCurrency] = useState(false);
  const { t } = useTranslation("global");

  const selectOptions = useMemo(() => {
    return data.map((exchangeRate) => ({
      label: `${exchangeRate?.currency} ${exchangeRate?.countryName}`,
      value: `${exchangeRate?.currency} - ${exchangeRate?.countryCode}`,
      country: exchangeRate?.countryCode,
      image: exchangeRate?.icon,
    }));
  }, [data]);

  const handleMoneyInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.endsWith(".")) {
      // just set the local value, don't emit it to parent because is not a valid number yet
      setFormattedValue(inputValue);
    } else {
      const numericValue = parseFloat(inputValue.replace(/,/g, ""));
      const event = { ...e };
      if (!numericValue) {
        event.target.value = "0";
      } else {
        event.target.value = numericValue.toString();
      }
      if (handleInputChange) {
        handleInputChange(event);
      }
    }
  };

  function handleDropdownItemClick(
    optionSelected: SelectOption<string, { country: string }>
  ) {
    const selected = data.find(
      (exchangeRate) => exchangeRate?.countryCode === optionSelected.country
    );

    if (selected && selected.currency) {
      setUnsupportedCurrency(!allowedCurrencies.includes(selected.currency));
      setSelectedItem(selected);

      if (handleCurrencyChange) {
        handleCurrencyChange(selected);
      }
    }
  }

  useEffect(() => {
    const selected = data.find(
      (exchangeRate) =>
        exchangeRate.countryCode === country &&
        exchangeRate.currency === currency
    );
    if (selected) {
      setSelectedItem(selected);
    }
  }, [currency, country, data, selectOptions]);

  useEffect(() => {
    const newFormattedValue = !Number.isNaN(parseFloat(amount || "0"))
      ? formatNumber(parseFloat(amount || "0"))
      : amount;
    if (newFormattedValue) {
      setFormattedValue(newFormattedValue);
    }
  }, [amount]);

  return (
    <FlexContainer direction="column" justify="space-between">
      <FlexContainer>
        <Text size={0.5} lineHeight="18px" weight={400} margin={0}>
          {label}
        </Text>
      </FlexContainer>
      <FlexContainer
        alignItems="center"
        justify="space-between"
        as={CurrencyInputDropdown}
      >
        <CurrencyInputInput
          type="text"
          inputMode="numeric"
          value={formattedValue}
          onChange={handleMoneyInputChange}
          onKeyDown={justNumbers}
          placeholder="0.00"
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          textAlign="left"
          onBlur={handleInputBlur}
        />
        {data.length > 1 ? (
          <SearchSelect
            options={selectOptions}
            name="currency"
            value={selectOptions
              .map((opt) => ({
                ...opt,
                label: opt.value.split(" - ")[0],
                avatarSize: { width: "24px", height: "24px" },
              }))
              .find((opt) => opt.country === selectedItem?.countryCode)}
            customStyles={currencySelectRTLStyles}
            showAvatar
            showAvatarOnValue
            m="auto"
            onChange={(optionSelected: SelectOption) =>
              handleDropdownItemClick(
                optionSelected as SelectOption<string, { country: string }>
              )
            }
          />
        ) : (
          <CurrencyInputButton>
            <FlexContainer m="0 5px 0 0">
              <CountryIcon size="24px" src={selectedItem?.icon} />
            </FlexContainer>
            <Text size={0} weight={500} margin="0 24px 0 3px">
              {selectedItem?.currency}
            </Text>
          </CurrencyInputButton>
        )}
      </FlexContainer>
      {unsupportedCurrency && (
        <ContainerText>
          <Icon icon="alertTriangle" color="error" size="small" />
          <Span $error>{t("Quote.QuoteForm.CurrencyNotAvailable")}</Span>
        </ContainerText>
      )}
      {hasError && errorLabel && (
        <ContainerText>
          <Icon icon="alertTriangle" color="error" size="small" />
          <Span $error>{errorLabel}</Span>
        </ContainerText>
      )}
    </FlexContainer>
  );
};
