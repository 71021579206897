import IconFont from "../../../../Components/IconFont";
import {
  StepTwoDetail,
  StepTwoStyled,
  StepTwoTitle,
  StepTwoUserCard,
  StepTitleRow,
} from "./Steps.styled";

export type tStepTwoProps = {
  title: string;
  description: string;
};
export default function StepTwo(props: tStepTwoProps) {
  const { title, description } = props;

  return (
    <StepTwoStyled>
      <StepTitleRow>
        <IconFont name="step-two" size="xlarge" gradient />
        <StepTwoTitle>{title}</StepTwoTitle>
      </StepTitleRow>
      <StepTwoUserCard>
        <IconFont name="user-card-id" />
      </StepTwoUserCard>
      <StepTwoDetail color="primary">{description}</StepTwoDetail>
    </StepTwoStyled>
  );
}
