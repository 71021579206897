import styled, { CSSProperties } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const CurrencyInputContainer = styled.div`
  text-align: left;
  width: 100%;
`;

export const CurrencyInputLabel = styled.div`
  color: ${theme("Context_UX_01")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
`;

export const CurrencyInputGroup = styled.div<{ $hasError?: boolean }>`
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: ${ifProp("$hasError", theme("Red_08"), theme("Background_03"))};
`;

export const CurrencyInputButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 108px;
  background-color: transparent;
  text-decoration: none;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: ${(props) => props.onClick && "pointer"};
`;

export const CurrencyInputInput = styled.input<{
  textAlign?: CSSProperties["textAlign"];
}>`
  box-sizing: border-box;
  flex: 1 1 auto;
  width: 100%;
  padding: 0px;
  border: none;
  border-radius: 0;
  text-align: ${prop("textAlign", "right")};
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  background-color: transparent;
  color: ${theme("Black")};
  &:disabled {
    color: ${theme("Black")}!important;
  }
`;

export const CurrencyInputInputLabel = styled.span`
  display: block;
  margin: 0 3px;
`;

export const CurrencyInputCurrencyLabel = styled.span`
  display: block;
  margin: 0 15px 0 3px;
`;

export const CurrencyInputDropdown = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;

  > div {
    width: unset;
  }
`;

export const CurrencyInputDropdownList = styled.ul<{ $open?: boolean }>`
  display: ${ifProp("$open", "block", "none")};
  position: absolute;
  top: 65px;
  left: -17px;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: ${theme("Background_01")};
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: 200px;
  overflow-y: auto;
  width: 301px;
  z-index: 1;
  cursor: pointer;
`;

export const CurrencyInputDropdownItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  height: 55px;
  text-decoration: none;
  padding-left: 12px;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background-color: ${theme("Background_03")};
  }
`;

export const CountryIcon = styled.img<{ size?: string }>`
  height: ${ifProp("size", prop("size"), "35px")};
  width: ${ifProp("size", prop("size"), "35px")};
  margin-right: 3px;
  border-radius: 50%;
`;

export const ChevronDownIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 3px;
`;
