import styled from "styled-components";
import { FlexContainer } from "../../Components/Flex/Flex.styled";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const StepIcon = styled.img`
  margin-right: 0.3rem;
`;

export const RowSection = styled.div`
  margin: 2px 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  text-align: left;
  margin: 0 50px;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    margin: 0;
  }

  h1 {
    text-align: left;
    display: block;
    ${mediaQuery[Screen.mobile]} {
      text-align: left;
    }
  }

  p {
    text-align: left;

    ${mediaQuery[Screen.mobile]} {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
  }
`;

export const FormSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 50px;

  ${mediaQuery[Screen.mobile]} {
    margin: 20px 0;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 15px;
  }

  p {
    font-size: 18px;
  }

  .PhoneInput {
    width: 100%;
    height: 54px;
  }

  .PhoneInputInput {
    height: 54px;
  }
`;

export const FormSectionIcon = styled(FormSection)`
  flex-direction: row;
  align-items: center;

  h1 {
    margin-left: 10px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;

  ${mediaQuery[Screen.desktop]} {
    margin-left: 50px;
  }

  Button {
    ${mediaQuery[Screen.mobile]} {
      width: 200px;
      span {
        font-size: 18px;
      }
    }
  }
`;

export const bankCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;

export const FormSectionBank = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px 20px 8px;

  input {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 15px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
`;

export const TitleSectionBank = styled(FormSectionBank)`
  padding: 0px 8px 16px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    cursor: pointer;
  }
`;
